import React from 'react';
import { useLocation } from 'react-router-dom';

const DonationTable = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const title =
    queryParams?.get('title') && queryParams?.get('title') !== 'null'
      ? queryParams?.get('title')
      : JSON.parse(localStorage.getItem('after_redirect') ?? '{}')
          .projectTitle ?? '';
  const amount = queryParams?.get('amount') ?? '';
  const tips = queryParams?.get('tips') ?? '';
  const id = queryParams?.get('id') ?? '';
  const g = queryParams?.get('g') ?? '';
  const localGoodies = localStorage.getItem('goodies');
  const goodies = g
    ? g.split(',')
    : localGoodies && localGoodies.length
    ? localGoodies.split(',').filter((v) => v !== '' && v !== 'null')
    : [];
  return (
    <table className="donation-table">
      <caption>Deine Auswahl:</caption>
      <tr>
        <th>Projekt</th>
        <th>{title}</th>
      </tr>
      <tr>
        <td>Projekt ID</td>
        <td>{id}</td>
      </tr>
      <tr>
        <td>Unterstützung</td>
        <td>{amount}</td>
      </tr>
      {!!goodies.length &&
        goodies.map((val) => (
          <tr>
            <td>Goodies</td>
            <td>{val}</td>
          </tr>
        ))}
      <tr>
        <td>Trinkgeld</td>
        <td>{tips}</td>
      </tr>
      <tr>
        <td>Summe</td>
        <td>
          {amount
            ? Number(amount) +
              Number(tips ?? '0') +
              goodies.reduce((a, b) => Number(a) + Number(b), 0)
            : ''}
        </td>
      </tr>
    </table>
  );
};

export default DonationTable;
