import { projectsConstants } from 'redux/actions/types';
import { generateProjectObject, generateProjectsArray } from 'utils';
const initialState = {
  loading: false,
  supportedProjects: [],
  projects: {
    projects: [],
    count: 0,
    page: 1,
  },
  randomProjects: [],
  homeProjects: [],
  project: {},
  categories: [],
  adminCategories: [],
  categoriesCreation: [],
  errors: { errors: [] },
  isUniq: false,
  draftCreated: false,
  updates: [],
  currentStep: 1,
  projectStatus: {},
  myProjects: [],
  eventProjects: [],
  successfulEventProjects: [],
  successfulProjects: {
    projects: [],
    count: 0,
    page: 1,
  },
  tempCategory: {},
  allEvents: [],
  allSuccessfullEvents: [],
};

function projects(state = initialState, action) {
  switch (action.type) {
    case projectsConstants.GET_MY_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_MY_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        myProjects: generateProjectsArray(action.projects.projects),
      };
    case projectsConstants.GET_MY_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.GET_MY_SUPPORTED_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_MY_SUPPORTED_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        supportedProjects: generateProjectsArray(action.projects.projects),
      };
    case projectsConstants.GET_MY_SUPPORTED_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.GET_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: {
          ...action.projects,
          projects: generateProjectsArray(action.projects.projects),
        },
      };
    case projectsConstants.GET_EVENT_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_EVENT_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        eventProjects: {
          ...action.projects,
          projects: generateProjectsArray(action.projects.projects),
        },
      };

    case projectsConstants.GET_SUCCESSFULL_EVENT_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case projectsConstants.SET_TEMP_CATEGORY:
      return {
        ...state,
        tempCategory: action.payload,
      };
    case projectsConstants.GET_SUCCESSFULL_EVENT_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        successfulEventProjects: {
          ...action.projects,
          projects: generateProjectsArray(action.projects.projects),
        },
      };

    case projectsConstants.ALL_SUCCESSFULL_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case projectsConstants.ALL_SUCCESSFULL_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        allSuccessfullEvents: {
          ...action.projects,
          projects: generateProjectsArray(action.projects.projects),
        },
      };

    case projectsConstants.GET_RANDOM_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_RANDOM_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        randomProjects: generateProjectsArray(action.projects),
      };
    case projectsConstants.GET_RANDOM_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.GET_SUCCESSFUL_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_SUCCESSFUL_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        successfulProjects: {
          ...action.projects,
          projects: generateProjectsArray(action.projects.projects),
        },
      };
    case projectsConstants.GET_SUCCESSFUL_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.GET_HOME_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_HOME_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        homeProjects: generateProjectsArray(action.projects),
      };
    case projectsConstants.GET_HOME_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.SHOW_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        project: {},
      };
    case projectsConstants.SHOW_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: action.project,
      };
    case projectsConstants.SHOW_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.CREATE_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.CREATE_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: generateProjectObject(action.project),
        draftCreated: true,
      };
    case projectsConstants.CREATE_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.UPDATE_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.UPDATE_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: generateProjectObject(action.project),
        currentStep: action.currentStep || state.currentStep,
        draftCreated: false,
      };
    case projectsConstants.UPDATE_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.REEDIT_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.REEDIT_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: generateProjectObject(action.project),
        currentStep: action.currentStep || state.currentStep,
        draftCreated: false,
      };
    case projectsConstants.REEDIT_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.UPLOAD_DRAFT_IMAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.UPLOAD_DRAFT_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        project: generateProjectObject(action.project),
      };
    case projectsConstants.UPLOAD_DRAFT_IMAGES_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.SUBMIT_DRAFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.SUBMIT_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        project: generateProjectObject(action.project),
      };
    case projectsConstants.SUBMIT_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.CHECK_URL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.CHECK_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        isUniq: action.isUniq,
      };
    case projectsConstants.CHECK_URL_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_ADMIN_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_CREATION_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_CATEGORIES_SUCCESS:
      const categories = action.categories.map((category) => ({
        value: category.id,
        category: category.name,
        visible: category.visible,
        image: category.image_url,
        loading: false,
        category_title: category.category_title,
        url: category.url,
        is_desktop: category.is_desktop,
        days_filter: category.days_filter,
        days_filter_check: category.days_filter_check,
        title: category.title,
        name: category.name,
      }));
      return {
        ...state,
        loading: false,
        categories: categories,
      };

    case projectsConstants.GET_ADMIN_CATEGORIES_SUCCESS:
      const adminCategories = action.adminCategories.map((category) => ({
        value: category.id,
        category: category.name,
        visible: category.visible,
        image: category.image_url,
        loading: false,
        category_title: category.category_title,
        url: category.url,
        is_desktop: category.is_desktop,
        title: category.title,
        days_filter: category.days_filter,
        days_filter_check: category.days_filter_check,
      }));
      return {
        ...state,
        loading: false,
        adminCategories: adminCategories,
      };
    case projectsConstants.GET_CREATION_CATEGORIES_SUCCESS:
      const categoriesCreation = action.categoriesCreation.map((category) => ({
        value: category[0],
        category: category[1],
        visible: category[2],
        loading: false,
      }));
      return {
        ...state,
        loading: false,
        categoriesCreation: categoriesCreation,
      };

    case projectsConstants.SET_CATEGORIES:
      return {
        ...state,
        adminCategories: action.payload,
      };

    case projectsConstants.GET_ADMIN_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.GET_CREATION_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.GET_UPDATES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_UPDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        updates: action.updates,
      };
    case projectsConstants.GET_UPDATES_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.CREATE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.CREATE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updates: action.updates,
      };
    case projectsConstants.CREATE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.DELETE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.DELETE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        updates: action.updates,
      };
    case projectsConstants.DELETE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.CLEAR_PROJECT_SUCCESS:
      return {
        ...state,
        project: {},
      };
    case projectsConstants.HANDLE_STEPS:
      return {
        ...state,
        currentStep: action.step,
      };
    case projectsConstants.GET_PROJECT_STATUS_REQUEST:
    case projectsConstants.REMOVE_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case projectsConstants.GET_PROJECT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        projectStatus: action.projectStatus,
      };
    case projectsConstants.GET_PROJECT_STATUS_FAILURE:
    case projectsConstants.REMOVE_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case projectsConstants.REMOVE_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        projectStatus: {
          ...state.projectStatus,
          status: action.projectStatus.status,
        },
      };
    default:
      return state;
  }
}

export default projects;
