import React from 'react';
import { Colors, device } from 'utils';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const Button = styled.button`
  position: relative;
  z-index: 2;
  font-weight: bold;
  background: ${(props) =>
    !props.primary ? Colors.secondaryColor : Colors.white};
  border: 1px solid ${Colors.secondaryColor};
  color: ${(props) => (!props.primary ? Colors.white : Colors.secondaryColor)};
  font-size: 16px;
  width: 100%;
  border-radius: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  height: 50px;
  &:focus {
    outline: none;
    color: ${Colors.white};
  }
  &:hover {
    outline: none;
    background-color: ${Colors.white};
    color: ${Colors.secondaryColor};
  }
  @media ${device.allMobile} {
    font-size: 14px;
  }
`;
const DonationButton = ({ id, url, name }) => (
  <Link
    to={{
      pathname: `/project/${id}/donation`,
      search: `?donation=20&name=${name}`, // You can also pass query params
      state: { url }, // Passing state
    }}
  >
    <Button>Unterstützen</Button>
  </Link>
);

export default DonationButton;
