import React, { useEffect } from 'react';
import { PrimaryButton } from 'utils';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const StripeForm = ({
  selectedRewards,
  paymentType,
  project_id,
  amount,
  tips,
  setMessage,
  setIsProcessing,
  values,
  isTicketable,
  selectedKey,
  project,
  setElements,
  setStripe,
  options,
  onSubmitHandle,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setStripe(stripe);
    setElements(elements);
  }, [elements, setElements, setStripe, stripe]);

  return (
    <div>
      <div>
        {paymentType === 'card' && (
          <>
            <div className="payment_blk_in">
              <PaymentElement
                id="payment-element"
                options={{
                  ...options,
                  layout: { type: 'accordion', defaultCollapsed: true },
                }}
              />
            </div>
            <PrimaryButton
              type="submit"
              minWidth={'100%'}
              className="payPalBtn"
              onClick={onSubmitHandle}
              style={{ marginBottom: '1px', marginTop: '25px' }}
            >
              Jetzt zahlungspflichtig {isTicketable ? 'buchen' : 'unterstützen'}
            </PrimaryButton>
          </>
        )}
      </div>
    </div>
  );
};

export default StripeForm;
