// import React from 'react';
import React, { useEffect, useState } from 'react';

import { BaseLayout, StartProject } from 'components';
import {
  RecommendedProjects,
  BannerComponent,
  HowItWork,
  Support,
} from './components';
import styled from '@emotion/styled';
import { HomeBanner } from 'images';
import ReactPlayer from 'react-player/youtube';
import { Col, Form, Input, Row } from 'antd';
import { Container, device } from 'utils';
import { Colors, SvgWrapper } from 'utils';
import FooterBackground from 'images/footerWrapper.svg';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { ProjectsActions } from 'redux/actions';
import ContactModal from 'scenes/Project/components/ContactModal';
const HomePageWrapper = styled.div``;
const FormItem = Form.Item;
const FooterWrapper = styled.footer`
  background-image: url(${FooterBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 35px;
  padding-bottom: 10px;
  color: ${Colors.white};
  a:hover {
    color: ${Colors.steel};
  }
  @media ${device.allMobile} {
    padding-top: 25px;
  }
  .content {
    margin: auto;
    max-width: 1440px;
    padding: 0 40px;
    @media (max-width: 1441px) {
      max-width: 950px;
    }
  }
  header {
    text-align: center;
    img {
      width: 200px;
      margin: auto;
      margin-bottom: 35px;
    }
  }
  h1,
  h2 {
    margin: 0;
    color: ${Colors.white};
  }
  .qute {
    text-align: center;
    font-size: 19px;
    margin: 20px auto;
  }
  q {
    font-size: 19px;
    font-weight: bold;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .flex,
  .social {
    display: flex;
    justify-content: space-between;
  }
  .flex {
    @media ${device.allMobile} {
      display: block;
      text-align: center;
      div {
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }

  .payment {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 200px;
    & > span {
      margin: auto 5px 5px;
    }
  }
  .social,
  .payment {
    @media ${device.allMobile} {
      max-width: 200px;
      margin: auto;
    }
  }
  footer {
    text-align: right;
    a {
      text-decoration: underline;
    }
    p {
      @media ${device.allMobile} {
        text-align: center;
      }
      span {
        @media ${device.allMobile} {
          display: block;
        }
      }
    }
  }
  .newsletter-form {
    margin-top: 15px;
    margin-bottom: 0;
    width: 600px;
    margin-left: auto;
    @media ${device.laptop} {
      width: 430px;
    }
    @media ${device.minLaptop} {
      width: 430px;
    }
    @media ${device.tablet} {
      width: 370px;
    }
    @media ${device.allMobile} {
      width: 100%;
    }
    form {
      display: flex;
    }
    .ant-form-item {
      width: 70%;
      & + .ant-form-item {
        width: 30%;
      }
      input {
        height: 54px;
        border-radius: 5px 0 0 5px;
      }
    }
    .fildhome {
    }
    button {
      background: none;
      border: 1px solid ${Colors.white};
      box-shadow: none;
      border-radius: 2px;
      color: ${Colors.white};
      margin-top: 0;
      width: 100%;
      padding: 15px 0;
      border-radius: 0 5px 5px 0;
      border-left: none;
    }
  }
`;
const YouTubeWrapper = styled.div`
  width: 700px;
  height: 400px;
  max-height: 100%;
  margin: 50px auto;
  @media ${device.allMobile} {
    width: 100%;
    height: 400px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  .play-button__icon {
    height: 100px;
    svg {
      margin: auto;
    }
  }
`;
export default function HomePage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ProjectsActions.setCategoryTemp({}));
  }, [dispatch]);
  return (
    <div>
      <ContactModal />
      <Helmet>
        <meta
          name="description"
          content="Entdecke commonsplace – die führende Crowdfunding-Plattform für deutsch-muslimische Projekte. Vernetzen, finanzieren und realisieren großartige Ideen."
        />
      </Helmet>
      <HomePageWrapper>
        <BaseLayout
          bannerComponent={<BannerComponent />}
          container={false}
          img={HomeBanner}
          bannerClass="home-page-banner"
          style={{ minHeight: 600, background: '#f9f9f9' }}
        >
          <div className="home-page-container">
            <RecommendedProjects noBG />
            <HowItWork />
            <Support />
            <StartProject withImg={true} />
            {/* <YouTubeWrapper className="plyr__video-embed" id="player">
            <ReactPlayer
              url={'https://youtu.be/WwOSko0nFSM'}
              width="100%"
              height="100%"
              className="react-player"
            />
          </YouTubeWrapper> */}
          </div>
        </BaseLayout>
      </HomePageWrapper>
    </div>
  );
}
