import React, { Component } from 'react';
import styled from '@emotion/styled';
import { SvgWrapper, H1, Container, isMobile, device, H2 } from 'utils';
import { BaseLayout } from 'components';
import { ShortBackground, FAQIcon } from 'images';
import { Allgemein, Projektinitiator, Unterstützer } from './components';
import { Helmet } from 'react-helmet';
import ContactModal from 'scenes/Project/components/ContactModal';
const Intro = styled.div`
  text-align: center;
  h1 {
    font-size: 55px;
    @media ${device.allMobile} {
      font-size: 30px;
    }
  }
`;

const FAQSWrapper = styled.div`
  max-width: 950px;
  margin: auto;
  margin-bottom: 100px;
  @media ${device.allMobile} {
    margin-bottom: 50px;
  }
  .icon {
    text-align: center;
    margin: 100px auto;
    @media ${device.allMobile} {
      margin: 50px auto;
    }
    h1 {
      display: block;
      margin: 50px auto;
    }
  }
`;
export default class FAQsPage extends Component {
  state = {
    currentOpen: null,
  };
  setCurrentOpen = (state, witchQut) => {
    if (state) {
      this.setState({ currentOpen: witchQut });
    } else {
      this.setState({ currentOpen: null });
    }
  };

  render() {
    const { currentOpen } = this.state;
    return (
      <div>
        <ContactModal />
        <Helmet>
          <meta
            name="description"
            content="Antworten auf häufig gestellte Fragen zu commonsplace. Schnell Informationen zu Projektfinanzierung, Gebühren, Sicherheit und mehr finden."
          />
        </Helmet>{' '}
        <BaseLayout
          maxHeightProps="500px"
          maxHeightMobileProps="250px"
          container={false}
          img={ShortBackground}
          bannerComponent={
            <Intro>
              <H1>FAQ</H1>
            </Intro>
          }
        >
          <Container>
            <FAQSWrapper>
              <div className="icon">
                <SvgWrapper width={isMobile() ? '80%' : '450px'} height="auto">
                  <FAQIcon />
                </SvgWrapper>
                <H2>Eure Fragen – Unsere Antworten</H2>
              </div>

              <Allgemein
                currentOpen={currentOpen}
                setCurrentOpen={this.setCurrentOpen}
              />
              <Unterstützer
                currentOpen={currentOpen}
                setCurrentOpen={this.setCurrentOpen}
              />
              <Projektinitiator
                currentOpen={currentOpen}
                setCurrentOpen={this.setCurrentOpen}
              />
            </FAQSWrapper>
          </Container>
        </BaseLayout>
      </div>
    );
  }
}
