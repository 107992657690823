import React from 'react';
import { BaseLayout } from 'components';
import {
  Intro,
  HowToStartProject,
  Why,
  HowToSupport,
  DiscoverProjects,
  WhyCrowdfunding,
  QuranFirst,
  QuranSec,
} from './components';
import { ShortBackground } from 'images';
import { Helmet } from 'react-helmet';
import ContactModal from 'scenes/Project/components/ContactModal';
export default function HomePage() {
  return (
    <div>
      <ContactModal />
      <Helmet>
        <meta
          name="description"
          content="Erfahren, wie commonsplace funktioniert. Eine Schritt-für-Schritt-Anleitung zur Erstellung, Finanzierung und Unterstützung von Projekten."
        />
      </Helmet>
      <BaseLayout
        maxHeightProps="500px"
        maxHeightMobileProps="250px"
        bannerComponent={<Intro />}
        container={false}
        img={ShortBackground}
      >
        <WhyCrowdfunding />
        <Why />
        <HowToStartProject />
        <QuranFirst />
        <HowToSupport />
        <DiscoverProjects />
        <QuranSec />
      </BaseLayout>
    </div>
  );
}
