import config from 'config';
const login = ({ email, password }) => {
  const raw = JSON.stringify({ email: email, password: password });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`auth/sign_in`, requestOptions)
    .then((user) => {
      console.log('useruser: ', user);

      return { user: user };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const loginWithGoogle = ({ token }) => {
  const raw = JSON.stringify({ token });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`google_login`, requestOptions)
    .then((user) => {
      console.log('useruser: ', user);
      return { user };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const register = (user) => {
  const { firstName, lastName, email, password, passwordConfirmation } = user;
  const raw = JSON.stringify({
    first_name: firstName,
    last_name: lastName,
    email: email,
    password: password,
    password_confirmation: passwordConfirmation,
  });

  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`auth`, requestOptions)
    .then((user) => {
      localStorage.setItem('user', JSON.stringify(user));
      return { user: user };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const updateProfile = (userInfo) => {
  const raw = JSON.stringify(userInfo);
  const requestOptions = {
    method: 'PUT',
    body: raw,
    redirect: 'follow',
  };

  return fetch(`auth`, requestOptions)
    .then((user) => {
      localStorage.setItem('user', JSON.stringify(user));
      return { user: user };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const resetPassword = (email) => {
  const APP_URL = config.REACT_APP_URL;
  const raw = JSON.stringify({
    email: email,
    redirect_url:
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000/reset-password'
        : `${APP_URL}reset-password`,
  });
  const requestOptions = {
    method: 'POST',
    body: raw,
  };

  return fetch(`auth/password`, requestOptions)
    .then((response) => {
      return { success: response.message };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const sendContact = (form) => {
  const raw = JSON.stringify({ form });
  const requestOptions = {
    method: 'POST',
    body: raw,
  };

  return fetch(`contact/send`, requestOptions)
    .then((response) => {
      return { success: true };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const changePassword = ({
  password_confirmation,
  password,
  accessToken,
  client,
  uid,
  addHeaders,
}) => {
  let headers = {};
  if (addHeaders) {
    headers = {
      'access-token': accessToken,
      uid: uid,
      client: client,
    };
  }

  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify({
      password_confirmation,
      password,
    }),
    headers,
  };

  return fetch(`auth/password`, requestOptions)
    .then((user) => {
      return { user: user };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const logout = () => {
  // remove user from local storage to log user out
  const requestOptions = {
    method: 'DELETE',
    body: '',
    redirect: 'follow',
  };

  return fetch(`auth/sign_out`, requestOptions)
    .then((user) => {
      localStorage.removeItem('user');
      localStorage.removeItem('access-token');
      localStorage.removeItem('client');
      localStorage.removeItem('uid');
      return { user: user };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

//Sending session id after checkout session success.
const sendSessionId = (id) => {
  const body = {
    session_id: id,
  };
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(body),
    redirect: 'follow',
  };

  return fetch(`/success_url`, requestOptions)
    .then((res) => {
      return { res: res };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const authServices = {
  register,
  login,
  logout,
  resetPassword,
  changePassword,
  updateProfile,
  sendContact,
  sendSessionId,
  loginWithGoogle,
};

export default authServices;
