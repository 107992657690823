import React, { Component } from 'react';
import { Card, Form, Input, Radio, Spin, Button } from 'antd'; // DatePicker
import CurrencyInput from 'react-currency-input-field';
import { UploadImage } from 'components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ProjectsActions } from 'redux/actions';
import { Row, Col } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import isEmpty from 'loadsh/isEmpty';
import { numberWithCommas, Modal, isMobile } from 'utils';
import 'moment/locale/de';
// import locale from 'antd/es/date-picker/locale/de_DE';

// import pickBy from 'loadsh/pickBy';
// import identity from 'loadsh/identity';
import {
  Container,
  PrimaryButton,
  formLayout,
  formValidateMessages,
  H1,
  // H2,
  FormWrapper,
} from 'utils';
import styled from '@emotion/styled';

const FormItem = Form.Item;

const CurrencyWrapper = styled.div`
  .custom-currency-input {
    input {
      width: 100%;
      border-radius: 10px !important;
      webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      -webkit-font-feature-settings: 'tnum';
      font-feature-settings: 'tnum', 'tnum';
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    input:hover {
      border-color: #1890ff;
    }
    input:focus-visible {
      border-color: #1890ff;
      outline: none;
    }
    &::after {
      content: '€';
      width: 80px;
      height: 100%;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 0 10px 10px 0;
      color: black;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      line-height: 35px;
      vertical-align: middle;
    }
  }
`;

class Rewards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addNewReward: false,
      showMaxStock: false,
      // Form Elements
      rewardID: null,
      title: null,
      image: null,
      // mobile_image: null,
      price: null,
      address_required: null,
      description: null,
      shipping_date: null,
      max_order_count: null,
      no_multi_order: null,
      questions: [],
      // Modal
      showModal: false,
      selectedDeleteReward: null,
    };
  }

  static propTypes = {
    updateDraft: PropTypes.func.isRequired,
    updateDraftReward: PropTypes.func.isRequired,
    deleteDraftReward: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    isUniq: PropTypes.bool.isRequired,
    checkUrl: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    projectId: PropTypes.string,
    currentStep: PropTypes.number.isRequired,
    setCanMove: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    selectedStep: PropTypes.number.isRequired,
    setSubmitFromModal: PropTypes.func.isRequired,
  };

  onFinish = (values) => {
    // Add The value to the Rewards
    const reward = {
      id: this.state.rewardID,
      title: this.state.title,
      price: this.state.price,
      address_required: this.state.address_required,
      description: this.state.description,
      shipping_date: this.state.shipping_date,
      max_order_count: this.state.max_order_count,
      no_multi_order: this.state.no_multi_order,
      questions: this.state.questions,
    };

    this.setState({
      addNewReward: false,
    });

    this.resetReward();

    // Changing the format of the object sent to match the request
    const objectToSend = {
      reward: { ...reward },
    };

    const formData = new FormData();
    // Check if the Image has the correct attributes
    if (this.state.image[0] && this.state.image[0].originFileObj) {
      formData.append(
        'reward[image]',
        this.state.image[0].originFileObj,
        this.state.image[0].name
      );
    }

    // if (this.state.mobile_image[0] && this.state.mobile_image[0].originFileObj) {
    //   formData.append(
    //     'reward[mobile_image]',
    //     this.state.mobile_image[0].originFileObj,
    //     this.state.mobile_image[0].name
    //   );
    // }
    const {
      updateDraftReward,
      projectId,
      currentStep,
      setCanMove,
      showModal,
      selectedStep,
      setSubmitFromModal,
    } = this.props;
    if (showModal) {
      updateDraftReward(projectId, objectToSend, formData, selectedStep - 1);
      setSubmitFromModal(false);
    } else {
      updateDraftReward(projectId, objectToSend, formData, currentStep);
    }
    setCanMove(true);
  };

  resetReward = () => {
    this.setState({
      rewardID: null,
      title: null,
      price: null,
      address_required: null,
      description: null,
      shipping_date: null,
      max_order_count: null,
      no_multi_order: null,
      questions: [],
    });
  };

  handleAddQuestionClick = () => {
    this.setState({ questions: [...this.state.questions, ['']] });
  };

  handleAddAnswerClick = (i) => {
    this.setState({
      questions: this.state.questions.map((question, index) => {
        return index === i ? [...question, ''] : question;
      }),
    });
  };

  handleRemoveReward = () => {
    const { projectId, currentStep, setCanMove, deleteDraftReward } =
      this.props;
    deleteDraftReward(projectId, this.state.selectedDeleteReward, currentStep);
    this.handelCloseModal();
    setCanMove(true);
  };

  handleInputChange = (e, i, j) => {
    this.setState({
      // eslint-disable-next-line array-callback-return
      questions: this.state.questions.map((question, index) => {
        const { value } = e.target;
        let temp_question = question;
        if (index === i) {
          temp_question[j] = value;
        }
        return temp_question;
      }),
    });
  };
  handleRemoveQuestionAnswer = (i, j) => {
    // if j is 0 that means it is a question
    if (j === 0) {
      const tempArray = [...this.state.questions];
      tempArray.splice(i, 1);
      this.setState({
        questions: [...tempArray],
      });
    }
    // Else that means it is an answer
    else {
      const tempArray = [...this.state.questions];
      tempArray[i].splice(j, 1);
      this.setState({
        questions: [...tempArray],
      });
    }
  };

  handleEditReward = (reward) => {
    this.setState({
      addNewReward: true,
      rewardID: reward.id,
      title: reward.title,
      image: [reward.image],
      // mobile_image: [reward.mobile_image],
      price: reward.price,
      address_required: reward.address_required,
      description: reward.description,
      shipping_date: reward.shipping_date,
      showMaxStock: reward.max_order_count ? true : false,
      max_order_count: reward.max_order_count,
      no_multi_order: reward.no_multi_order,
      questions: reward.questions,
    });
  };

  handelCloseModal = () => {
    this.setState({ showModal: false, selectedDeleteReward: null });
  };

  render() {
    const {
      project,
      loading,
      // isUniq,
      setCanMove,
      projectId,
    } = this.props;

    return (
      <Container className="inside-form-container">
        <Spin spinning={loading}>
          <H1>Goodies</H1>
          <p>
            Hier geht es um eine Gegenleistung, die du der Community für ihre
            Unterstützung zurück gibst, die bei commonsplace Goodies heißen
          </p>
          <p>
            Goodies sind vielfältig und können materieller oder immaterieller
            Natur sein, z.B. dein fertiges Produkt, Tickets, ein Buch, Seminare
            oder Workshops, Wohnzimmerkonzerte, Meetings, Nennungen, Widmungen
            oder virtuelle Gesten.
          </p>
          <p>Ziele:</p>
          <ul>
            <li>
              Goodies sollten einen direkten Zusammenhang zu deiner Idee haben.
            </li>
            <li>
              Du solltest die Versandkosten in den Unterstützungspreis mit
              einberechnen Goodies sollten ansprechend gestaltet sein.
            </li>
            <li>
              Deine Goodies kannst du bis zum Ende der Finanzierungsphase
              hinzufügen und bearbeiten.
            </li>
          </ul>
          <p>
            Deine Goodies kannst du bis zum Ende der Finanzierungsphase
            hinzufügen und bearbeiten.
          </p>
          {/* Regular Screen */}
          {!loading &&
            this.state.addNewReward === false &&
            project.rewards?.length === 0 && (
              <div
                style={{
                  margin: '20px',
                  textAlign: 'center',
                }}
              >
                <p
                  style={{
                    fontSize: '20px',
                    color: '#ccc',
                    margin: '20px',
                    textAlign: 'center',
                  }}
                >
                  Du hast bisher noch keine Goodies erstellt.
                </p>

                <PrimaryButton
                  type="primary"
                  minWidth="150px"
                  onClick={() => {
                    this.setState({ addNewReward: true });
                  }}
                >
                  Hinzufügen
                </PrimaryButton>
              </div>
            )}
          {!loading &&
            this.state.addNewReward === false &&
            project.rewards?.length !== 0 && (
              <div>
                <Row>
                  {projectId &&
                    project.rewards.map((reward, i) => {
                      let img = reward.image;
                      // if (isMobile()) {
                      //   img = reward?.mobile_image ? reward?.mobile_image : reward.image;
                      // } else {
                      //   img = reward.image ? reward.image : reward?.mobile_image;
                      // }
                      return (
                        <Col
                          key={i}
                          // xs={{ span: 24, offset: 0 }}
                          // md={{ span: 24, offset: 0 }}
                          // sm={{ span: 24, offset: 0 }}
                          lg={{ span: 20, offset: 0 }}
                          xl={{ span: 12, offset: 0 }}
                          xxl={{ span: 8, offset: 0 }}
                          style={{ padding: '10px' }}
                        >
                          <Card
                            style={{
                              borderRadius: '30px',
                              overflow: 'hidden',
                              maxWidth: '300px',
                            }}
                            cover={
                              <img
                                style={{
                                  borderRadius: '40px',
                                  width: '300px',
                                  height: '300px',
                                  border: '1px solid #f0f0f0',
                                }}
                                alt="reward-card"
                                src={img}
                                height="150px"
                              />
                            }
                            actions={[
                              <Button
                                danger
                                type="text"
                                icon={<CloseCircleOutlined />}
                                onClick={() =>
                                  this.setState({
                                    showModal: true,
                                    selectedDeleteReward: reward.id,
                                  })
                                }
                              />,
                              <Button
                                type="link"
                                icon={<EditOutlined />}
                                onClick={() => this.handleEditReward(reward)}
                              />,
                            ]}
                          >
                            <div
                              style={{
                                textAlign: 'center',
                                fontSize: '20px',
                                fontWeight: '500',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}
                            >
                              {reward.title}
                            </div>
                            <div
                              style={{
                                textAlign: 'center',
                                fontSize: '14px',
                                color: '#999',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}
                            >
                              {reward.description}
                            </div>
                            <div
                              style={{
                                textAlign: 'right',
                                fontSize: '16px',
                                fontWeight: '750',
                                marginTop: '10px',
                              }}
                            >
                              {numberWithCommas(
                                Number(reward.price).toFixed(2)
                              )}{' '}
                              €
                            </div>
                            {/* <div
                            style={{
                              marginTop: '10px',
                              textAlign: 'center',
                              fontWeight: '700',
                            }}
                          >
                            {moment
                              .unix(reward.shipping_date)
                              .format('MM.YYYY')}
                          </div> */}
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
                <div
                  style={{
                    margin: '20px',
                    textAlign: 'center',
                  }}
                >
                  <PrimaryButton
                    type="primary"
                    minWidth="150px"
                    onClick={() => {
                      this.setState({ addNewReward: true });
                    }}
                  >
                    Hinzufügen
                  </PrimaryButton>
                </div>

                <Modal
                  showModal={this.state.showModal}
                  handelCloseModal={this.handelCloseModal}
                >
                  <div style={{ padding: '10px 30px' }}>
                    <div style={{ fontSize: '24px' }}>
                      Bist du sicher, dass du fortfahren möchtest?
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        marginTop: '20px',
                      }}
                    >
                      <PrimaryButton onClick={() => this.handleRemoveReward()}>
                        Bestätigen
                      </PrimaryButton>
                      <PrimaryButton
                        onClick={() => this.handelCloseModal()}
                        style={{ backgroundColor: '#F44336', color: '#FFF' }}
                      >
                        Abbrechen
                      </PrimaryButton>
                    </div>
                  </div>
                </Modal>
              </div>
            )}

          {/* Add New Screen */}

          {!loading && this.state.addNewReward === true && (
            <Form
              {...formLayout}
              onChange={(e) => {
                return setCanMove(false);
              }}
              name="rewards"
              id="reward-form"
              onFinish={this.onFinish}
              validateMessages={formValidateMessages}
            >
              <div>
                <FormWrapper>
                  <>
                    <FormItem
                      name="title"
                      label="Titel"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      onChange={(e) => {
                        this.setState({ title: e.target.value });
                      }}
                      initialValue={this.state.title}
                    >
                      <Input />
                    </FormItem>

                    {/* <FormItem
                      name="price"
                      label="Preis"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      onChange={(e) => {
                        this.setState({
                          price: !isNaN(
                            Number(
                              e.target.value
                                .replaceAll('.', '')
                                .replaceAll(',', '.')
                            )
                          )
                            ? Number(
                              e.target.value
                                .replaceAll('.', '')
                                .replaceAll(',', '.')
                            )
                            : 0,
                        });
                      }}
                      initialValue={this.state.price}
                    >
                      <CurrencyWrapper>
                        <div className="custom-currency-input">
                          <CurrencyInput
                            allowNegativeValue={false}
                            decimalSeparator=","
                            groupSeparator="."
                            placeholder="0,00"
                            value={this.state.price}
                          />
                        </div>
                      </CurrencyWrapper>
                    </FormItem> */}

                    <FormItem
                      name="price"
                      label="Preis"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const floatValue = parseFloat(inputValue);
                        this.setState({
                          price:
                            !isNaN(floatValue) && isFinite(floatValue)
                              ? inputValue !== ''
                                ? floatValue
                                : undefined
                              : undefined,
                        });
                      }}
                      initialValue={this.state.price}
                    >
                      <CurrencyWrapper>
                        <div className="custom-currency-input">
                          <input
                            type="number"
                            step="any"
                            placeholder="Enter price"
                            value={this.state.price}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const numericValue = Number(inputValue);

                              // Check if the input is a valid number
                              if (!isNaN(numericValue)) {
                                this.setState({
                                  price: numericValue,
                                });
                              }
                            }}
                          />
                        </div>
                      </CurrencyWrapper>
                    </FormItem>

                    <FormItem
                      name="description"
                      label="Beschreibung"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      onChange={(e) => {
                        this.setState({ description: e.target.value });
                      }}
                      initialValue={this.state.description}
                    >
                      <Input.TextArea showCount maxLength={500} />
                    </FormItem>
                    {/* <b>Für Mobil- und Desktop-Optimierung Bilder bitte in verschiedenen Größen hochladen</b><br/> */}
                    <UploadImage
                      required={this.state.rewardID ? false : true}
                      multiple={false}
                      label="Bild"
                      name="image"
                      fileList={
                        this.state.image?.length ? this.state.image : null
                      }
                      setImage={(img) => {
                        this.setState({ image: img });
                      }}
                    />
                    {/* <p>Bild für die <b>Desktop-Ansicht</b> </p> */}
                    <p className="remark">
                      Empfohlene Größe: 1000 x 1000 pixels
                    </p>

                    {/* <UploadImage
                      required={this.state.rewardID ? false : true}
                      multiple={false}
                      label="Mobiles Bild"
                      name="mobile_image"
                      setImage={(img) => {
                        this.setState({ mobile_image: img });
                      }}
                    />
                    <p>Bild für die <b>mobile Ansicht</b> </p>
                    
                    <p className="remark">
                        Empfohlene Größe: 300 x 300 pixels
                      </p> */}
                    <FormItem
                      name="address_required"
                      label="Wird eine Lieferadresse benötigt?"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      onChange={(e) => {
                        this.setState({ address_required: e.target.value });
                      }}
                      initialValue={this.state.address_required}
                    >
                      <Radio.Group>
                        <Radio value={true}>Ja</Radio>
                        <Radio value={false}>
                          Nein, z.B. digitale Belohnung
                        </Radio>
                      </Radio.Group>
                    </FormItem>

                    {/* Date Picker Removed For Now */}
                    {/* <FormItem
                      name="shipping_date"
                      label="Voraussichtliche Lieferung (frühestens nach Finanzierungszeitraum)"
                      extra="Wähle einen realistischen Lieferzeitpunkt für den Versand des Goodies an deine Unterstützer."
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      initialValue={
                        this.state.shipping_date
                          ? moment.unix(this.state.shipping_date)
                          : null
                      }
                    >
                      <DatePicker
                        disabledDate={(current) => {
                          return current && current < moment().endOf('day');
                        }}
                        picker="month"
                        locale={locale}
                        format="MM.YYYY"
                        onChange={(e) => {
                          this.setState({ shipping_date: e.unix() });
                        }}
                      />
                    </FormItem> */}

                    <FormItem
                      name="shipping_date"
                      label="Voraussichtliche Lieferung"
                      extra="Wähle einen realistischen Lieferzeitpunkt für den Versand des Goodies an deine Unterstützer."
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      initialValue={this.state.shipping_date ? 1 : 0}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          this.setState({ shipping_date: e.target.value });
                        }}
                      >
                        <Radio value={1}> Goody ist sofort lieferbar </Radio>
                        <Radio value={0}>
                          Goody bei erfolgreichem Crowdfunding lieferbar
                        </Radio>
                      </Radio.Group>
                    </FormItem>

                    <FormItem
                      label="Limitierung"
                      name="show_max_stock"
                      extra="Möchtest du die angebotene Anzahl dieser Goodies begrenzen?"
                      initialValue={this.state.showMaxStock}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          this.setState({ showMaxStock: e.target.value });
                        }}
                      >
                        <Radio value={true}> Ja </Radio>
                        <Radio value={false}>Nein</Radio>
                      </Radio.Group>
                    </FormItem>

                    {this.state.showMaxStock && (
                      <FormItem
                        name="max_order_count"
                        label="Max. Anzahl eingeben"
                        extra="Gib die maximale Verfügbarkeit an, wenn du es limitieren möchtest."
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        onChange={(e) => {
                          this.setState({ max_order_count: e.target.value });
                        }}
                        initialValue={this.state.max_order_count}
                      >
                        <Input type="number" min={1} />
                      </FormItem>
                    )}

                    <FormItem
                      name="no_multi_order"
                      label="Mehrfachauswahl"
                      extra="Möchtest du die Anzahl pro Unterstützer auf eine Buchung begrenzen?"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      onChange={(e) => {
                        this.setState({ no_multi_order: e.target.value });
                      }}
                      initialValue={this.state.no_multi_order}
                    >
                      <Radio.Group>
                        <Radio value={true}>Ja</Radio>
                        <Radio value={false}> Nein </Radio>
                      </Radio.Group>
                    </FormItem>

                    {/* Questions */}

                    <FormItem
                      label="Fragen an den Unterstützer"
                      extra="Frage Varianten bei den Unterstützenden ab (z.B. Größe, S, M, L; Farbe etc.) oder nutze das Feld Eigenschaft für deine individuelle Abfrage (z.B. dein Widmungstext)"
                    >
                      {this.state.questions.map((question, i) => {
                        return (
                          <div key={i} style={{ textAlign: 'center' }}>
                            {question.map((item, j) => {
                              return (
                                <Row
                                  key={j + i * 100}
                                  style={{ width: '100%' }}
                                >
                                  <Col
                                    md={{ span: 8, offset: 0 }}
                                    lg={{ span: 5, offset: 0 }}
                                    xl={{ span: 3, offset: 0 }}
                                    style={{
                                      padding: '5px',
                                      height: '50px',
                                      lineHeight: '25px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {j === 0 && 'Frage ' + (i + 1)}
                                    {j !== 0 && 'Antwort ' + j}
                                  </Col>

                                  <Col
                                    md={{ span: 10, offset: 0 }}
                                    lg={{ span: 14, offset: 0 }}
                                    xl={{ span: 16, offset: 0 }}
                                    style={{ padding: '5px' }}
                                  >
                                    <Input
                                      onChange={(e) =>
                                        this.handleInputChange(e, i, j)
                                      }
                                      placeholder={
                                        j === 0 ? 'Frage' : `Antwort`
                                      }
                                      required={true}
                                      value={this.state.questions[i][j]}
                                    />
                                  </Col>
                                  <Col
                                    md={{ span: 4, offset: 0 }}
                                    lg={{ span: 3, offset: 0 }}
                                    xl={{ span: 2, offset: 0 }}
                                    style={{
                                      padding: '5px',
                                      height: '50px',
                                      lineHeight: '25px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {
                                      <Button
                                        type="link"
                                        danger
                                        size="large"
                                        onClick={() =>
                                          this.handleRemoveQuestionAnswer(i, j)
                                        }
                                      >
                                        <MinusCircleOutlined />
                                      </Button>
                                    }
                                  </Col>
                                </Row>
                              );
                            })}
                            <Button
                              style={{ margin: '10px 20px 20px' }}
                              shape="circle"
                              size="small"
                              onClick={(e) => this.handleAddAnswerClick(i)}
                            >
                              +
                            </Button>
                          </div>
                        );
                      })}
                      <div>
                        Frage hinzufügen
                        <Button
                          style={{ margin: '30px 10px', borderRadius: '10px' }}
                          icon={<PlusOutlined />}
                          onClick={this.handleAddQuestionClick}
                        />
                      </div>
                      {/* For Testing Purposes */}
                      {/* <div style={{ marginTop: 20 }}>
                        {JSON.stringify(this.state.questions)}
                      </div> */}
                    </FormItem>

                    {/* End Questions */}

                    <FormItem>
                      <PrimaryButton
                        type="primary"
                        htmltype="submit"
                        minWidth="150px"
                        id="submit-button"
                      >
                        Hinzufügen
                      </PrimaryButton>
                    </FormItem>
                  </>
                </FormWrapper>
              </div>
            </Form>
          )}
        </Spin>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  const { currentStep, project, loading, errors, isUniq } = state.projects;
  return {
    currentStep,
    project,
    loading,
    errors,
    isUniq,
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkUrl: (url) => dispatch(ProjectsActions.checkUrl(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
