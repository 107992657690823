import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Checkbox, Spin } from 'antd';
import { CountriesSelect } from 'components';
import {
  PrimaryButton,
  IsLoggedIn,
  Modal,
  device,
  formValidateMessages,
  H1,
} from 'utils';
import { InfoCardForm } from 'components';
import styled from '@emotion/styled';
import { PaymentActions } from 'redux/actions';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { store } from 'react-notifications-component';
import config from 'config';
import PaymentMethod from './PaymentMethod';
import { Elements } from '@stripe/react-stripe-js';
import PaymentMethod2 from './PaymentMethod2';
const stripePromise = loadStripe(config.REACT_APP_STRIPE_KEY);

const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};

const FormItem = Form.Item;

const InfoFormWrapper = styled.div`
  @media ${device.allMobile} {
    margin-top: 0;
  }
  .ant-select-selector {
    border-radius: 10px !important;
    padding: 6.5px 11px !important;
    height: auto !important;
  }
  button {
    margin-top: 40px;
    @media ${device.allMobile} {
      margin-top: 20px;
    }
  }
  .info-input {
    label {
      display: none;
    }
  }
  .payment-options {
    display: flex;
    span {
      cursor: pointer;
      & + span {
        margin-left: 25px;
      }
    }
  }
  .remove-header {
    @media ${device.allMobile} {
      margin: 0px;
      font-size: 4px;
      padding: 0px;
    }
  }
  .remove-label {
    .ant-form-item-label {
      display: none;
    }
  }
`;

export class InfoForm extends Component {
  static propTypes = {
    setCurrentViewState: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
    tips: PropTypes.number.isRequired,
    selectedCard: PropTypes.object.isRequired,
    card: PropTypes.object.isRequired,
    makeDonation: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
    donationFormRef: PropTypes.object.isRequired,
    addressRequired: PropTypes.bool.isRequired,
    selectedRewards: PropTypes.any.isRequired,
    clientSecret: PropTypes.any.isRequired,
    handleCLientSecret: PropTypes.func.isRequired,
  };

  state = {
    paymentMethods: null,
    showModal: false,
    loading: false,
    paymentData: null,
    countryValue: '',
  };
  handleChange = (value) => {
    if (!IsLoggedIn() && (value === 'klarna' || value === 'giro')) {
      this.handelShowModal();
    }
    this.setState({
      paymentMethods: value,
    });
  };

  handleCard = () => {
    this.setState({
      paymentType: 'card',
    });
  };
  handlePayPal = () => {
    this.setState({
      paymentType: 'payPal',
    });
  };
  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };
  handleCountryChange = (countryValue) => {
    this.setState({
      countryValue,
    });
  };
  // extractRewards = (selectedRewards) => {
  //   let rewards = {};
  //   selectedRewards.forEach((reward) => {
  //     if (!rewards.hasOwnProperty(reward.id)) rewards[reward.id] = [];
  //     rewards[reward.id].push(reward.answers);
  //   });
  //   return rewards;
  // };

  // onFinish = async (values) => {
  //   // Validate the Form in Donation First
  //   await Promise.all([this.props.donationFormRef.current.validateFields()])
  //     .then(async () => {
  //       this.setState({ loading: true });
  //       const stripe = await stripePromise;
  //       const { amount, tips, projectId } = this.props;
  //       let donation;
  //       if (this.props.selectedRewards.length > 0) {
  //         const rewards = this.extractRewards(this.props.selectedRewards);
  //         donation = {
  //           ...values,
  //           rewards,
  //           amount: Number(amount),
  //           tips: Number(tips),
  //           project_id: Number(projectId),
  //         };
  //       } else {
  //         donation = {
  //           ...values,
  //           amount: Number(amount),
  //           tips: Number(tips),
  //           project_id: Number(projectId),
  //         };
  //       }

  //       const response = await fetch('/payments/payment_session', {
  //         method: 'POST',
  //         body: JSON.stringify({ payment_session: donation }),
  //         redirect: 'follow',
  //       });

  //       // Send event to Google Analytics
  //       window.ga('send', {
  //         hitType: 'event',
  //         eventCategory: 'User',
  //         eventAction: 'Clicked Donate',
  //         eventLabel: `Project ID: ${projectId}`,
  //       });

  //       // When the customer clicks on the button, redirect them to Checkout.
  //       const result = await stripe.redirectToCheckout({
  //         sessionId: response.id,
  //       });
  //       this.setState({ loading: false });
  //       if (result.error) {
  //         // If `redirectToCheckout` fails due to a browser or network
  //         // error, display the localized error message to your customer
  //         // using `result.error.message`.
  //         store.addNotification({
  //           ...notification,
  //           title: 'Fehler!',
  //           type: 'danger',
  //           message: result.error.message,
  //         });
  //       }
  //     })
  //     .catch((error) => {});
  // };

  // onFinish = async (values) => {
  //   this.setState({ loading: true });
  //   const { amount, tips, projectId, selectedRewards } = this.props;
  //   const rewards = selectedRewards.map((reward) => {
  //     return reward?.id;
  //   });
  //   this.setState({ paymentData: { ...values, rewards } });
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   // Create Payment-Intent
  //   fetch('/payments/payment_intent', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       payment_intent: {
  //         first_name:
  //           user?.first_name == null ? values?.first_name : user?.first_name,
  //         last_name:
  //           user?.last_name == null ? values?.last_name : user?.last_name,
  //         email: user?.email == null ? values?.email : user?.email,
  //         user: true,
  //         project_id: projectId,
  //         amount: amount,
  //         tips: tips,
  //       },
  //       reward_ids: rewards.length == 0 ? [] : rewards,
  //     }),
  //   })
  //     .then((result) => {
  //       console.log(result?.payment_intent?.client_secret, 'cleint');
  //       this.setState({
  //         loading: false,
  //       });
  //       this.props.handleCLientSecret(result?.payment_intent?.client_secret);
  //     })
  //     .catch((err) => console.log('err'));
  //   this.setState({ loading: false });
  // };
  // componentDidMount() {
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   const url = window.location.href
  //   const parameters = new URLSearchParams(url);
  //   const rewardId = parameters.get('rewardId');
  //   const { amount, tips, projectId } = this.props;
  //   const rewards = this.props.selectedRewards?.map((reward) => {
  //     return reward?.id;
  //   });
  //   // Create Payment-Intent
  //   fetch('/payments/payment_intent', {
  //     method: 'POST',
  //     body: JSON.stringify({
  //       payment_intent: {
  //         first_name: user?.first_name == null ? '' : user?.first_name,
  //         last_name: user?.last_name == null ? '' : user?.last_name,
  //         email: user?.email == null ? '' : user?.email,
  //         user: true,
  //         project_id: projectId,
  //         amount: amount,
  //         tips: tips,
  //       },
  //       reward_ids:rewardId == null ? (rewards && rewards?.length == 0 ? [] : rewards) : [rewardId],
  //     }),
  //     redirect: 'follow',
  //   })
  //     .then((result) => {
  //       console.log('result', result.client_secret);
  //       this.props.handleCLientSecret(result?.payment_intent?.client_secret);
  //     })
  //     .catch((err) => console.log('err'));
  // }

  render() {
    const { showModal, loading, paymentData, countryValue } = this.state;
    const { amount, tips, projectId, selectedRewards, clientSecret, project } =
      this.props;
    const options = {
      appearance: {
        theme: 'stripe',
      },
      clientSecret,
    };

    return (
      <Spin spinning={loading}>
        {/* {clientSecret && stripePromise && (
          <Elements options={options} stripe={stripePromise}>
            <PaymentMethod
              project_id={projectId}
              amount={amount}
              tips={tips}
              paymentData={paymentData}
              selectedRewards={selectedRewards}
              project={project}
            />
          </Elements>
        )} */}
        <PaymentMethod2
          project_id={projectId}
          amount={amount}
          tips={tips}
          paymentData={paymentData}
          selectedRewards={selectedRewards}
          project={project}
        />

        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <InfoCardForm setCurrentView={() => this.handelCloseModal()} />
        </Modal>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  const { card, selectedCard, donation, loading, errors } = state.payment;
  return {
    selectedCard,
    loading,
    errors,
    donation,
    card,
  };
};

const mapDispatchToProps = (dispatch) => ({
  makeDonation: (donation) => dispatch(PaymentActions.makeDonation(donation)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InfoForm);
