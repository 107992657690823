import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  LogoWhite,
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  LinkedIn,
  KlarnaCard,
  MaestroCard,
  Mastercard,
  VisaCard,
  PaymentIcon1,
  PaymentIcon2,
  PaymentIcon4,
  SepaImages,
  GooglePay,
  ApplePay,
} from 'images';
import PayPal_logo from '../../images/PayPal-logo.svg';
import FooterBackground from 'images/footerWrapper.svg';
import { Colors, SvgWrapper, device, isMobile, isTablet } from 'utils';
import { Form, Input } from 'antd';
import { store } from 'react-notifications-component';
import NewsLetter from 'scenes/Home/components/NewsLetter';
import MuhammedSAW from 'components/MuhammadSaw';
import SawWhite from './../../images/White-sallallahu-alaihi-wasallam.png';
import hijriMoment from 'moment-hijri';
import { WhatsAppOutlined } from '@ant-design/icons';
import './footer.css';

const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};
const FormItem = Form.Item;
const FooterWrapper = styled.footer`
  // background-image: url(${FooterBackground});
  background-color: #1e312b;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 35px;
  padding-bottom: 10px;
  color: ${Colors.white};
  a:hover {
    color: ${Colors.steel};
  }
  @media ${device.allMobile} {
    padding-top: 25px;
  }
  .content {
    margin: auto;
    max-width: 1440px;
    padding: 0 40px;
    @media (max-width: 1441px) {
      max-width: 950px;
    }
  }
  header {
    text-align: center;
    img {
      width: 200px;
      margin: auto;
      margin-bottom: 35px;
    }
  }
  h1,
  h2 {
    margin: 0;
    color: ${Colors.white};
  }
  .qute {
    text-align: center;
    font-size: 19px;
    margin: 20px auto;
  }
  q {
    font-size: 19px;
    font-weight: bold;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .flex,
  .social {
    display: flex;
    justify-content: space-between;
  }
  .flex {
    @media ${device.allMobile} {
      display: block;
      text-align: center;
      div {
        width: 100%;
        margin-bottom: 25px;
      }
    }
  }

  .payment {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 200px;
    & > span {
      margin: auto 5px 5px;
    }
  }
  .social,
  .payment {
    @media ${device.allMobile} {
      max-width: 200px;
      margin: auto;
    }
  }
  footer {
    text-align: right;
    a {
      text-decoration: underline;
    }
    p {
      @media ${device.allMobile} {
        text-align: center;
      }
      span {
        @media ${device.allMobile} {
          display: block;
        }
      }
    }
  }
  .newsletter-form {
    margin-top: 15px;
    margin-bottom: 0;
    width: 600px;
    margin-left: auto;
    @media ${device.laptop} {
      width: 430px;
    }
    @media ${device.minLaptop} {
      width: 430px;
    }
    @media ${device.tablet} {
      width: 370px;
    }
    @media ${device.allMobile} {
      width: 100%;
    }
    form {
      display: flex;
    }
    .ant-form-item {
      width: 70%;
      & + .ant-form-item {
        width: 30%;
      }
      input {
        height: 54px;
        border-radius: 5px 0 0 5px;
      }
    }
    button {
      background: none;
      border: 1px solid ${Colors.white};
      box-shadow: none;
      border-radius: 2px;
      color: ${Colors.white};
      margin-top: 0;
      width: 100%;
      padding: 15px 0;
      border-radius: 0 5px 5px 0;
      border-left: none;
    }
  }
`;

export default class Footer extends Component {
  sendNewsletter = ({ email }) => {
    const raw = JSON.stringify({ email: email });
    const requestOptions = {
      method: 'POST',
      body: raw,
      redirect: 'follow',
    };
    return fetch(`newsletter?email=${email}`, requestOptions)
      .then((email) => {
        store.addNotification({
          ...notification,
          title: 'Danke!',
          type: 'success',
          message: 'Du wurdest im Newsletter eingetragen.',
        });
        return { email };
      })
      .catch((error) => {
        store.addNotification({
          ...notification,
          title: 'Fehler!',
          type: 'danger',
          message: error,
        });
        return Promise.reject(error);
      });
  };
  onFinish = (values) => {
    return this.sendNewsletter(values);
  };

  render() {
    return (
      <>
        <NewsLetter />
        <FooterWrapper>
          {(isMobile() || isTablet()) && (
            <div
              style={{
                marginBottom: 30,
                textAlign: 'center',
              }}
            >
              <p>{hijriMoment().format('DD.MM.YYYY')}</p>
              <p>
                {hijriMoment().format('iDD')}{' '}
                {islamicMonths[hijriMoment().format('iMM')]}{' '}
                {hijriMoment().format('iYYYY')}
              </p>
            </div>
          )}
          <div className="content">
            <header>
              <h1>
                <Link to="/">
                  <span className="visually-hidden">CrowdFunding</span>
                  <img src={LogoWhite} alt="crowd-funding-logo" />
                </Link>
              </h1>
            </header>
            <p className="qute">
              <q>
                Der Beste unter den Menschen ist derjenige, der seinen
                Mitmenschen am nützlichsten ist.
              </q>
            </p>
            <p className="qute">
              <MuhammedSAW image={SawWhite} />
            </p>
            <div className="flex">
              <div>
                <h2>commonsplace</h2>
                <ul>
                  <li>
                    <Link to="/about-us">Über uns</Link>
                  </li>
                  <li>
                    <Link to="/how-it-works">Wie funktioniert es</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Kontakt</Link>
                  </li>
                  <li>
                    <a
                      href="http://muslimlab.de/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MuslimLab
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2>Hilfe</h2>
                <ul>
                  <li>
                    <Link to="/faqs">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/guidelines">Richtlinien</Link>
                  </li>
                  <li>
                    <Link to="/fees">Gebühren</Link>
                  </li>
                  <li>
                    <Link to="/legitimation">Legitimation</Link>
                  </li>
                  <li>
                    <Link to="/transparency-and-trust">
                      Vertrauen und Sicherheit
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2>Bezahlmethoden</h2>
                <div className="payment">
                  <SvgWrapper width={'35px'} height={'35px'}>
                    <KlarnaCard />
                  </SvgWrapper>
                  <SvgWrapper width={'35px'} height={'35px'}>
                    <MaestroCard />
                  </SvgWrapper>
                  <SvgWrapper width={'35px'} height={'35px'}>
                    <Mastercard />
                  </SvgWrapper>
                  <SvgWrapper width={'35px'} height={'35px'}>
                    <VisaCard />
                  </SvgWrapper>
                  <SvgWrapper width={'35px'} height={'35px'}>
                    <PaymentIcon1 />
                  </SvgWrapper>
                  <SvgWrapper width={'45px'} height={'45px'}>
                    <PaymentIcon2 />
                  </SvgWrapper>
                  <SvgWrapper width={'35px'} height={'35px'}>
                    <img src={SepaImages} alt="sepa-pay-icon" />
                  </SvgWrapper>
                  <SvgWrapper width={'35px'} height={'35px'}>
                    <PaymentIcon4 />
                  </SvgWrapper>
                  <SvgWrapper width={'35px'} height={'35px'}>
                    <img src={GooglePay} alt="google-pay-icon" />
                  </SvgWrapper>
                  <SvgWrapper width={'35px'} height={'35px'}>
                    <img src={ApplePay} alt="apple-pay-icon" />
                  </SvgWrapper>
                  <SvgWrapper width={'50px'} height={'35px'}>
                    <img
                      style={{ height: '100%' }}
                      src={PayPal_logo}
                      alt="paypal-logo-icon"
                    />
                  </SvgWrapper>
                </div>
              </div>
              <div>
                <h2>Folge uns auf</h2>
                <div className="social">
                  <a
                    href="https://www.facebook.com/commonsplace.de"
                    target="_blank"
                    rel="noreferrer"
                    alt="facebook"
                  >
                    <SvgWrapper>
                      <FacebookIcon color={Colors.white} />
                    </SvgWrapper>
                  </a>
                  <a
                    href="https://www.instagram.com/commonsplace_de/"
                    target="_blank"
                    rel="noreferrer"
                    alt="instagram"
                  >
                    <SvgWrapper>
                      <InstagramIcon color={Colors.white} />
                    </SvgWrapper>
                  </a>
                  <a
                    href="https://youtube.com/channel/UCDZ5ZtMl_l7Vm0ynrTp6sVg"
                    target="_blank"
                    rel="noreferrer"
                    alt="youtube"
                  >
                    <SvgWrapper>
                      <YoutubeIcon color={Colors.white} />
                    </SvgWrapper>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/cp21"
                    target="_blank"
                    rel="noreferrer"
                    alt="linkedin"
                  >
                    <SvgWrapper>
                      <LinkedIn color={Colors.white} />
                    </SvgWrapper>
                  </a>
                  <a
                    className="footer-whatsapp-icon"
                    href="https://whatsapp.com/channel/0029VaMMAq50QealT9ayL51r"
                    target="_blank"
                    rel="noreferrer"
                    alt="linkedin"
                  >
                    <SvgWrapper>
                      <WhatsAppOutlined color={Colors.white} />
                    </SvgWrapper>
                  </a>
                </div>
                {!isMobile() && !isTablet() && (
                  <div style={{ marginTop: 30 }}>
                    <div>{hijriMoment().format('DD.MM.YYYY')}</div>
                    <div>
                      {hijriMoment().format('iDD')}{' '}
                      {islamicMonths[hijriMoment().format('iMM')]}{' '}
                      {hijriMoment().format('iYYYY')}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="newsletter-form">
              <Form name="newsletter" onFinish={this.onFinish}>
                <FormItem
                  name="email"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Im Newsletter eintragen und nichts verpassen!"
                  />
                </FormItem>
                <FormItem>
                  <button type="primary" htmltype="submit">
                    Eintragen
                  </button>
                </FormItem>
              </Form>
            </div> */}
            <footer style={{ textAlign: 'center', marginTop: '2%' }}>
              <p>
                <Link to="/impressum"> Impressum</Link>{' '}
                <Link to="/datenschutz"> Datenschutz</Link>{' '}
                <Link to="/anb"> ANB</Link>{' '}
                <span>© {hijriMoment().format('YYYY')} commonsplace UG</span>
              </p>
            </footer>
          </div>
        </FooterWrapper>
      </>
    );
  }
}

const islamicMonths = {
  '01': 'Muharram',
  '02': 'Safar',
  '03': 'Rabi al-Awwal',
  '04': 'Rabi al-Thani',
  '05': 'Jumada al-Awwal',
  '06': 'Jumada al-Thani',
  '07': 'Rajab',
  '08': "Sha'ban",
  '09': 'Ramadan',
  10: 'Shawwal',
  11: "Dhu al-Qi'dah",
  12: 'Dhu al-Hijjah',
};
