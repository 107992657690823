import React, { Component } from 'react';
import { Layout } from 'components';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { Form, Input, Spin } from 'antd';
import { Container, PrimaryButton, Colors, H1, device } from 'utils';
import { AdminActions } from 'redux/actions';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'loadsh/isEmpty';
import ContactModal from 'scenes/Project/components/ContactModal';

const FormItem = Form.Item;
const LoginFormWrapper = styled.div`
  min-width: 500px;
  padding: 0 50px 40px;
  @media ${device.allMobile} {
    min-width: 1px;
    padding: 0;
    max-width: 100%;
  }
  h1 {
    font-size: 25px;
    font-weight: bold;
  }
  .ant-form-item:last-of-type,
  .ant-form-item:last-of-type button {
    margin: 0;
    min-width: 100%;
  }
  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
      label {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .ant-form-item-control-input-content > input,
    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
      @media ${device.allMobile} {
        width: 80%;
      }
    }
  }
  .login-actions {
    display: flex;
    justify-content: space-between;
  }
`;
const LoginWrapper = styled.div`
  max-width: 550px;
  margin: 100px auto;
`;
class LoginPage extends Component {
  static propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool,
    errors: PropTypes.array,
    login: PropTypes.func.isRequired,
  };

  onFinish = (values) => {
    const { login } = this.props;
    login(values);
  };

  render() {
    const { loading, user } = this.props;
    const userToken = localStorage.getItem('access-token');
    const client = localStorage.getItem('client');
    const isLoggedIn = !isEmpty(user) && userToken && client;
    return (
      <Layout>
        <ContactModal />
        <Container>
          <LoginWrapper>
            <LoginFormWrapper>
              {isLoggedIn && <Redirect to={{ pathname: '/' }} />}
              <Spin spinning={loading}>
                <H1>Admin Anmelden</H1>
                <Form name="login" onFinish={this.onFinish}>
                  <FormItem
                    name="email"
                    label="E-Mail-Adresse"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                    ]}
                  >
                    <Input type="email" />
                  </FormItem>
                  <FormItem
                    label="Passwort"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Bitte gib dein Passwort ein.',
                      },
                    ]}
                  >
                    <Input.Password />
                  </FormItem>

                  <FormItem>
                    <PrimaryButton htmltype="submit">Anmelden</PrimaryButton>
                  </FormItem>
                </Form>
              </Spin>
            </LoginFormWrapper>
          </LoginWrapper>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, loading, errors } = state.admin;
  return {
    user,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(AdminActions.login(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
