import React, { Component } from 'react';
import { Collapse } from 'components';
import styled from '@emotion/styled';
import { Colors, H1, device } from 'utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ProjektinitiatorFAQsWrapper = styled.div`
  padding: 20px;
  .faq__collapse {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 0.5px solid ${Colors.secondaryColor};
    h2 {
      font-size: 22px;
      font-weight: 600;
      @media ${device.allMobile} {
        font-size: 18px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 15px;
      color: ${Colors.steel};
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
`;

export default class ProjektinitiatorFAQs extends Component {
  render() {
    const { currentOpen, setCurrentOpen } = this.props;
    return (
      <ProjektinitiatorFAQsWrapper>
        <H1>Projektinitiator</H1>
        <div>
          <Collapse
            title={<h2>1. Wer kann ein Projekt initiieren?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator1' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator1'}
          >
            <p>
              Du musst mindestens 18 Jahre sein und eine Wohnanschrift in
              Deutschland haben.
            </p>
          </Collapse>
          <Collapse
            title={<h2>2. Wie kann ich meine Spenden auszahlen lassen?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator2' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator2'}
          >
            <p>
              Bei Erreichen des Fundingziels und erfolgreicher Legitimation
              werden die Unterstützungen automatisch auf dein in der Anmeldung
              angegebenes Konto ausgezahlt. Bei sozialen Projekten werden die
              Unterstützungen am Ende des Finanzierungszeitraums auch
              ausgezahlt, sollte das Fundingziel nicht erreicht worden sein.
              I.d.R. erhältst du deine Unterstützungen nach 2-4 Werktagen.
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>
                3. Kann man Projekte auch aus der Schweiz oder Österreich
                initiieren?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator3' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator3'}
          >
            <p>
              Zurzeit ist es leider noch nicht möglich. Sollte es zu einer
              Änderung kommen, werden wir dich umgehend über unsere Kanäle
              informieren!
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>
                4. Wie lange dauert es, bis ich meine Unterstützung erhalte, und
                gibt es eine Möglichkeit, dies zu beschleunigen?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator4' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator4'}
          >
            <p>
              Die Auszahlung deiner gesamten Fundingsumme kann in der Regel 2-4
              Werktage dauern, da Banken und Zahlungsdienstleister die
              erforderlichen Autorisierungen durchführen müssen. Falls du eine
              schnellere Auszahlung benötigst, bietet die Instant Payout-Option
              die Möglichkeit, deine Gelder innerhalb von 30 Minuten auf deinem
              Konto zu haben, vorausgesetzt, deine Bank unterstützt Instant
              Payout. Für die Nutzung dieser Option wird eine Gebühr von 2 %
              erhoben, die automatisch vom Auszahlungsbetrag abgezogen wird.
            </p>
          </Collapse>
          <Collapse
            title={<h2>5. Ist meine Idee für commonsplace geeignet?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator5' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator5'}
          >
            <p>
              Jedes Projekt, welches nicht gegen{' '}
              <Link to="/guidelines"> unsere Regeln</Link>verstößt ist bei uns
              grundsätzlich am richtigen Ort. Wenn du dir unsicher bist, kannst
              du uns gerne unter support@commonsplace.de kontaktieren.
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>6. Wie kann ich meine UnterstützerInnen kontaktieren?</h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator6' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator6'}
          >
            <p>Über das Update Feld deiner Projektseite.</p>
          </Collapse>
          <Collapse
            title={
              <h2>
                7. Was passiert, wenn ich mehr als das gesetzte Fundingziel
                erhalte?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator7' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator7'}
          >
            <p>
              Dann ist die Community von dem Potential deiner Idee überzeugt und
              den Überschuss kannst du zusätzlich in das Projekt investieren!
            </p>
          </Collapse>
          <Collapse
            title={<h2>8. Wie bearbeite ich mein Projekt?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator8' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator8'}
          >
            <p>Mein Profil → auf dein Projekt → Projekt bearbeiten</p>
          </Collapse>
          <Collapse
            title={
              <h2>
                9. Wieso hat commonsplace Geld von meinem Konto abgebucht?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator9' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator9'}
          >
            <p>
              Sollten Unterstützer ihre Unterstützungen im Nachhinein widerrufen
              (die Widerrufungszeit beträgt 14 Tage), buchen wir das Geld
              automatisch von deinem Konto ab, und überweisen es auf das Konto
              des Unterstützers zurück. Du erhältst eine Stornorechnung per
              Email.
            </p>
          </Collapse>
          <Collapse
            title={<h2>10. Kann ich meine Projektlaufzeit verlängern?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator10' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator10'}
          >
            <p>
              Wenn du deine Fundingsumme innerhalb des Finanzierungszeitraums
              nicht erreichst, kannst du eine Verlängerung des
              Finanzierungszeitraums beantragen. Das commonsplace Team muss die
              Verlängerungen nach Beantragung genehmigen.
            </p>
          </Collapse>
          <Collapse
            title={<h2>11. Wie gewinne ich Unterstützer?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator11' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator11'}
          >
            <>
              <p>
                Dein Projekt muss an Bekanntschaft innerhalb der Community
                gewinnen. Es reicht nicht aus, das Projekt ausschließlich auf
                commonsplace zu initiieren:
                <ul>
                  <li>
                    Arbeite mit aussagekräftigen Bildern, Videos und
                    Beschreibungen
                  </li>
                  <li>Teile dein Projekt über</li>
                  <li>Soziale Medien, per E-Mail, Facebook, Twitter, etc.</li>
                  <li>
                    Hänge Plakate auf bspw. in deiner lokalen Moschee
                    Organisation
                  </li>
                  <li>
                    Informiere die Community stets über Neuigkeiten deines
                    Projekts auf deiner Projektseite
                  </li>
                  <li>Sei kreativ!</li>
                </ul>
              </p>
            </>
          </Collapse>
          <Collapse
            title={
              <h2>12. Wie wird mein Projekt den Kategorien zugeordnet?</h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator12' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator12'}
          >
            <p>
              Bei der Projekterstellung kannst du die Kategorie für dein Projekt
              auswählen.
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>
                13. Kann ich mein Projekt zu einem späteren Zeitpunkt noch
                bearbeiten?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator13' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator13'}
          >
            <p>
              Ja. Nach der ersten Veröffentlichung wird jede getätigte Änderung
              von uns geprüft und dann freigegeben.
            </p>
          </Collapse>
          <Collapse
            title={
              <h2>
                14. Kann ich mein Projekt abbrechen oder löschen und was
                passiert dann mit den Unterstützungen?
              </h2>
            }
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator14' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator14'}
          >
            <>
              <p>
                Bevor das Projekt gestartet ist, kannst du dein Projekt löschen.
                Bitte kontaktiere uns hierfür unter Angabe einer Begründung
                unter support@commonsplace.de
              </p>
              <p>
                Während der Finanzierungsphase kannst du dein Projekt abbrechen.
                Die Unterstützungen deiner Supporter werden dann an andere
                Projekte auf commonsplace verteilt.
              </p>{' '}
              <p>
                Wenn du das Geld erhalten hast und du aus besonderen Umständen
                das Projekt nicht realisieren kannst, informiere bitte deine
                Unterstützer und erkläre ihnen die Gründe. Bitte kontaktiere uns
                über support@commonsplace.de und erkläre, wieso du dein Projekt
                nicht realisieren kannst. Deine erhaltenenen Unterstützungen
                können wir leider nicht mehr rückabwickeln. Wenn du das Geld
                zurückzahlen möchtest, bitten wir dich die Unterstützer
                selbstständig zu kontaktieren und weiteres mit ihnen zu klären.
              </p>{' '}
            </>
          </Collapse>

          <Collapse
            title={<h2>15. Was passiert mit meinen Legitimationsdaten?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator15' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator15'}
          >
            <p>
              Die Stammdaten, die du in der Projektoberfläche unter
              "Legitimation" eingibst, werden nicht öffentlich freigegeben.
              Unser Zahlungsdienstleister Stripe (verantwortlich für die
              Legitimation) erfüllt strengste Datenschutzanforderungen . Die
              öffentliche Erklärung von Stripe zur Verarbeitung
              personenbezogener Daten findest du{' '}
              <a href="https://stripe.com/privacy-center/legal">
                https://stripe.com/privacy-center/legal
              </a>
              . Deine persönlichen Daten werden mit anerkannter
              SSL-Verschlüsselung an unseren Zahlungsdienstleister übermittelt.
              Nach erfolgreichem Abschluss des Projekts werden die Daten gemäß
              der gesetzlichen Aufbewahrungsfrist (Artikel 5 des
              Geldwäschegesetzes) bis zu 5 Jahre gespeichert. Auch wenn du ein
              erneutes Projekt auf commonsplace anlegst, musst du dich
              legitimieren.
            </p>
          </Collapse>
          <Collapse
            title={<h2>16. Welche Goodies sollte ich anbieten?</h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator16' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator16'}
          >
            <p>
              Goodies können vielfältig sein. Sie können materieller oder
              immaterieller Natur sein.{' '}
            </p>
            <p>
              Produkte, z.B. <br />
              Ein spannendes und signiertes Buch <br />
              Der plastikfreie Stift
            </p>
            <p>
              Produkte, die als Gegenleistung dein Projekt widerspiegeln , z.B.{' '}
              <br />
              Tickets <br />
              Dein neues Buch <br />
              Ein Mushaf <br />
              Liebhaberstücke <br />
              Seminare oder Workshops <br />
              Persönliche Treffen <br />
              Online- oder Wohnzimmerkonzerte <br />
            </p>
            <p>
              Aktionen , z.B. <br />
              Patenschaften <br />
              Nennung im Abspann , im Buch oder Webseiten <br />
              Liveauftritt auf deinem Instagramkanal
            </p>
          </Collapse>
          <Collapse
            title={<h2>17. Wie lege ich ein Goody an? </h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator17' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator17'}
          >
            <p>
              Im Projektformular legst du Goodies für dein Projekt an. Goodies
              können mit einem Bild, Text und weiteren Details beschrieben
              werden. Mit einem aussagekräftigen Bild und einer klaren
              Beschreibung motivierst du die Community das Goody zu buchen.
            </p>
          </Collapse>
          {/* <Collapse
            title={<h2>18. Zahlungen über PayPal </h2>}
            arrowWidth={'20px'}
            className="faq__collapse"
            defaultState={currentOpen === 'projekrinitiator18' ? true : false}
            handleMultiCollapes={setCurrentOpen}
            witchQuestions={'projekrinitiator18'}
          >
            <p>
              Bei commonsplace kannst du deine Projekte mit PayPal-Unterstützung finanzieren. Im Projektformular musst du angeben, ob du ein PayPal-Konto hast. Falls du kein PayPal-Konto hast, kannst du immer noch Gelder über andere Zahlungsdienstleister empfangen.
            </p>
            <p>
              Wenn du jedoch ein PayPal-Konto hast, musst du uns eine korrekte PayPal-Adresse angeben, damit wir die Unterstützungen an dich auszahlen können. Falls du uns keine korrekten Informationen gibst, halten wir das Geld ein, bis du uns die korrekten Angaben gibst, damit wir es an dich auszahlen können.
            </p>
          </Collapse> */}
        </div>
      </ProjektinitiatorFAQsWrapper>
    );
  }
}

ProjektinitiatorFAQs.propTypes = {
  currentOpen: PropTypes.string,
  setCurrentOpen: PropTypes.func.isRequired,
};
