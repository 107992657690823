import { Col, Form, Input, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { useState } from 'react';
import { Container } from 'utils';
import config from 'config';
import { AlertActions } from 'redux/actions';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Button, Modal } from 'antd';

const API_URL = config.REACT_APP_API_URL;
const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const dispatch = useDispatch();
  const [modalTooltip, setModalTooltip] = useState(false);

  const handleSubmit = async (e) => {
    // API endpoint URL
    const apiUrl = `${API_URL}/brevo`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, phone: phone }),
      });
      // Check if the request was successful (status code 2xx)
      if (response.brevo.id != null) {
        // console.log('created');
        return dispatch(AlertActions.success('Vom Benutzer erstellt.'));
        // alert('Successfully subscribed to the newsletter!');
      } else {
        // console.log('Already created');
        return dispatch(
          AlertActions.error('Der Benutzer wurde bereits erstellt.')
        );
        // alert('Failed to subscribe to the newsletter. Please try again later.');
      }
    } catch (error) {
      console.error('Error sending the request:', error);
      console.log('error', error);
      // alert('An error occurred. Please try again later.');
      return dispatch(
        AlertActions.error(
          'Die E-Mail Adresse oder Telefonnummer wird bereits verwendet oder ist im falschen Format!'
        )
      );
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const validateEmail = (rule, value, callback) => {
    if (!value) {
      // If the field is empty, trigger the error callback with the custom message
      callback('Deine E-Mail ist notwendig');
    } else {
      // If the field has a value, call the callback with no arguments to indicate success
      callback();
    }
  };
  const Title = styled.div`
  .newsLettertitle {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 0.9;
    letter-spacing: -0.03em;
    transform: translateX(-0.07em);
    margin-right: 70px;
  }
  .paragraph-inline p{
    font-size:16px
  }
 
  @media (max-width: 830px) {
    .paragraph-inline p{
      font-size:14px
    }
  .newsLettertitle {
    /* Adjust the styles for mobile screens */
    font-size: 20px; /* Example: Half the original font size, you can change this value as per your requirement */
    line-height: 1.2; /* Example: A different line height to improve readability */
    margin-right: 0; /* Remove unnecessary margin on mobile */
    transform: none; /* Remove any transform on mobile */
    margin-right: 70px;
  }
  @media (max-width: 730px) {
    .paragraph-inline p{
      font-size:15px
    }
  }
  @media (max-width: 400px) {
    .paragraph-inline p{
      font-size:13px
    }
  }
`;

  return (
    <div
      style={{
        marginTop: '1000',
        backgroundColor: '#95bd51',
        width: '100%',
        padding: '70px 0',
      }}
    >
      <Container>
        <Row gutter={16}>
          <Col sm={12}>
            <Title>
              <h2 className="newsLettertitle">
                E-Mail und WhatsApp-Newsletter
              </h2>
            </Title>
            <h3
              style={{
                marginTop: '10px',
                fontSize: '18px',
                lineHeight: '1.22222222',
                letterSpacing: 'normal',
              }}
            >
              <p style={{ fontWeight: '700', marginRight: '70px' }}>
                Erhalte aktuelle News über commonsplace bequem per Newsletter -
                melde dich jetzt an!
              </p>
            </h3>
          </Col>
          <Col sm={12} style={{ width: '100%' }}>
            <Title>
              <div className="paragraph-inline">
                <p style={{ margin: '0px' }}>
                  Spannende deutsch-muslimische Projekte und Ideen.
                </p>{' '}
                <p style={{ margin: '0px' }}>
                  Inspirierende Geschichten von vielfältigen Menschen.
                </p>{' '}
                <p style={{ margin: '0px', marginBottom: '10px' }}>
                  Aktuelle Crowdfunding-News auf commonsplace.
                </p>
              </div>
            </Title>
            <div className="newsletter-form">
              <Form name="newsletter" onFinish={handleSubmit}>
                <FormItem
                  name="email"
                  rules={[
                    {
                      validator: validateEmail,
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Deine E-Mail-Adresse"
                    value={email}
                    onChange={handleEmailChange}
                    style={{
                      maxWidth: '500px',
                      width: '100%',
                      height: '35px',
                      border: '1px solid transparent',
                      borderRadius: '6px',
                    }}
                  />
                </FormItem>

                <FormItem
                  name="phone"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <Input
                    type="phone"
                    placeholder="Deine WhatsApp-Nummer (in +49 Format)"
                    value={phone}
                    onChange={handlePhoneChange}
                    style={{
                      maxWidth: '500px',
                      width: '100%',
                      height: '35px',
                      border: '1px solid transparent',
                      borderRadius: '6px',
                    }}
                  />
                </FormItem>
                <div>
                  <p
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      gap: 5,
                      cursor: 'pointer',
                    }}
                    onClick={() => setModalTooltip(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      style={{ width: 18 }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Datenschutzhinweis
                  </p>
                </div>

                <button
                  type="submit"
                  style={{
                    borderTopLeftRadius: '0',
                    borderBottomLeftRadius: '0',
                    height: '42px',
                    backgroundColor: '#304b50',
                    borderColor: '#304b50',
                    color: '#fff',
                    border: '1px solid transparent',
                    borderRadius: '6px',
                  }}
                >
                  Eintragen
                </button>
                <div>
                  <p
                    style={{
                      fontSize: '14px',
                      display: 'inline-flex',
                      gap: 5,
                      marginTop: 5,
                    }}
                  >
                    <div style={{ letterSpacing: -0.4 }}>
                      Und trete auch jetzt unserem{' '}
                      <a
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        href="https://whatsapp.com/channel/0029VaMMAq50QealT9ayL51r"
                        target="_blank"
                        rel="noreferrer"
                        alt="linkedin"
                      >
                        WhatsApp-Kanal
                      </a>{' '}
                      bei.
                    </div>
                  </p>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        title=""
        centered
        visible={modalTooltip}
        footer={null}
        onCancel={() => setModalTooltip(false)}
      >
        <p
          style={{
            marginTop: 25,
            letterSpacing: '0.03em',
            fontSize: '15px',
            lineHeight: 1.45,
            color: '#304b50',
            fontWeight: 400,
          }}
        >
          Es werden ggf. personenbezogene Daten an den Anbieter Sendinblue GmbH
          übermittelt und Cookies gesetzt.
        </p>
      </Modal>
    </div>
  );
};

export default NewsLetter;
