import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WelcomeIcon } from 'images';
import { authActions } from 'redux/actions';
import { SvgWrapper, Container, H1, Colors, isMobile } from 'utils';
import { Header, Footer } from 'components';
import styled from '@emotion/styled';
import ContactModal from 'scenes/Project/components/ContactModal';

const WelcomeWrapper = styled.div`
  text-align: center;
  margin-bottom: 100px;
  h1 {
    font-weight: bold;
    color: ${Colors.GreenColor};
    & + p {
      font-size: 22px;
      color: ${Colors.GreenColor};
    }
  }
  .become-a-member {
    background: ${Colors.secondaryColor};
    border: 1px solid ${Colors.secondaryColor};
    color: ${Colors.white};
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 20px;
    padding: 15px 20px;
    min-width: 200px;
    font-size: 18px;
    display: inline-block;
    &:hover {
      background: ${Colors.transparent};
      color: ${Colors.secondaryColor};
    }
  }
`;

export class WelcomePage extends Component {
  componentDidMount() {
    const { resetWelcome } = this.props;
    resetWelcome();
  }
  static propTypes = {
    resetWelcome: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div>
        <ContactModal />
        <Header relativeHeader={true} />
        <Container>
          <WelcomeWrapper>
            <div>
              <SvgWrapper width={isMobile() ? '100%' : '500px'} height={'auto'}>
                <WelcomeIcon />
              </SvgWrapper>
            </div>

            <H1>Vielen Dank für deine Anmeldung!</H1>
            <p>
              In wenigen Augenblicken solltest du eine Bestätigungsmail
              erhalten. Bitte bestätige deine E-Mail, um die Anmeldung
              abzuschließen
            </p>
            <p>
              Falls die Bestätigungsmail nicht innerhalb der nächsten 5 – 10
              Minuten bei dir ankommt, überprüfe bitte deinen Spam-Ordner.
            </p>
            <p>Dein commonsplace-Team</p>
          </WelcomeWrapper>
        </Container>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  resetWelcome: () => dispatch(authActions.resetWelcome()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
