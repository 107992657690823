import React, { Component } from 'react';
import { Form, Input, Checkbox, Spin } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'loadsh/isEmpty';
import {
  Container,
  PrimaryButton,
  formLayout,
  formValidateMessages,
  H1,
  H2,
  FormWrapper,
} from 'utils';
import { ProjectsActions } from 'redux/actions';

const FormItem = Form.Item;
class FundingGoal extends Component {
  static propTypes = {
    updateDraft: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    projectId: PropTypes.string,
    currentStep: PropTypes.number.isRequired,
    setCanMove: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    selectedStep: PropTypes.number.isRequired,
    setSubmitFromModal: PropTypes.func.isRequired,
  };
  onFinish = (values) => {
    const {
      updateDraft,
      projectId,
      currentStep,
      setCanMove,
      showModal,
      selectedStep,
      setSubmitFromModal,
    } = this.props;
    if (showModal) {
      updateDraft(projectId, values, selectedStep - 1);
      setSubmitFromModal(false);
    } else {
      updateDraft(projectId, values, currentStep);
    }
    setCanMove(true);
    this.props.handleSteps(5);
  };
  render() {
    const { project, loading, setCanMove, projectId } = this.props;

    return (
      <Container className="inside-form-container">
        <Spin spinning={loading}>
          <H1>Fundingziel</H1>
          <p>
            Das Fundingziel ist der Geldbetrag, welcher für die Realisierung des
            Projektes benötigt wird. Sollte das Fundingziel nicht erreicht
            werden, erhalten Projektinitiatoren von sozialen Projekten das Geld
            trotzdem und müssen angeben, was mit den Unterstützungen passiert.
            Projekte unternehmerischer Natur erhalten das Geld nur bei Erreichen
            des Fundingziels.
          </p>
          {!loading && (
            <Form
              {...formLayout}
              name="project-details"
              onFinish={this.onFinish}
              validateMessages={formValidateMessages}
              onChange={() => setCanMove(false)}
              initialValues={
                projectId
                  ? project
                    ? {
                        ...project.fundingTarget,
                      }
                    : {}
                  : ''
              }
            >
              <div>
                <H2>Fundingziel</H2>
                <FormWrapper>
                  <>
                    <FormItem
                      name="final_goal"
                      label="Fundingziel"
                      extra="Mindestens 100 Euro"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        min={100}
                        addonAfter="EUR"
                        step=".01"
                      />
                    </FormItem>
                    <FormItem
                      name="goal"
                      label="Das erste Etappenziel"
                      extra="Das erste Etappenziel zeigt den Mindestbetrag, den du für dein Projekt benötigst. Dein Fundingziel liegt beispielsweise bei 4.000 Euro, und dein erstes Etappenziel ist 1.300 Euro. Sobald du die 1.300 Euro erreichst, erhöht sich das nächste Etappenziel automatisch basierend auf der Fibonacci-Folge auf 2.100 Euro und so weiter, bis du das Fundingziel erreichst. So kannst du dein Netzwerk schrittweise motivieren, und das Ziel wirkt für deine Unterstützer erreichbar und nicht überfordernd."
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              Number(value) >
                              Number(getFieldValue(['final_goal']))
                            ) {
                              return Promise.reject(
                                'Das nächste Etappenziel muss kleiner als das Fundingziel sein.'
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input
                        type="number"
                        min={100}
                        addonAfter="EUR"
                        step=".01"
                      />
                    </FormItem>
                    <FormItem
                      name="goal_explanation"
                      label="Begründung der Summe"
                      rules={[
                        {
                          required: true,
                          message: 'Begründung ist erforderlich.',
                        },
                      ]}
                    >
                      <Input.TextArea showCount maxLength={500} />
                    </FormItem>
                  </>
                </FormWrapper>
              </div>

              <div>
                <H2>Nachweise</H2>
                <FormWrapper>
                  <>
                    <FormItem
                      name="bill_possible"
                      extra="Deine Unterstützer können Rechnungen von dir erhalten."
                      valuePropName="checked"
                    >
                      <Checkbox>Ich kann Rechnungen ausstellen</Checkbox>
                    </FormItem>
                    <FormItem
                      name="donation_receipts_possible"
                      extra="Wenn deine Organisation gemeinnützig ist, können Unterstützer Spendenquittungen von dir erhalten. Die Ausstellung der Spendenquittung liegt in deiner Verantwortung."
                      valuePropName="checked"
                    >
                      <Checkbox>Ich kann Spendenquittungen ausstellen</Checkbox>
                    </FormItem>
                  </>
                </FormWrapper>
              </div>
              <FormItem>
                <PrimaryButton
                  type="primary"
                  htmltype="submit"
                  minWidth="150px"
                  id="submit-button"
                >
                  Speichern
                </PrimaryButton>
              </FormItem>
            </Form>
          )}
        </Spin>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { project, loading, errors, currentStep } = state.projects;
  return {
    project,
    loading,
    errors,
    currentStep,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleSteps: (step) => dispatch(ProjectsActions.handleSteps(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FundingGoal);
