import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Colors, device, isMobile, SvgWrapper } from 'utils';
import { ProjectsActions } from 'redux/actions';
import { Spin } from 'antd';
import { SadFace, Declined, AcceptIcon } from 'images';
const ProjectsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 35px;
  .project {
    width: 32%;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
    @media ${device.minLaptop} {
      width: 48%;
      margin: auto;
      margin-bottom: 20px;
      margin-top: 0;
    }
    @media ${device.allMobile} {
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
    &:nth-of-type(3n + 2) {
      margin: 0 15px 20px;
      @media ${device.allMobile} {
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
      }
      @media ${device.tablet} {
        margin: 0 14px 20px;
      }
      @media ${device.minLaptop} {
        margin: auto;
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
`;

const YourProjectsWrapper = styled.div`
  margin-bottom: 35px;
  .link {
    text-align: center;
    a {
      background: ${Colors.secondaryColor};
      border: 1px solid ${Colors.secondaryColor};
      color: ${Colors.white};
      text-align: center;
      border-radius: 12px;
      cursor: pointer;
      margin-bottom: 20px;
      padding: 15px 20px;
      min-width: 200px;
      font-size: 18px;
      &:hover {
        background: ${Colors.transparent};
        color: ${Colors.secondaryColor};
      }
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
`;

const Project = styled.div`
  border: 1px solid ${Colors.grey};
  border-radius: 10px;
  .image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    span {
      border-radius: 10px 10px 0 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      background: ${Colors.steel};
    }
  }
  .details {
    padding: 10px 15px;
    h3 {
      font-weight: bold;
      font-size: 22px;
      margin: 15px auto;
    }
    p {
      font-size: 16px;
      max-width: 80%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  a:hover,
  a:visited {
    color: inherit;
  }
  .card {
    &__img-wrapper {
      position: relative;
      max-height: 200px;
      border-radius: 10px 10px 0 0;

      & > span,
      img {
        width: 100%;
        height: 200px;
        position: relative;
        display: inline-block;
        background: ${Colors.grey};
        border-radius: 10px 10px 0 0;
        object-fit: cover;
        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
      .declined-icon {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0.7;
        background: ${Colors.grey};
        svg {
          position: absolute;
          top: 41%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        p {
          position: absolute;
          bottom: 10px;
          color: ${Colors.red};
          left: 50%;
          transform: translate(-50%, 0);
          margin: 0;
          font-weight: bold;
        }
        &.successful {
          p {
            color: ${Colors.secondaryColor};
          }
        }
      }
    }
    &__category {
      color: ${Colors.white};
      text-transform: uppercase;
      font-size: 12px;
      background-color: rgba(42, 42, 42, 0.8);
      padding: 5px;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      opacity: 0.5;
    }
    &__form {
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      display: flex;
      align-items: center;
      color: ${Colors.black};
      text-transform: uppercase;
      font-size: 12px;
      background: ${Colors.white};
      padding: 5px;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
      opacity: 0.5;
    }
    &__content {
      padding: 10px 20px;
      footer {
        display: flex;
        justify-content: space-between;
        p {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 1;
          margin-top: 5px;
          span {
            margin-right: 5px;
          }
        }
      }
    }
    &__name {
      margin: 0;
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    &__description {
      font-size: 16px;
      margin: 0;
      margin-bottom: 50px;
      width: 100%;
      overflow-x: hidden;
    }
  }
`;
const SadFaceWrapper = styled.div`
  text-align: center;
  margin: 25px auto;
`;
class YourProjects extends Component {
  componentDidMount() {
    const { getMyProjects } = this.props;
    getMyProjects();
  }
  static propTypes = {
    getMyProjects: PropTypes.func.isRequired,
    myProjects: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
  };

  render() {
    const { myProjects, loading } = this.props;
    // console.log('myProjects');
    // console.log(myProjects);
    return (
      <YourProjectsWrapper>
        <h2 id="started-projects">Deine gestarteten Projekte:</h2>
        <Spin spinning={loading}>
          <>
            {myProjects.length > 0 ? (
              <ProjectsWrapper>
                {myProjects.map((project, indx) => (
                  <Project
                    className="project"
                    key={`${JSON.stringify(project)}-${indx}`}
                  >
                    <Link
                      to={
                        project.status === 'draft'
                          ? `/start-project/${project.id}`
                          : project.status === 'totally_declined'
                          ? `#!`
                          : `/my-project/${project.id}`
                      }
                    >
                      <div className="card__img-wrapper">
                        {project.status === 'totally_declined' && (
                          <>
                            <span className="declined-icon">
                              <SvgWrapper width={'100%'} height={'100%'}>
                                <Declined width={'70%'} height={'70%'} />
                              </SvgWrapper>
                              <p>Abgelehnt</p>
                            </span>
                          </>
                        )}
                        {project.status === 'successful' && (
                          <>
                            <span className="declined-icon successful">
                              <SvgWrapper width={'100%'} height={'100%'}>
                                <AcceptIcon width={'70%'} height={'70%'} />
                              </SvgWrapper>
                              <p>Erfolgreich</p>
                            </span>
                          </>
                        )}
                        {
                          <picture>
                            <img src={project.photo} alt={project.name} />
                          </picture>
                          // isMobile() ? (
                          //   <>
                          //     {project.mobile_photo ? (
                          //       <picture>
                          //         <img src={project.mobile_photo} alt={project.name} />
                          //       </picture>
                          //     ) : project.photo ? (
                          //       <picture>
                          //         <img src={project.photo} alt={project.name} />
                          //       </picture>
                          //     ) : (
                          //       <span>Projektbild</span>
                          //     )}
                          //   </>
                          // ) : (
                          //   <>
                          //     {project.photo ? (
                          //       <picture>
                          //         <img src={project.photo} alt={project.name} />
                          //       </picture>
                          //     ) : project.mobile_photo ? (
                          //       <picture>
                          //         <img src={project.mobile_photo} alt={project.name} />
                          //       </picture>
                          //     ) : (
                          //       <span>Projektbild</span>
                          //     )}
                          //   </>
                          // )
                        }

                        {project.category && (
                          <p className="card__category">{project.category}</p>
                        )}
                      </div>
                      <div className="card__content">
                        <h2 className="card__name">{project.name}</h2>
                        <p className="card__description">
                          {project.description}
                        </p>
                      </div>
                    </Link>
                  </Project>
                ))}
              </ProjectsWrapper>
            ) : (
              <SadFaceWrapper>
                <SvgWrapper width={'150px'} height={'auto'}>
                  <SadFace />
                </SvgWrapper>
              </SadFaceWrapper>
            )}
          </>
        </Spin>
        <div className="link">
          {/* <Link to="/start-project">Jetzt neues Projekt starten</Link> */}
        </div>
      </YourProjectsWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { myProjects, loading, errors } = state.projects;
  return {
    myProjects,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyProjects: () => dispatch(ProjectsActions.getMyProjects()),
});

export default connect(mapStateToProps, mapDispatchToProps)(YourProjects);
