import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header } from 'components';
import { projectsServices } from 'services';
import { isTablet, Container, isMobile, PrimaryButton, device } from 'utils';
import {
  LeftSideBar,
  RightSideBar,
  MyProjects,
  MySupports,
  ResetEmail,
  ResetPassword,
  UserInfo,
  Payment,
  MobileRightSideBar,
  YourRewards,
} from './components';
import styled from '@emotion/styled';
import ContactModal from 'scenes/Project/components/ContactModal';

const ProfilePageWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  position: relative;
  .profile-page__content {
    max-width: 1050px;
    padding-top: 50px;
    padding: 50px 20px;
    @media ${device.minLaptop} {
      max-width: 550px;
      margin: 0;
    }
  }
`;
const ShowMenuWrapper = styled.div`
  text-align: center;
  margin: 25px 0 0;
`;

export class ProfilePage extends Component {
  static propTypes = {
    supportedProjects: PropTypes.array,
    myProjects: PropTypes.array,
    rewardsCount: PropTypes.number,
  };
  constructor(props) {
    super(props);
    this.state = {
      currentView: 'profile',
      openMenu: false,
      rewardsCount: 0,
    };
  }

  async componentDidMount() {
    const rewards = await projectsServices.getUserRewards(1, 1);
    this.setState({ rewardsCount: rewards.count });
  }

  setCurrentView = (currentView) => {
    this.setState({ currentView, openMenu: false });
    document.body.style.overflowY = 'unset';
  };
  handleOpenMenu = () => {
    this.setState({ openMenu: true });
    document.body.style.overflowY = 'hidden';
  };

  handleCloseMenu = () => {
    this.setState({ openMenu: false });
    document.body.style.overflowY = 'unset';
  };
  render() {
    const { currentView, openMenu } = this.state;
    const { supportedProjects, myProjects } = this.props;
    const handelView = () => {
      switch (currentView) {
        case 'profile':
        case 'projects':
          return <MyProjects />;
        case 'resetPassword':
          return <ResetPassword />;
        case 'userInfo':
          return <UserInfo />;
        case 'resetEmail':
          return <ResetEmail />;
        case 'suppers':
          return <MySupports />;
        case 'payment':
          return <Payment />;
        case 'rewards':
          return <YourRewards />;
        default:
          break;
      }
    };
    return (
      <div>
        <Header relativeHeader={true} mainProfile={true} />
        <ContactModal />
        {(isMobile() || isTablet()) && (
          <ShowMenuWrapper>
            <PrimaryButton
              onClick={() => this.handleOpenMenu()}
              minWidth={'90%'}
            >
              Menü anzeigen
            </PrimaryButton>
          </ShowMenuWrapper>
        )}
        <ProfilePageWrapper>
          {!isMobile() && !isTablet() && (
            <LeftSideBar
              supportedLength={supportedProjects.length}
              myProjectsLength={myProjects.length}
              rewardsLength={this.state.rewardsCount}
              setCurrentView={this.setCurrentView}
              currentView={currentView}
            />
          )}

          <Container className="profile-page__content">
            {handelView()}
          </Container>
          {!isMobile() && !isTablet() && (
            <RightSideBar
              setCurrentView={this.setCurrentView}
              currentView={currentView}
            />
          )}
          {(isMobile() || isTablet()) && openMenu && (
            <MobileRightSideBar
              setCurrentView={this.setCurrentView}
              currentView={currentView}
              handleCloseMenu={this.handleCloseMenu}
            />
          )}
        </ProfilePageWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { supportedProjects, myProjects } = state.projects;
  return {
    supportedProjects,
    myProjects,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
