import React, { Component } from 'react';
import { Layout } from 'components';
import { Container } from 'utils';
import ContactModal from 'scenes/Project/components/ContactModal';

export default class Datenschutz extends Component {
  render() {
    return (
      <Layout>
        <ContactModal />
        <Container>
          <div style={{ marginTop: '100px', marginBottom: '100px' }}>
            <h1 style={{ fontSize: '40px' }}>Datenschutz­erklärung</h1>
            <h2>1. Datenschutz auf einen Blick</h2>
            <h3>Allgemeine Hinweise</h3>
            <p>
              Die folgenden Hinweise geben einen einfachen Überblick darüber,
              was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
              Website besuchen. Personenbezogene Daten sind alle Daten, mit
              denen Sie persönlich identifiziert werden können. Ausführliche
              Informationen zum Thema Datenschutz entnehmen Sie unserer unter
              diesem Text aufgeführten Datenschutzerklärung.
            </p>
            <h3>Datenerfassung auf dieser Website</h3>
            <h4>
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </h4>
            <p>
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
              „Hinweis zur Verantwortlichen Stelle“ in dieser
              Datenschutzerklärung entnehmen.
            </p>
            <h4>Wie erfassen wir Ihre Daten?</h4>
            <p>
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
              ein Kontaktformular eingeben.
            </p>
            <p>
              Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
              Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
              allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
              Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
              automatisch, sobald Sie diese Website betreten.
            </p>
            <h4>Wofür nutzen wir Ihre Daten?</h4>
            <p>
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gewährleisten. Andere Daten können
              zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>
            <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
            <p>
              Sie haben jederzeit das Recht, unentgeltlich Auskunft über
              Herkunft, Empfänger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht,
              die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie
              eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie
              diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
              haben Sie das Recht, unter bestimmten Umständen die Einschränkung
              der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
              Aufsichtsbehörde zu.
            </p>
            <p>
              Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
              sich jederzeit an uns wenden.
            </p>
            <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>
            <p>
              Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
              ausgewertet werden. Das geschieht vor allem mit sogenannten
              Analyseprogrammen.
            </p>
            <p>
              Detaillierte Informationen zu diesen Analyseprogrammen finden Sie
              in der folgenden Datenschutzerklärung.
            </p>
            <h2>
              2. Datenübermittlung an deinerseits unterstützte Projektinitatoren
            </h2>
            <p>
              Soweit du bei uns personenbezogene Daten hinterlegst, sind wir
              berechtigt, die jeweils erforderlichen Daten denjenigen
              Unterstützer, deren Projekte du finanziell unterstützt zur
              Verfügung zu stellen, damit die versprochenen Gegenleistungen dir
              gegenüber erbracht werden können. Dies betrifft im Zweifel deinen
              Vor- und Nachnamen, deine Adressdaten, deine Unterstützungshöhe,
              deine E-Mail-Adresse, das ausgewählte Goodie/ die ausgewählte
              Belohnung inkl. evtl. ausgewählter besonderer Eigenschaften (z.B.
              Kleidergröße/Farbe) und das Datum der Unterstützung.
              Rechtsgrundlage dieser Datenübermittlung ist Artikel 6 Abs. 1 lit.
              b) DS-GVO
            </p>
            <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
            <h3>Datenschutz</h3>
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </p>
            <p>
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
              mit denen Sie persönlich identifiziert werden können. Die
              vorliegende Datenschutzerklärung erläutert, welche Daten wir
              erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
              welchem Zweck das geschieht.
            </p>
            <p>
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
              B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </p>
            <h3>Hinweis zur verantwortlichen Stelle</h3>
            <p>
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser
              Website ist:
            </p>
            <p>
              commonsplace UG (haftungsbeschränkt)
              <br />
              Kleinmarschierstraße 63
              <br />
              52062 Aachen
            </p>
            <p>
              Telefon: 017661685206
              <br />
              E-Mail: info(at)commonsplace.de
            </p>
            <p>
              Verantwortliche Stelle ist die natürliche oder juristische Person,
              die allein oder gemeinsam mit anderen über die Zwecke und Mittel
              der Verarbeitung von personenbezogenen Daten (z. B. Namen,
              E-Mail-Adressen o. Ä.) entscheidet.
            </p>
            <h3>Speicherdauer</h3>
            <p>
              Soweit innerhalb dieser Datenschutzerklärung keine speziellere
              Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen
              Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt.
              Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine
              Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
              gelöscht, sofern wir keinen anderen rechtlich zulässigen Gründe
              für die Speicherung Ihrer personenbezogenen Daten haben (z.B.
              steuer- oder handelsrechtliche Aufbewahrungsfristen); im
              letztgenannten Fall erfolgt die Löschung nach Fortfall dieser
              Gründe.
            </p>
            <h3>Hinweis zur Datenweitergabe in die USA</h3>
            <p>
              Auf unserer Website sind unter anderem Tools von Unternehmen mit
              Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, können
              Ihre personenbezogenen Daten an die US-Server der jeweiligen
              Unternehmen weitergegeben werden. Wir weisen darauf hin, dass die
              USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts
              sind. US-Unternehmen sind dazu verpflichtet, personenbezogene
              Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als
              Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher
              nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste)
              Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken
              verarbeiten, auswerten und dauerhaft speichern. Wir haben auf
              diese Verarbeitungstätigkeiten keinen Einfluss.
            </p>
            <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
            <p>
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
              Einwilligung möglich. Sie können eine bereits erteilte
              Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </p>
            <h3>
              Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
              sowie gegen Direktwerbung (Art. 21 DSGVO)
            </h3>
            <p>
              WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
              ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
              DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
              VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
              DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
              PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
              VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG.
              WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
              PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR
              KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
              NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
              ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
              VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
              DSGVO).
            </p>
            <p>
              WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG
              ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
              DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
              ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS
              PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
              STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
              ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
              (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            </p>
            <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>
            <p>
              Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
              Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
              Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
              oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
              besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.
            </p>
            <h3>Recht auf Daten­übertrag­barkeit</h3>
            <p>
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem gängigen,
              maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
              direkte Übertragung der Daten an einen anderen Verantwortlichen
              verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </p>
            <h3>SSL- bzw. TLS-Verschlüsselung</h3>
            <p>
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
              oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
              SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
              erkennen Sie daran, dass die Adresszeile des Browsers von
              „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
              Ihrer Browserzeile.
            </p>
            <p>
              Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
              Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
              werden.
            </p>
            <h3>Verschlüsselter Zahlungsverkehr auf dieser Website</h3>
            <p>
              Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine
              Verpflichtung, uns Ihre Zahlungsdaten (z. B. Kontonummer bei
              Einzugsermächtigung) zu übermitteln, werden diese Daten zur
              Zahlungsabwicklung benötigt.
            </p>
            <p>
              Der Zahlungsverkehr über die gängigen Zahlungsmittel
              (Visa/MasterCard, Lastschriftverfahren) erfolgt ausschließlich
              über eine verschlüsselte SSL- bzw. TLS-Verbindung. Eine
              verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile
              des Browsers von „http://“ auf „https://“ wechselt und an dem
              Schloss-Symbol in Ihrer Browserzeile.
            </p>
            <p>
              Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die
              Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
            </p>
            <h3>Auskunft, Löschung und Berichtigung</h3>
            <p>
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
              weiteren Fragen zum Thema personenbezogene Daten können Sie sich
              jederzeit an uns wenden.
            </p>
            <h3>Recht auf Einschränkung der Verarbeitung</h3>
            <p>
              Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu können Sie sich
              jederzeit an uns wenden. Das Recht auf Einschränkung der
              Verarbeitung besteht in folgenden Fällen:
            </p>
            <ul>
              <li>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, benötigen wir in der Regel
                Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                das Recht, die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                geschah/geschieht, können Sie statt der Löschung die
                Einschränkung der Datenverarbeitung verlangen.
              </li>
              <li>
                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
                sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                Löschung die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                vorgenommen werden. Solange noch nicht feststeht, wessen
                Interessen überwiegen, haben Sie das Recht, die Einschränkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>
            </ul>
            <p>
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
              abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
              der Rechte einer anderen natürlichen oder juristischen Person oder
              aus Gründen eines wichtigen öffentlichen Interesses der
              Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
            </p>
            <h3>Widerspruch gegen Werbe-E-Mails</h3>
            <p>
              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
              Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
              Werbung und Informationsmaterialien wird hiermit widersprochen.
              Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
              Schritte im Falle der unverlangten Zusendung von
              Werbeinformationen, etwa durch Spam-E-Mails, vor.
            </p>
            <h2>3. Datenerfassung auf dieser Website</h2>
            <h3>Cookies</h3>
            <p>
              Unsere Internetseiten verwenden so genannte „Cookies“. Cookies
              sind kleine Textdateien und richten auf Ihrem Endgerät keinen
              Schaden an. Sie werden entweder vorübergehend für die Dauer einer
              Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf
              Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres
              Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
              Endgerät gespeichert, bis Sie diese selbst löschen oder eine
              automatische Löschung durch Ihren Webbrowser erfolgt.
            </p>
            <p>
              Teilweise können auch Cookies von Drittunternehmen auf Ihrem
              Endgerät gespeichert werden, wenn Sie unsere Seite betreten
              (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die
              Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
              Cookies zur Abwicklung von Zahlungsdienstleistungen).
            </p>
            <p>
              Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
              technisch notwendig, da bestimmte Websitefunktionen ohne diese
              nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
              Anzeige von Videos). Andere Cookies dienen dazu, das
              Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
            </p>
            <p>
              Cookies, die zur Durchführung des elektronischen
              Kommunikationsvorgangs (notwendige Cookies) oder zur
              Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
              (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur
              Optimierung der Website (z.B. Cookies zur Messung des
              Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
              Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
              berechtigtes Interesse an der Speicherung von Cookies zur
              technisch fehlerfreien und optimierten Bereitstellung seiner
              Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
              abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies
              ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1
              lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen
              von Cookies informiert werden und Cookies nur im Einzelfall
              erlauben, die Annahme von Cookies für bestimmte Fälle oder
              generell ausschließen sowie das automatische Löschen der Cookies
              beim Schließen des Browsers aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </p>
            <p>
              Soweit Cookies von Drittunternehmen oder zu Analysezwecken
              eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
              Datenschutzerklärung gesondert informieren und ggf. eine
              Einwilligung abfragen.
            </p>
            <h3>Server-Log-Dateien</h3>
            <p>
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log-Dateien, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
            </p>
            <ul>
              <li>Browsertyp und Browserversion</li>
              <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>
              <li>Hostname des zugreifenden Rechners</li>
              <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>
            </ul>
            <p>
              Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
              nicht vorgenommen.
            </p>
            <p>
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website – hierzu müssen die Server-Log-Files erfasst
              werden.
            </p>
            <h3>Kontaktformular</h3>
            <p>
              Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
              Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
              angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
              den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde.
            </p>
            <p>
              Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
              uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
              Speicherung widerrufen oder der Zweck für die Datenspeicherung
              entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
              Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </p>
            <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
            <p>
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde.
            </p>
            <p>
              Die von Ihnen an uns per Kontaktanfragen übersandten Daten
              verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck für die
              Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
              Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
              insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
            </p>
            <h3>SendinBlue</h3>
            <p>
              Für die Kommunikation mit unseren Nutzern mittels eines
              Newsletters verwenden wir die Dienste von Sendinblue. Sendinblue
              ist ein deutsches Unternehmen (Sendinblue GmbH, Köpenicker Str.
              126, 10179 Berlin), welches die Kommunikation mit unserer
              Community via Newsletter E-Mails vereinfacht. Hierbei wird
              lediglich die E-Mail Adresse bei Sendinblue gespeichert. Die
              Newsletter Anmeldung ist optional und wird von jedem User
              freiwillig bestimmt und kann nachträglich wieder entfernt werden.
              Die E-Mail Daten werden auf einem deutschen Server der Sendinblue
              gespeichert. Gleichzeitig wird durch die Newsletter-Anmeldung
              eingewilligt, dass wir Dich via E-Mail kontaktieren dürfen. Die
              Speicherung und der Versand gilt nur solange, bis Du dich aktiv
              wieder abmeldest und damit die Löschung deiner E-Mail Adresse aus
              der Sendinblue Datenbank vornimmst. Diese Möglichkeit wird dir bei
              jeder erhaltenen E-Mail gegeben.
            </p>
            <p>
              Das Versenden unseres Newsletters durch Sendinblue erfolgt auf
              Grundlage Deiner Einwilligung (Artikel 6 Abs. 1 lit.a DSGVO). Das
              heißt, wir dürfen Dir nur dann einen Newsletter schicken, wenn Du
              dich zuvor aktiv dafür angemeldet hast. Wenn eine Einwilligung
              nicht nötig ist, dann erfolgt der Newsletter-Versand auf Grundlage
              des berechtigten Interesses am Direktmarketing (Artikel 6 Abs. 1
              lit.f), sofern dies rechtlich erlaubt ist. Ihren
              Registrierungsprozess zeichnen wir auf, damit wir stets nachweisen
              können, dass dieser unseren Gesetzen entspricht.
            </p>
            {/* <h3>Kommunikation via WhatsApp</h3>
            <p>
              Für die Kommunikation mit unseren Kunden und sonstigen Dritten
              nutzen wir unter anderem den Instant-Messaging-Dienst WhatsApp.
              Anbieter ist die WhatsApp Ireland Limited, 4 Grand Canal Square,
              Grand Canal Harbour, Dublin 2, Irland.
            </p>
            <p>
              Die Kommunikation erfolgt über eine Ende-zu-Ende-Verschlüsselung
              (Peer-to-Peer), die verhindert, dass WhatsApp oder sonstige Dritte
              Zugriff auf die Kommunikationsinhalte erlangen können. WhatsApp
              erhält jedoch Zugriff auf Metadaten, die im Zuge des
              Kommunikationsvorgangs entstehen (z.B. Absender, Empfänger und
              Zeitpunkt). Wir weisen ferner darauf hin, dass WhatsApp nach
              eigener Aussage, personenbezogene Daten seiner Nutzer mit seiner
              in den USA ansässigen Konzernmutter Facebook teilt. Weitere
              Details zur Datenverarbeitung finden Sie in der
              Datenschutzrichtlinie von WhatsApp unter:
              <a
                href="https://www.whatsapp.com/legal/#privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.whatsapp.com/legal/#privacy-policy
              </a>
              .
            </p>
            <p>
              Der Einsatz von WhatsApp erfolgt auf Grundlage unseres
              berechtigten Interesses an einer möglichst schnellen und
              effektiven Kommunikation mit Kunden, Interessenten und sonstigen
              Geschäfts- und Vertragspartnern (Art. 6 Abs. 1 S. 1 lit. f DSGVO).
              Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt
              die Datenverarbeitung ausschließlich auf Grundlage der
              Einwilligung; diese ist jederzeit mit Wirkung für die Zukunft
              widerrufbar.
            </p>
            <p>
              Die zwischen und auf WhatsApp ausgetauschten Kommunikationsinhalte
              verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck für die
              Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
              Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </p> */}
            {/* <h3>Calendly</h3>
            <p>
              Auf unserer Website haben Sie die Möglichkeit, Termine mit uns zu
              vereinbaren. Für die Terminbuchung nutzen wir das Tool „Calendly“.
              Anbieter ist die Calendly LLC, 271 17th St NW, 10th Floor,
              Atlanta, Georgia 30363, USA (nachfolgend „Calendly“).
            </p>
            <p>
              Zum Zweck der Terminbuchung geben Sie die abgefragten Daten und
              den Wunschtermin in die dafür vorgesehene Maske ein. Die
              eingegebenen Daten werden für die Planung, Durchführung und ggf.
              für die Nachbereitung des Termins verwendet.
            </p>
            <p>
              Die von Ihnen eingegebenen Daten verbleiben bei uns, bis Sie uns
              zur Löschung auffordern, Ihre Einwilligung zur Speicherung
              widerrufen oder der Zweck für die Datenspeicherung entfällt.
              Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </p>
            <p>
              Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f
              DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
              einer möglichst unkomplizierten Terminvereinbarung mit
              Interessenten und Kunden. Sofern eine Einwilligung abgefragt wurde
              ist Art. 6 Abs. 1 lit. a DSGVO die Rechtsgrundlage für die
              Datenverarbeitung; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://calendly.com/pages/dpa"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://calendly.com/pages/dpa
              </a>
              .
            </p> */}
            <h3>Registrierung auf dieser Website</h3>
            <p>
              Sie können sich auf dieser Website registrieren, um zusätzliche
              Funktionen auf der Seite zu nutzen. Die dazu eingegebenen Daten
              verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes
              oder Dienstes, für den Sie sich registriert haben. Die bei der
              Registrierung abgefragten Pflichtangaben müssen vollständig
              angegeben werden. Anderenfalls werden wir die Registrierung
              ablehnen.
            </p>
            <p>
              Für wichtige Änderungen etwa beim Angebotsumfang oder bei
              technisch notwendigen Änderungen nutzen wir die bei der
              Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu
              informieren.
            </p>
            <p>
              Die Verarbeitung der bei der Registrierung eingegebenen Daten
              erfolgt zum Zwecke der Durchführung des durch die Registrierung
              begründeten Nutzungsverhältnisses und ggf. zur Anbahnung weiterer
              Verträge (Art. 6 Abs. 1 lit. b DSGVO).
            </p>
            <p>
              Die bei der Registrierung erfassten Daten werden von uns
              gespeichert, solange Sie auf dieser Website registriert sind und
              werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen
              bleiben unberührt.
            </p>
            <h3>Registrierung mit Google</h3>
            <p>
              Statt einer direkten Registrierung auf dieser Website können Sie
              sich mit Google registrieren. Anbieter dieses Dienstes ist die
              Google Ireland Limited („Google”), Gordon House, Barrow Street,
              Dublin 4, Irland.
            </p>
            <p>
              Um sich mit Google zu registrieren, müssen Sie ausschließlich Ihre
              Google-Namen und Ihr Passwort eingeben. Google wird Sie
              identifizieren und unserer Website Ihre Identität bestätigen.
            </p>
            <p>
              Wenn Sie sich mit Google anmelden ist es uns ggf. möglich
              bestimmte Informationen auf Ihrem Konto zu nutzen, um Ihr Profil
              bei uns zu vervollständigen. Ob und welche Informationen das sind
              entscheiden Sie im Rahmen Ihrer Google-Sicherheitseinstellungen,
              die Sie hier finden:
              <a
                href="https://myaccount.google.com/security"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://myaccount.google.com/security
              </a>
              und
              <a
                href="https://myaccount.google.com/permissions"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://myaccount.google.com/permissions
              </a>
              .
            </p>
            <p>
              Die Datenverarbeitung, die mit der Google-Registrierung einhergeht
              beruht auf unserem berechtigten Interesse, unseren Nutzern einen
              möglichst einfachen Registrierungsprozess zu ermöglichen (Art. 6
              Abs. 1 lit. f DSGVO). Da die Nutzung der Registrierungsfunktion
              freiwillig ist und die Nutzer selbst über die jeweiligen
              Zugriffsmöglichkeiten entscheiden können, sind keine
              entgegenstehenden überwiegenden Rechte der Betroffenen
              ersichtlich.
            </p>
            <h3>Kommentar­funktion auf dieser Website</h3>
            <p>
              Für die Kommentarfunktion auf dieser Seite werden neben Ihrem
              Kommentar auch Angaben zum Zeitpunkt der Erstellung des
              Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten,
              der von Ihnen gewählte Nutzername gespeichert.
            </p>
            <h4>Speicherdauer der Kommentare</h4>
            <p>
              Die Kommentare und die damit verbundenen Daten werden gespeichert
              und verbleiben auf dieser Website, bis der kommentierte Inhalt
              vollständig gelöscht wurde oder die Kommentare aus rechtlichen
              Gründen gelöscht werden müssen (z. B. beleidigende Kommentare).
            </p>
            <h4>Rechtsgrundlage</h4>
            <p>
              Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von
              Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
              formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der
              bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
              unberührt.
            </p>
            <h2>4. Soziale Medien</h2>
            <h3>Social-Media-Plugins mit Shariff</h3>
            <p>
              Auf dieser Website werden Plugins verwendet (z. B. SendinBlue)
            </p>
            <p>
              Die Plugins können Sie in der Regel anhand der jeweiligen
              Social-Media-Logos erkennen. Um den Datenschutz auf dieser Website
              zu gewährleisten, verwenden wir diese Plugins nur zusammen mit der
              sogenannten „Shariff“-Lösung. Diese Anwendung verhindert, dass die
              auf dieser Website integrierten Plugins Daten schon beim ersten
              Betreten der Seite an den jeweiligen Anbieter übertragen.
            </p>
            <p>
              Erst wenn Sie das jeweilige Plugin durch Anklicken der zugehörigen
              Schaltfläche aktivieren, wird eine direkte Verbindung zum Server
              des Anbieters hergestellt (Einwilligung). Sobald Sie das Plugin
              aktivieren, erhält der jeweilige Anbieter die Information, dass
              Sie mit Ihrer IP-Adresse dieser Website besucht haben. Wenn Sie
              gleichzeitig in Ihrem jeweiligen Social-Media-Account (z. B.
              Facebook) eingeloggt sind, kann der jeweilige Anbieter den Besuch
              dieser Website Ihrem Benutzerkonto zuordnen.
            </p>
            <p>
              Das Aktivieren des Plugins stellt eine Einwilligung im Sinne des
              Art. 6 Abs. 1 lit. a DSGVO dar. Diese Einwilligung können Sie
              jederzeit mit Wirkung für die Zukunft widerrufen.
            </p>
            <h3>Facebook Plugins (Like & Share-Button)</h3>
            <p>
              Auf dieser Website sind Plugins des sozialen Netzwerks Facebook
              integriert. Anbieter dieses Dienstes ist die Facebook Ireland
              Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten
              Daten werden nach Aussage von Facebook jedoch auch in die USA und
              in andere Drittländer übertragen.
            </p>
            <p>
              Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem
              „Like-Button“ („Gefällt mir“) auf dieser Website. Eine Übersicht
              über die Facebook Plugins finden Sie hier:
              <a
                href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://developers.facebook.com/docs/plugins/?locale=de_DE
              </a>
              .
            </p>
            <p>
              Wenn Sie diese Website besuchen, wird über das Plugin eine direkte
              Verbindung zwischen Ihrem Browser und dem Facebook-Server
              hergestellt. Facebook erhält dadurch die Information, dass Sie mit
              Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den
              Facebook „Like-Button“ anklicken, während Sie in Ihrem
              Facebook-Account eingeloggt sind, können Sie die Inhalte dieser
              Website auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook
              den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen
              darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
              Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook
              erhalten. Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von Facebook unter:
              <a
                href="https://de-de.facebook.com/privacy/explanation"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://de-de.facebook.com/privacy/explanation
              </a>
              .
            </p>
            <p>
              Wenn Sie nicht wünschen, dass Facebook den Besuch dieser Website
              Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte
              aus Ihrem Facebook-Benutzerkonto aus.
            </p>
            <p>
              Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst umfangreichen Sichtbarkeit in den
              Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
              widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/legal/EU_data_transfer_addendum
              </a>
              ,
              <a
                href="https://de-de.facebook.com/help/566994660333381"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://de-de.facebook.com/help/566994660333381
              </a>
              und
              <a
                href="https://www.facebook.com/policy.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/policy.php
              </a>
              .
            </p>
            <h3>Twitter Plugin</h3>
            <p>
              Auf dieser Website sind Funktionen des Dienstes Twitter
              eingebunden. Diese Funktionen werden angeboten durch die Twitter
              International Company, One Cumberland Place, Fenian Street, Dublin
              2, D02 AX07, Irland. Durch das Benutzen von Twitter und der
              Funktion „Re-Tweet“ werden die von Ihnen besuchten Websites mit
              Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt
              gegeben. Dabei werden auch Daten an Twitter übertragen. Wir weisen
              darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
              Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter
              erhalten. Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von Twitter unter:
              <a
                href="https://twitter.com/de/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://twitter.com/de/privacy
              </a>
              .
            </p>
            <p>
              Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst umfangreichen Sichtbarkeit in den
              Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
              widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://gdpr.twitter.com/en/controller-to-controller-transfers.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://gdpr.twitter.com/en/controller-to-controller-transfers.html
              </a>
              .
            </p>
            <p>
              Ihre Datenschutzeinstellungen bei Twitter können Sie in den
              Konto-Einstellungen unter
              <a
                href="https://twitter.com/account/settings"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://twitter.com/account/settings
              </a>
              ändern.
            </p>
            <h3>Instagram Plugin</h3>
            <p>
              Auf dieser Website sind Funktionen des Dienstes Instagram
              eingebunden. Diese Funktionen werden angeboten durch die Facebook
              Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin
              2, Irland integriert.
            </p>
            <p>
              Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie
              durch Anklicken des Instagram-Buttons die Inhalte dieser Website
              mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den
              Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen
              darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
              Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram
              erhalten.
            </p>
            <p>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an einer möglichst umfangreichen
              Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
              Einwilligung abgefragt wurde, erfolgt die Verarbeitung
              ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
              Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/legal/EU_data_transfer_addendum
              </a>
              ,
              <a
                href="https://help.instagram.com/519522125107875"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://help.instagram.com/519522125107875
              </a>
              und
              <a
                href="https://de-de.facebook.com/help/566994660333381"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://de-de.facebook.com/help/566994660333381
              </a>
              .
            </p>
            <p>
              Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von Instagram:
              <a
                href="https://instagram.com/about/legal/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://instagram.com/about/legal/privacy/
              </a>
              .
            </p>
            {/* <h3>Tumblr Plugin</h3>
            <p>
              Diese Website nutzt Schaltflächen des Dienstes Tumblr. Anbieter
              ist die Tumblr, Inc., 35 East 21st St, 10th Floor, New York, NY
              10010, USA.
            </p>
            <p>
              Diese Schaltflächen ermöglichen es Ihnen, einen Beitrag oder eine
              Seite bei Tumblr zu teilen oder dem Anbieter bei Tumblr zu folgen.
              Wenn Sie eine unserer Websites mit Tumblr-Button aufrufen, baut
              der Browser eine direkte Verbindung mit den Servern von Tumblr
              auf. Wir haben keinen Einfluss auf den Umfang der Daten, die
              Tumblr mit Hilfe dieses Plugins erhebt und übermittelt. Nach
              aktuellem Stand werden die IP-Adresse des Nutzers sowie die URL
              der jeweiligen Website übermittelt.
            </p>
            <p>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an einer möglichst umfangreichen
              Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
              Einwilligung abgefragt wurde, erfolgt die Verarbeitung
              ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
              Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Weitere Informationen hierzu finden sich in der
              Datenschutzerklärung von Tumblr unter:
              <a
                href="https://www.tumblr.com/privacy/de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.tumblr.com/privacy/de
              </a>
              .
            </p> */}
            <h3>LinkedIn Plugin</h3>
            <p>
              Diese Website nutzt Funktionen des Netzwerks LinkedIn. Anbieter
              ist die LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton
              Place, Dublin 2, Irland.
            </p>
            <p>
              Bei jedem Abruf einer Seite dieser Website, die Funktionen von
              LinkedIn enthält, wird eine Verbindung zu Servern von LinkedIn
              aufgebaut. LinkedIn wird darüber informiert, dass Sie diese
              Website mit Ihrer IP-Adresse besucht haben. Wenn Sie den
              „Recommend-Button“ von LinkedIn anklicken und in Ihrem Account bei
              LinkedIn eingeloggt sind, ist es LinkedIn möglich, Ihren Besuch
              auf dieser Website Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir
              weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
              vom Inhalt der übermittelten Daten sowie deren Nutzung durch
              LinkedIn haben.
            </p>
            <p>
              Die Verwendung des LinkedIn-Plugins erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst umfangreichen Sichtbarkeit in den
              Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
              widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de
              </a>
            </p>
            <p>
              Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von LinkedIn unter:
              <a
                href="https://www.linkedin.com/legal/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.linkedin.com/legal/privacy-policy
              </a>
              .
            </p>
            {/* <h3>XING Plugin</h3>
            <p>
              Diese Website nutzt Funktionen des Netzwerks XING. Anbieter ist
              die New Work SE, Dammtorstraße 30, 20354 Hamburg, Deutschland.
            </p>
            <p>
              Bei jedem Abruf einer unserer Seiten, die Funktionen von XING
              enthält, wird eine Verbindung zu Servern von XING hergestellt.
              Eine Speicherung von personenbezogenen Daten erfolgt dabei nach
              unserer Kenntnis nicht. Insbesondere werden keine IP-Adressen
              gespeichert oder das Nutzungsverhalten ausgewertet.
            </p>
            <p>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an einer möglichst umfangreichen
              Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
              Einwilligung abgefragt wurde, erfolgt die Verarbeitung
              ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
              Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Weitere Information zum Datenschutz und dem XING Share-Button
              finden Sie in der Datenschutzerklärung von XING unter:
              <a
                href="https://www.xing.com/app/share?op=data_protection"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.xing.com/app/share?op=data_protection
              </a>
              .
            </p> */}
            {/* <h3>Pinterest Plugin</h3>
            <p>
              Auf dieser Website verwenden wir Social Plugins des sozialen
              Netzwerkes Pinterest, das von der Pinterest Europe Ltd.,
              Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Irland
              betrieben wird.
            </p>
            <p>
              Wenn Sie eine Seite aufrufen, die ein solches Plugin enthält,
              stellt Ihr Browser eine direkte Verbindung zu den Servern von
              Pinterest her. Das Plugin übermittelt dabei Protokolldaten an den
              Server von Pinterest in die USA. Diese Protokolldaten enthalten
              möglicherweise Ihre IP-Adresse, die Adresse der besuchten
              Websites, die ebenfalls Pinterest-Funktionen enthalten, Art und
              Einstellungen des Browsers, Datum und Zeitpunkt der Anfrage, Ihre
              Verwendungsweise von Pinterest sowie Cookies.
            </p>
            <p>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an einer möglichst umfangreichen
              Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
              Einwilligung abgefragt wurde, erfolgt die Verarbeitung
              ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
              Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Weitere Informationen zu Zweck, Umfang und weiterer Verarbeitung
              und Nutzung der Daten durch Pinterest sowie Ihre diesbezüglichen
              Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie
              in den Datenschutzhinweisen von Pinterest:
              <a
                href="https://policy.pinterest.com/de/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policy.pinterest.com/de/privacy-policy
              </a>
              .
            </p> */}
            <h2>5. Analyse-Tools und Werbung</h2>
            <h3>Google Tag Manager</h3>
            <p>
              Wir setzen den Google Tag Manager ein. Anbieter ist die Google
              Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir
              Tracking- oder Statistik-Tools und andere Technologien auf unserer
              Website einbinden können. Der Google Tag Manager selbst erstellt
              keine Nutzerprofile, speichert keine Cookies und nimmt keine
              eigenständigen Analysen vor. Er dient lediglich der Verwaltung und
              Ausspielung der über ihn eingebundenen Tools. Der Google Tag
              Manager erfasst jedoch Ihre IP-Adresse, die auch an das
              Mutterunternehmen von Google in die Vereinigten Staaten übertragen
              werden kann.
            </p>
            <p>
              Der Einsatz des Google Tag Managers erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer schnellen und unkomplizierten Einbindung und
              Verwaltung verschiedener Tools auf seiner Website. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <h3>Google Analytics</h3>
            <p>
              Diese Website nutzt Funktionen des Webanalysedienstes Google
              Analytics. Anbieter ist die Google Ireland Limited („Google“),
              Gordon House, Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten
              der Websitebesucher zu analysieren. Hierbei erhält der
              Websitebetreiber verschiedene Nutzungsdaten, wie z.B.
              Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und
              Herkunft des Nutzers. Diese Daten werden von Google ggf. in einem
              Profil zusammengefasst, das dem jeweiligen Nutzer bzw. dessen
              Endgerät zugeordnet ist.
            </p>
            <p>
              Google Analytics verwendet Technologien, die die Wiedererkennung
              des Nutzers zum Zwecke der Analyse des Nutzerverhaltens
              ermöglichen (z.B. Cookies oder Device-Fingerprinting). Die von
              Google erfassten Informationen über die Benutzung dieser Website
              werden in der Regel an einen Server von Google in den USA
              übertragen und dort gespeichert.
            </p>
            <p>
              Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
              Webangebot als auch seine Werbung zu optimieren. Sofern eine
              entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://privacy.google.com/businesses/controllerterms/mccs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.google.com/businesses/controllerterms/mccs/
              </a>
              .
            </p>
            <h4>IP Anonymisierung</h4>
            <p>
              Wir haben auf dieser Website die Funktion IP-Anonymisierung
              aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen
              Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in
              Ausnahmefällen wird die volle IP-Adresse an einen Server von
              Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Website wird Google diese Informationen
              benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
              die Websiteaktivitäten zusammenzustellen und um weitere mit der
              Websitenutzung und der Internetnutzung verbundene Dienstleistungen
              gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
              Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
              nicht mit anderen Daten von Google zusammengeführt.
            </p>
            <h4>Browser Plugin</h4>
            <p>
              Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
              verhindern, indem Sie das unter dem folgenden Link verfügbare
              Browser-Plugin herunterladen und installieren:
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              .
            </p>
            <p>
              Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
              finden Sie in der Datenschutzerklärung von Google:
              <a
                href="https://support.google.com/analytics/answer/6004245?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/analytics/answer/6004245?hl=de
              </a>
              .
            </p>
            <h4>Speicherdauer</h4>
            <p>
              Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die
              mit Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B.
              DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach
              14 Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie
              unter folgendem Link:
              <a
                href="https://support.google.com/analytics/answer/7667196?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/analytics/answer/7667196?hl=de
              </a>
            </p>
            <h3>Google Ads</h3>
            <p>
              Der Websitebetreiber verwendet Google Ads. Google Ads ist ein
              Online-Werbeprogramm der Google Ireland Limited („Google“), Gordon
              House, Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Google Ads ermöglicht es uns Werbeanzeigen in der
              Google-Suchmaschine oder auf Drittwebseiten auszuspielen, wenn der
              Nutzer bestimmte Suchbegriffe bei Google eingibt
              (Keyword-Targeting). Ferner können zielgerichtete Werbeanzeigen
              anhand der bei Google vorhandenen Nutzerdaten (z.B. Standortdaten
              und Interessen) ausgespielt werden (Zielgruppen-Targeting). Wir
              als Websitebetreiber können diese Daten quantitativ auswerten,
              indem wir beispielsweise analysieren, welche Suchbegriffe zur
              Ausspielung unserer Werbeanzeigen geführt haben und wie viele
              Anzeigen zu entsprechenden Klicks geführt haben.
            </p>
            <p>
              Die Nutzung von Google Ads erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an einer möglichst effektiven Vermarktung seiner Dienstleistung
              Produkte.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://policies.google.com/privacy/frameworks"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy/frameworks
              </a>
              und
              <a
                href="https://privacy.google.com/businesses/controllerterms/mccs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.google.com/businesses/controllerterms/mccs/
              </a>
              .
            </p>
            <h3>Google AdSense (nicht personalisiert)</h3>
            <p>
              Diese Website nutzt Google AdSense, einen Dienst zum Einbinden von
              Werbeanzeigen. Anbieter ist die Google Ireland Limited („Google“),
              Gordon House, Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Wir nutzen Google AdSense im „nicht-personalisierten“ Modus. Im
              Gegensatz zum personalisierten Modus beruhen die Werbeanzeigen
              daher nicht auf Ihrem früheren Nutzerverhalten und es wird kein
              Nutzerprofil von Ihnen erstellt. Stattdessen werden bei der
              Auswahl der Werbung sogenannte „Kontextinformationen“
              herangezogen. Die ausgewählten Werbeanzeigen richten sich dann
              beispielsweise nach Ihrem Standort, dem Inhalt der Website, auf
              der Sie sich befinden oder nach Ihren aktuellen Suchbegriffen.
              Mehr zu den Unterschieden zwischen personalisiertem und
              nicht-personalisiertem Targeting mit Google AdSense finden Sie
              unter:
              <a
                href="https://support.google.com/adsense/answer/9007336"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/adsense/answer/9007336
              </a>
              .
            </p>
            <p>
              Bitte beachten Sie, dass auch beim Einsatz von Google Adsense im
              nicht-personalisierten Modus Cookies oder vergleichbare
              Wiedererkennungstechnologien (z.B. Device-Fingerprinting)
              verwendet werden können. Diese werden laut Google zur Bekämpfung
              von Betrug und Missbrauch eingesetzt.
            </p>
            <p>
              Die Nutzung von AdSense erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an einer möglichst effektiven Vermarktung seiner Website. Sofern
              eine entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://privacy.google.com/businesses/controllerterms/mccs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.google.com/businesses/controllerterms/mccs/
              </a>
              .
            </p>
            <p>
              Sie können Ihre Werbeeinstellungen selbstständig in Ihrem
              Nutzer-Account anpassen. Klicken Sie hierzu auf folgenden Link und
              loggen Sie sich ein:
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </p>
            <p>
              Weitere Informationen zu den Werbetechnologien von Google finden
              Sie hier:
              <a
                href="https://policies.google.com/technologies/ads"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/technologies/ads
              </a>
              und
              <a
                href="https://www.google.de/intl/de/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.google.de/intl/de/policies/privacy/
              </a>
              .
            </p>
            <h3>Google Remarketing</h3>
            <p>
              Diese Website nutzt die Funktionen von Google Analytics
              Remarketing. Anbieter ist die Google Ireland Limited („Google“),
              Gordon House, Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Google Remarketing analysiert Ihr Nutzerverhalten auf unserer
              Website (z.B. Klick auf bestimmte Produkte), um Sie in bestimmte
              Werbe-Zielgruppen einzuordnen und Ihnen anschließend beim Besuch
              von anderen Onlineangeboten passende Webebotschaften auszuspielen
              (Remarketing bzw. Retargeting).
            </p>
            <p>
              Des Weiteren können die mit Google Remarketing erstellten
              Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von
              Google verknüpft werden. Auf diese Weise können
              interessenbezogene, personalisierte Werbebotschaften, die in
              Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem
              Endgerät (z. B. Handy) an Sie angepasst wurden auch auf einem
              anderen Ihrer Endgeräte (z. B. Tablet oder PC) angezeigt werden.
            </p>
            <p>
              Wenn Sie über einen Google-Account verfügen, können Sie der
              personalisierten Werbung unter folgendem Link widersprechen:
              <a
                href="https://www.google.com/settings/ads/onweb/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.google.com/settings/ads/onweb/
              </a>
              .
            </p>
            <p>
              Die Nutzung von Google Remarketing erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst effektiven Vermarktung seiner
              Produkte. Sofern eine entsprechende Einwilligung abgefragt wurde,
              erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Weitergehende Informationen und die Datenschutzbestimmungen finden
              Sie in der Datenschutzerklärung von Google unter:
              <a
                href="https://policies.google.com/technologies/ads?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/technologies/ads?hl=de
              </a>
              .
            </p>
            <h4>Zielgruppenbildung mit Kundenabgleich</h4>
            <p>
              Zur Zielgruppenbildung verwenden wir unter anderem den
              Kundenabgleich von Google Remarketing. Hierbei übergeben wir
              bestimmte Kundendaten (z.B. E-Mail-Adressen) aus unseren
              Kundenlisten an Google. Sind die betreffenden Kunden Google-Nutzer
              und in ihrem Google-Konto eingeloggt, werden ihnen passende
              Werbebotschaften innerhalb des Google-Netzwerks (z.B. bei YouTube,
              Gmail oder in der Suchmaschine) angezeigt.
            </p>
            <h3>Google Conversion-Tracking</h3>
            <p>
              Diese Website nutzt Google Conversion Tracking. Anbieter ist die
              Google Ireland Limited („Google“), Gordon House, Barrow Street,
              Dublin 4, Irland.
            </p>
            <p>
              Mit Hilfe von Google-Conversion-Tracking können Google und wir
              erkennen, ob der Nutzer bestimmte Aktionen durchgeführt hat. So
              können wir beispielsweise auswerten, welche Buttons auf unserer
              Website wie häufig geklickt und welche Produkte besonders häufig
              angesehen oder gekauft wurden. Diese Informationen dienen dazu,
              Conversion-Statistiken zu erstellen. Wir erfahren die Gesamtanzahl
              der Nutzer, die auf unsere Anzeigen geklickt haben und welche
              Aktionen sie durchgeführt haben. Wir erhalten keine Informationen,
              mit denen wir den Nutzer persönlich identifizieren können. Google
              selbst nutzt zur Identifikation Cookies oder vergleichbare
              Wiedererkennungstechnologien.
            </p>
            <p>
              Die Nutzung von Google Conversion-Tracking erfolgt auf Grundlage
              von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
              sowohl sein Webangebot als auch seine Werbung zu optimieren.
              Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Mehr Informationen zu Google Conversion-Tracking finden Sie in den
              Datenschutzbestimmungen von Google:
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
            {/* <h3>Google DoubleClick</h3>
            <p>
              Diese Website nutzt Funktionen von Google DoubleClick. Anbieter
              ist die Google Ireland Limited („Google“), Gordon House, Barrow
              Street, Dublin 4, Irland, (nachfolgend „DoubleClick“).
            </p>
            <p>
              DoubleClick wird eingesetzt, um Ihnen interessenbezogene
              Werbeanzeigen im gesamten Google-Werbenetzwerk anzuzeigen. Die
              Werbeanzeigen können mit Hilfe von DoubleClick zielgerichtet an
              die Interessen des jeweiligen Betrachters angepasst werden. So
              kann unsere Werbung beispielsweise in Google-Suchergebnissen oder
              in Werbebannern, die mit DoubleClick verbunden sind, angezeigt
              werden.
            </p>
            <p>
              Um den Nutzern interessengerechte Werbung anzeigen zu können, muss
              DoubleClick den jeweiligen Betrachter wiedererkennen und ihm seine
              besuchten Webseiten, Klicks und sonstigen Informationen zum
              Nutzerverhalten zuordnen können. Hierzu setzt DoubleClick Cookies
              oder vergleichbare Wiedererkennungstechnologien (z.B.
              Device-Fingerprinting) ein. Die erfassten Informationen werden zu
              einem pseudonymen Nutzerprofil zusammengefasst, um dem
              betreffenden Nutzer interessengerechte Werbung anzuzeigen.
            </p>
            <p>
              Die Nutzung von Google DoubleClick erfolgt im Interesse
              zielgerichteter Werbemaßnahmen. Dies stellt ein berechtigtes
              Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
              entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Weitere Informationen zu Widerspruchsmöglichkeiten gegen die von
              Google eingeblendeten Werbeanzeigen entnehmen Sie folgenden Links:
              <a
                href="https://policies.google.com/technologies/ads"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/technologies/ads
              </a>
              und
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://adssettings.google.com/authenticated
              </a>
              .
            </p> */}
            {/* <h3>Facebook Pixel</h3>
            <p>
              Diese Website nutzt zur Konversionsmessung der
              Besucheraktions-Pixel von Facebook. Anbieter dieses Dienstes ist
              die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2,
              Irland. Die erfassten Daten werden nach Aussage von Facebook
              jedoch auch in die USA und in andere Drittländer übertragen.
            </p>
            <p>
              So kann das Verhalten der Seitenbesucher nachverfolgt werden,
              nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die
              Website des Anbieters weitergeleitet wurden. Dadurch können die
              Wirksamkeit der Facebook-Werbeanzeigen für statistische und
              Marktforschungszwecke ausgewertet werden und zukünftige
              Werbemaßnahmen optimiert werden.
            </p>
            <p>
              Die erhobenen Daten sind für uns als Betreiber dieser Website
              anonym, wir können keine Rückschlüsse auf die Identität der Nutzer
              ziehen. Die Daten werden aber von Facebook gespeichert und
              verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil
              möglich ist und Facebook die Daten für eigene Werbezwecke,
              entsprechend der
              <a
                href="https://de-de.facebook.com/about/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook-Datenverwendungsrichtlinie
              </a>
              verwenden kann. Dadurch kann Facebook das Schalten von
              Werbeanzeigen auf Seiten von Facebook sowie außerhalb von Facebook
              ermöglichen. Diese Verwendung der Daten kann von uns als
              Seitenbetreiber nicht beeinflusst werden.
            </p>
            <p>
              Die Nutzung von Facebook-Pixel erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an effektiven Werbemaßnahmen unter Einschluss der
              sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde (z. B. eine Einwilligung zur Speicherung von Cookies),
              erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/legal/EU_data_transfer_addendum
              </a>
              und
              <a
                href="https://de-de.facebook.com/help/566994660333381"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://de-de.facebook.com/help/566994660333381
              </a>
              .
            </p>
            <p>
              In den Datenschutzhinweisen von Facebook finden Sie weitere
              Hinweise zum Schutz Ihrer Privatsphäre:
              <a
                href="https://de-de.facebook.com/about/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://de-de.facebook.com/about/privacy/
              </a>
              .
            </p>
            <p>
              Sie können außerdem die Remarketing-Funktion „Custom Audiences“ im
              Bereich Einstellungen für Werbeanzeigen unter
              <a
                href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
              </a>
              deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
            </p>
            <p>
              Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte
              Werbung von Facebook auf der Website der European Interactive
              Digital Advertising Alliance deaktivieren:
              <a
                href="http://www.youronlinechoices.com/de/praferenzmanagement/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.youronlinechoices.com/de/praferenzmanagement/
              </a>
              .
            </p> */}
            <h2>6. Newsletter</h2>
            <h3>Newsletter­daten</h3>
            <p>
              Wenn Sie den auf der Website angebotenen Newsletter beziehen
              möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
              Informationen, welche uns die Überprüfung gestatten, dass Sie der
              Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang
              des Newsletters einverstanden sind. Weitere Daten werden nicht
              bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
              ausschließlich für den Versand der angeforderten Informationen und
              geben diese nicht an Dritte weiter.
            </p>
            <p>
              Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
              Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung
              (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
              Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
              Versand des Newsletters können Sie jederzeit widerrufen, etwa über
              den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits
              erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
              unberührt.
            </p>
            <p>
              Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
              hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem
              Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert
              und nach der Abbestellung des Newsletters oder nach Zweckfortfall
              aus der Newsletterverteilerliste gelöscht. Wir behalten uns vor,
              E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem
              Ermessen im Rahmen unseres berechtigten Interesses nach Art. 6
              Abs. 1 lit. f DSGVO zu löschen oder zu sperren.
            </p>
            <p>
              Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
              E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
              einer Blacklist gespeichert, um künftige Mailings zu verhindern.
              Die Daten aus der Blacklist werden nur für diesen Zweck verwendet
              und nicht mit anderen Daten zusammengeführt. Dies dient sowohl
              Ihrem Interesse als auch unserem Interesse an der Einhaltung der
              gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
              Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
              Speicherung in der Blacklist ist zeitlich nicht befristet.
              <strong>
                Sie können der Speicherung widersprechen, sofern Ihre Interessen
                unser berechtigtes Interesse überwiegen.
              </strong>
            </p>
            {/* <h3>MailChimp</h3>
            <p>
              Diese Website nutzt die Dienste von MailChimp für den Versand von
              Newslettern. Anbieter ist die Rocket Science Group LLC, 675 Ponce
              De Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA.
            </p>
            <p>
              MailChimp ist ein Dienst, mit dem u.a. der Versand von Newslettern
              organisiert und analysiert werden kann. Wenn Sie Daten zum Zwecke
              des Newsletterbezugs eingeben (z. B. E-Mail-Adresse), werden diese
              auf den Servern von MailChimp in den USA gespeichert.
            </p>
            <p>
              Mit Hilfe von MailChimp können wir unsere Newsletterkampagnen
              analysieren. Wenn Sie eine mit MailChimp versandte E-Mail öffnen,
              verbindet sich eine in der E-Mail enthaltene Datei (sog.
              web-beacon) mit den Servern von MailChimp in den USA. So kann
              festgestellt werden, ob eine Newsletter-Nachricht geöffnet und
              welche Links ggf. angeklickt wurden. Außerdem werden technische
              Informationen erfasst (z. B. Zeitpunkt des Abrufs, IP-Adresse,
              Browsertyp und Betriebssystem). Diese Informationen können nicht
              dem jeweiligen Newsletter-Empfänger zugeordnet werden. Sie dienen
              ausschließlich der statistischen Analyse von Newsletterkampagnen.
              Die Ergebnisse dieser Analysen können genutzt werden, um künftige
              Newsletter besser an die Interessen der Empfänger anzupassen.
            </p>
            <p>
              Wenn Sie keine Analyse durch MailChimp wollen, müssen Sie den
              Newsletter abbestellen. Hierfür stellen wir in jeder
              Newsletternachricht einen entsprechenden Link zur Verfügung.
            </p>
            <p>
              Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung
              (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung
              jederzeit widerrufen, indem Sie den Newsletter abbestellen. Die
              Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge
              bleibt vom Widerruf unberührt.
            </p>
            <p>
              Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
              hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem
              Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert
              und nach der Abbestellung des Newsletters aus der
              Newsletterverteilerliste gelöscht. Daten, die zu anderen Zwecken
              bei uns gespeichert wurden, bleiben hiervon unberührt.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://mailchimp.com/eu-us-data-transfer-statement/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mailchimp.com/eu-us-data-transfer-statement/
              </a>
              und
              <a
                href="https://mailchimp.com/legal/data-processing-addendum/#Annex_C_-_Standard_Contractual_Clauses"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mailchimp.com/legal/data-processing-addendum/#Annex_C_-_Standard_Contractual_Clauses
              </a>
              .
            </p>
            <p>
              Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
              E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
              einer Blacklist gespeichert, um künftige Mailings zu verhindern.
              Die Daten aus der Blacklist werden nur für diesen Zweck verwendet
              und nicht mit anderen Daten zusammengeführt. Dies dient sowohl
              Ihrem Interesse als auch unserem Interesse an der Einhaltung der
              gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
              Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
              Speicherung in der Blacklist ist zeitlich nicht befristet.
              <strong>
                Sie können der Speicherung widersprechen, sofern Ihre Interessen
                unser berechtigtes Interesse überwiegen.
              </strong>
            </p>
            <p>
              Näheres entnehmen Sie den Datenschutzbestimmungen von MailChimp
              unter:
              <a
                href="https://mailchimp.com/legal/terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mailchimp.com/legal/terms/
              </a>
              .
            </p> */}
            {/* <h3>Klick-Tipp</h3>
            <p>
              Diese Website nutzt Klick-Tipp für den Versand von Newslettern.
              Anbieter ist die KLICK-TIPP LIMITED, 15 Cambridge Court, 210
              Shepherd’s Bush Road, London W6 7NJ, Vereinigtes Königreich.
            </p>
            <p>
              Klick-Tipp ist ein Dienst, mit dem u.a. der Versand von
              Newslettern organisiert und analysiert werden kann. Die von Ihnen
              zum Zwecke des Newsletterbezugs eingegeben Daten werden auf den
              Servern von Klick-Tipp gespeichert.
            </p>
            <h4>Datenanalyse durch Klick-Tipp</h4>
            <p>
              Wenn wir Newsletter mit Hilfe von Klick-Tipp versenden, können wir
              feststellen, ob eine Newsletter-Nachricht geöffnet und welche
              Links ggf. angeklickt wurden.
            </p>
            <p>
              Klick-Tipp ermöglicht es uns auch, die Newsletter-Empfänger anhand
              verschiedener Kategorien zu unterteilen (sog. Tagging). Dabei
              lassen sich die Newsletterempfänger z. B. nach Geschlecht,
              persönlichen Vorlieben (z. B. Vegetarier oder Nicht-Vegetarier)
              oder Kundenbeziehung (z. B. Kunde oder potenzieller Kunde)
              unterteilen. Auf diese Weise lassen sich die Newsletter besser an
              die jeweiligen Zielgruppen anpassen. Nähere Informationen erhalten
              Sie unter:
              <a
                href="https://www.klick-tipp.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.klick-tipp.com
              </a>
              und
              <a
                href="https://www.klick-tipp.com/handbuch"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.klick-tipp.com/handbuch
              </a>
              .
            </p>
            <p>
              Wenn Sie keine Analyse durch Klick-Tipp wollen, müssen Sie daher
              den Newsletter abbestellen. Hierfür stellen wir in jeder
              Newsletternachricht einen entsprechenden Link zur Verfügung.
            </p>
            <h4>Rechtsgrundlage</h4>
            <p>
              Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung
              (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung
              jederzeit widerrufen. Die Rechtmäßigkeit der bereits erfolgten
              Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
            </p>
            <h4>Speicherdauer</h4>
            <p>
              Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
              hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem
              Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert
              und nach der Abbestellung des Newsletters aus der
              Newsletterverteilerliste gelöscht. Daten, die zu anderen Zwecken
              bei uns gespeichert wurden, bleiben hiervon unberührt.
            </p>
            <p>
              Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
              E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
              einer Blacklist gespeichert, um künftige Mailings zu verhindern.
              Die Daten aus der Blacklist werden nur für diesen Zweck verwendet
              und nicht mit anderen Daten zusammengeführt. Dies dient sowohl
              Ihrem Interesse als auch unserem Interesse an der Einhaltung der
              gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
              Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
              Speicherung in der Blacklist ist zeitlich nicht befristet.
              <strong>
                Sie können der Speicherung widersprechen, sofern Ihre Interessen
                unser berechtigtes Interesse überwiegen.
              </strong>
            </p>
            <p>
              Näheres entnehmen Sie den Datenschutzbestimmungen von Klick-Tipp
              unter:
              <a
                href="https://www.klick-tipp.com/datenschutzerklaerung"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.klick-tipp.com/datenschutzerklaerung
              </a>
              .
            </p>
            <h4>Abschluss eines Vertrags über Auftragsverarbeitung</h4>
            <p>
              Wir haben einen Vertrag über Auftragsverarbeitung mit Klick-Tipp
              abgeschlossen, in dem wir Klick-Tipp verpflichten, die Daten
              unserer Kunden zu schützen und sie nicht an Dritte weiterzugeben.
            </p> */}
            <h2>7. Plugins und Tools</h2>
            <h3>YouTube mit erweitertem Datenschutz</h3>
            <p>
              Diese Website bindet Videos der YouTube ein. Betreiber der Seiten
              ist die Google Ireland Limited („Google“), Gordon House, Barrow
              Street, Dublin 4, Irland.
            </p>
            <p>
              Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
              bewirkt laut YouTube, dass YouTube keine Informationen über die
              Besucher auf dieser Website speichert, bevor diese sich das Video
              ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch
              den erweiterten Datenschutzmodus hingegen nicht zwingend
              ausgeschlossen. So stellt YouTube – unabhängig davon, ob Sie sich
              ein Video ansehen – eine Verbindung zum Google
              DoubleClick-Netzwerk her.
            </p>
            <p>
              Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
              Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
              YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht
              haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind,
              ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
              persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem
              Sie sich aus Ihrem YouTube-Account ausloggen.
            </p>
            <p>
              Des Weiteren kann YouTube nach Starten eines Videos verschiedene
              Cookies auf Ihrem Endgerät speichern oder vergleichbare
              Wiedererkennungstechnologien (z.B. Device-Fingerprinting)
              einsetzen. Auf diese Weise kann YouTube Informationen über
              Besucher dieser Website erhalten. Diese Informationen werden u. a.
              verwendet, um Videostatistiken zu erfassen, die
              Anwenderfreundlichkeit zu verbessern und Betrugsversuchen
              vorzubeugen.
            </p>
            <p>
              Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
              Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
              Einfluss haben.
            </p>
            <p>
              Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
              Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
              Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Weitere Informationen über Datenschutz bei YouTube finden Sie in
              deren Datenschutzerklärung unter:
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
            {/* <h3>Vimeo</h3>
            <p>
              Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist
              die Vimeo Inc., 555 West 18th Street, New York, New York 10011,
              USA.
            </p>
            <p>
              Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten Seiten
              besuchen, wird eine Verbindung zu den Servern von Vimeo
              hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche
              unserer Seiten Sie besucht haben. Zudem erlangt Vimeo Ihre
              IP-Adresse. Dies gilt auch dann, wenn Sie nicht bei Vimeo
              eingeloggt sind oder keinen Account bei Vimeo besitzen. Die von
              Vimeo erfassten Informationen werden an den Vimeo-Server in den
              USA übermittelt.
            </p>
            <p>
              Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, ermöglichen Sie
              Vimeo, Ihr Surfverhalten direkt Ihrem persönlichen Profil
              zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
              Vimeo-Account ausloggen.
            </p>
            <p>
              Zur Wiedererkennung der Websitebesucher verwendet Vimeo Cookies
              bzw. vergleichbare Wiedererkennungstechnologien (z.B.
              Device-Fingerprinting).
            </p>
            <p>
              Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden
              Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
              Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission sowie nach Aussage von
              Vimeo auf „berechtigte Geschäftsinteressen“ gestützt. Details
              finden Sie hier:
              <a
                href="https://vimeo.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://vimeo.com/privacy
              </a>
              .
            </p>
            <p>
              Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerklärung von Vimeo unter:
              <a
                href="https://vimeo.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://vimeo.com/privacy
              </a>
              .
            </p> */}
            {/* <h3>Google Maps</h3>
            <p>
              Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
              Google Ireland Limited („Google“), Gordon House, Barrow Street,
              Dublin 4, Irland.
            </p>
            <p>
              Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
              IP-Adresse zu speichern. Diese Informationen werden in der Regel
              an einen Server von Google in den USA übertragen und dort
              gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
              diese Datenübertragung.
            </p>
            <p>
              Die Nutzung von Google Maps erfolgt im Interesse einer
              ansprechenden Darstellung unserer Online-Angebote und an einer
              leichten Auffindbarkeit der von uns auf der Website angegebenen
              Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
              Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
              abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
              Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://privacy.google.com/businesses/gdprcontrollerterms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.google.com/businesses/gdprcontrollerterms/
              </a>
              und
              <a
                href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
              </a>
              .
            </p>
            <p>
              Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerklärung von Google:
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p> */}
            {/* <h3>Google reCAPTCHA</h3>
            <p>
              Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf
              dieser Website. Anbieter ist die Google Ireland Limited
              („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
            </p>
            <p>
              Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf
              dieser Website (z. B. in einem Kontaktformular) durch einen
              Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu
              analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand
              verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald
              der Websitebesucher die Website betritt. Zur Analyse wertet
              reCAPTCHA verschiedene Informationen aus (z. B. IP-Adresse,
              Verweildauer des Websitebesuchers auf der Website oder vom Nutzer
              getätigte Mausbewegungen). Die bei der Analyse erfassten Daten
              werden an Google weitergeleitet.
            </p>
            <p>
              Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
              Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
              stattfindet.
            </p>
            <p>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse daran, seine Webangebote vor
              missbräuchlicher automatisierter Ausspähung und vor SPAM zu
              schützen. Sofern eine entsprechende Einwilligung abgefragt wurde,
              erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <p>
              Weitere Informationen zu Google reCAPTCHA entnehmen Sie den
              Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen
              unter folgenden Links:
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              und
              <a
                href="https://policies.google.com/terms?hl=de"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/terms?hl=de
              </a>
              .
            </p> */}
            <h2>8. eCommerce und Zahlungs­anbieter</h2>
            <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
            <p>
              Wir erheben, verarbeiten und nutzen personenbezogene Daten nur,
              soweit sie für die Begründung, inhaltliche Ausgestaltung oder
              Änderung des Rechtsverhältnisses erforderlich sind
              (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
              b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines
              Vertrags oder vorvertraglicher Maßnahmen gestattet.
              Personenbezogene Daten über die Inanspruchnahme dieser Website
              (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit
              dies erforderlich ist, um dem Nutzer die Inanspruchnahme des
              Dienstes zu ermöglichen oder abzurechnen.
            </p>
            <p>
              Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
              Beendigung der Geschäftsbeziehung gelöscht. Gesetzliche
              Aufbewahrungsfristen bleiben unberührt.
            </p>
            <h3>
              Daten­übermittlung bei Vertragsschluss für Online-Shops, Händler
              und Warenversand
            </h3>
            <p>
              Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn
              dies im Rahmen der Vertragsabwicklung notwendig ist, etwa an die
              mit der Lieferung der Ware betrauten Unternehmen oder das mit der
              Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende
              Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der
              Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer
              Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken
              der Werbung, erfolgt nicht.
            </p>
            <p>
              Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b
              DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags
              oder vorvertraglicher Maßnahmen gestattet.
            </p>
            <h3>
              Daten­übermittlung bei Vertragsschluss für Dienstleistungen und
              digitale Inhalte
            </h3>
            <p>
              Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn
              dies im Rahmen der Vertragsabwicklung notwendig ist, etwa an das
              mit der Zahlungsabwicklung beauftragte Kreditinstitut.
            </p>
            <p>
              Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur
              dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben.
              Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche
              Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
            </p>
            <p>
              Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b
              DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags
              oder vorvertraglicher Maßnahmen gestattet.
            </p>
            <h3>Zahlungsdienste</h3>
            <p>
              Wir binden Zahlungsdienste von Drittunternehmen auf unserer
              Website ein. Wenn Sie einen Kauf bei uns tätigen, werden Ihre
              Zahlungsdaten (z.B. Name, Zahlungssumme, Kontoverbindung,
              Kreditkartennummer) vom Zahlungsdienstleister zum Zwecke der
              Zahlungsabwicklung verarbeitet. Für diese Transaktionen gelten die
              jeweiligen Vertrags- und Datenschutzbestimmungen der jeweiligen
              Anbieter. Der Einsatz der Zahlungsdienstleister erfolgt auf
              Grundlage von Art. 6 Abs. 1 lit. b DSGVO (Vertragsabwicklung)
              sowie im Interesse eines möglichst reibungslosen, komfortablen und
              sicheren Zahlungsvorgangs (Art. 6 Abs. 1 lit. f DSGVO). Soweit für
              bestimmte Handlungen Ihre Einwilligung abgefragt wird, ist Art. 6
              Abs. 1 lit. a DSGVO Rechtsgrundlage der Datenverarbeitung;
              Einwilligungen sind jederzeit für die Zukunft widerrufbar.
            </p>
            <p>
              Folgende Zahlungsdienste / Zahlungsdienstleister setzen wir im
              Rahmen dieser Website ein:
            </p>
            <h4>Apple Pay</h4>
            <p>
              Anbieter des Zahlungsdienstes ist Apple Inc., Infinite Loop,
              Cupertino, CA 95014, USA. Die Datenschutzerklärung von Apple
              finden Sie unter:
              <a
                href="https://www.apple.com/legal/privacy/de-ww/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.apple.com/legal/privacy/de-ww/
              </a>
              .
            </p>
            <h4>Google Pay</h4>
            <p>
              Anbieter ist Google Ireland Limited, Gordon House, Barrow Street,
              Dublin 4, Irland. Die Datenschutzerklärung von Google finden Sie
              hier:
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy
              </a>
              .
            </p>
            <h4>Stripe</h4>
            <p>
              Anbieter für Kunden innerhalb der EU ist die Stripe Payments
              Europe, Ltd.,1 Grand Canal Street Lower, Grand Canal Dock, Dublin,
              Irland (im Folgenden „Stripe“).
            </p>
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              <a
                href="https://stripe.com/de/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://stripe.com/de/privacy
              </a>
              und
              <a
                href="https://stripe.com/de/guides/general-data-protection-regulation"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://stripe.com/de/guides/general-data-protection-regulation
              </a>
              .
            </p>
            <p>
              Details hierzu können Sie in der Datenschutzerklärung von Stripe
              unter folgendem Link nachlesen:
              <a
                href="https://stripe.com/de/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://stripe.com/de/privacy
              </a>
              .
            </p>
            <h4>Klarna</h4>
            <p>
              Anbieter ist die Klarna AB, Sveavägen 46, 111 34 Stockholm,
              Schweden (im Folgenden „Klarna“). Klarna bietet verschiedene
              Zahlungsoptionen an (z. B. Ratenkauf). Wenn Sie sich für die
              Bezahlung mit Klarna entscheiden (Klarna-Checkout-Lösung), wird
              Klarna verschiedene personenbezogene Daten von Ihnen erheben.
              Klarna nutzt Cookies, um die Verwendung der Klarna-Checkout-Lösung
              zu optimieren. Details zum Einsatz von Klarna-Cookies entnehmen
              Sie folgendem Link:
              <a
                href="https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf
              </a>
              .
            </p>
            <p>
              Details hierzu können Sie in der Datenschutzerklärung von Klarna
              unter folgendem Link nachlesen:
              <a
                href="https://www.klarna.com/de/datenschutz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.klarna.com/de/datenschutz/
              </a>
              .
            </p>
            <h4>Paydirekt</h4>
            <p>
              Anbieter dieses Zahlungsdienstes ist die Paydirekt GmbH, Hamburger
              Allee 26-28, 60486 Frankfurt am Main, Deutschland (im Folgenden
              „Paydirekt“). Wenn Sie die Bezahlung mittels Paydirekt ausführen,
              erhebt Paydirekt verschiedene Transaktionsdaten und leitet diese
              an die Bank weiter, bei der Sie mit Paydirekt registriert sind.
              Neben den für die Zahlung erforderlichen Daten erhebt Paydirekt im
              Rahmen der Transaktionsabwicklung ggf. weitere Daten wie z. B.
              Lieferadresse oder einzelne Positionen im Warenkorb. Paydirekt
              authentifiziert die Transaktion anschließend mit Hilfe des bei der
              Bank hierfür hinterlegten Authentifizierungsverfahrens.
              Anschließend wird der Zahlbetrag von Ihrem Konto auf unser Konto
              überwiesen. Weder wir noch Dritte haben Zugriff auf Ihre
              Kontodaten. Details zur Zahlung mit Paydirekt entnehmen Sie den
              AGB und den Datenschutzbestimmungen von Paydirekt unter:
              <a
                href="https://www.paydirekt.de/agb/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.paydirekt.de/agb/index.html
              </a>
              .
            </p>
            <h4>Sofort­überweisung</h4>
            <p>
              Anbieter dieses Zahlungsdienstes ist die Sofort GmbH,
              Theresienhöhe 12, 80339 München (im Folgenden „Sofort GmbH“). Mit
              Hilfe des Verfahrens „Sofortüberweisung“ erhalten wir in Echtzeit
              eine Zahlungsbestätigung von der Sofort GmbH und können
              unverzüglich mit der Erfüllung unserer Verbindlichkeiten beginnen.
              Wenn Sie sich für die Zahlungsart „Sofortüberweisung“ entschieden
              haben, übermitteln Sie die PIN und eine gültige TAN an die Sofort
              GmbH, mit der diese sich in Ihr Online-Banking-Konto einloggen
              kann. Sofort GmbH überprüft nach dem Einloggen automatisch Ihren
              Kontostand und führt die Überweisung an uns mit Hilfe der von
              Ihnen übermittelten TAN durch. Anschließend übermittelt sie uns
              unverzüglich eine Transaktionsbestätigung. Nach dem Einloggen
              werden außerdem Ihre Umsätze, der Kreditrahmen des Dispokredits
              und das Vorhandensein anderer Konten sowie deren Bestände
              automatisiert geprüft. Neben der PIN und der TAN werden auch die
              von Ihnen eingegebenen Zahlungsdaten sowie Daten zu Ihrer Person
              an die Sofort GmbH übermittelt. Bei den Daten zu Ihrer Person
              handelt es sich um Vor- und Nachnamen, Adresse, Telefonnummer(n),
              E-Mail-Adresse, IP-Adresse und ggf. weitere zur Zahlungsabwicklung
              erforderliche Daten. Die Übermittlung dieser Daten ist notwendig,
              um Ihre Identität zweifelsfrei zu festzustellen und
              Betrugsversuchen vorzubeugen. Details zur Zahlung mit
              Sofortüberweisung entnehmen Sie folgenden Links:
              <a
                href="https://www.sofort.de/datenschutz.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.sofort.de/datenschutz.html
              </a>
              und
              <a
                href="https://www.klarna.com/sofort/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.klarna.com/sofort/
              </a>
              .
            </p>
            <h2>9. Eigene Dienste</h2>
            <h3>Umgang mit Bewerberdaten</h3>
            <p>
              Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B.
              per E-Mail, postalisch oder via Online-Bewerberformular). Im
              Folgenden informieren wir Sie über Umfang, Zweck und Verwendung
              Ihrer im Rahmen des Bewerbungsprozesses erhobenen
              personenbezogenen Daten. Wir versichern, dass die Erhebung,
              Verarbeitung und Nutzung Ihrer Daten in Übereinstimmung mit
              geltendem Datenschutzrecht und allen weiteren gesetzlichen
              Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt
              werden.
            </p>
            <h4>Umfang und Zweck der Datenerhebung </h4>
            <p>
              Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre
              damit verbundenen personenbezogenen Daten (z. B. Kontakt- und
              Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
              Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
              Begründung eines Beschäftigungsverhältnisses erforderlich ist.
              Rechtsgrundlage hierfür ist § 26 BDSG-neu nach deutschem Recht
              (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit.
              b DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine
              Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die
              Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen
              Daten werden innerhalb unseres Unternehmens ausschließlich an
              Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung
              beteiligt sind.
            </p>
            <p>
              Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
              eingereichten Daten auf Grundlage von § 26 BDSG-neu und Art. 6
              Abs. 1 lit. b DSGVO zum Zwecke der Durchführung des
              Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen
              gespeichert.
            </p>
            <h4>Aufbewahrungsdauer der Daten</h4>
            <p>
              Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
              Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten
              wir uns das Recht vor, die von Ihnen übermittelten Daten auf
              Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f
              DSGVO) bis zu 6 Monate ab der Beendigung des Bewerbungsverfahrens
              (Ablehnung oder Zurückziehung der Bewerbung) bei uns
              aufzubewahren. Anschließend werden die Daten gelöscht und die
              physischen Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient
              insbesondere Nachweiszwecken im Falle eines Rechtsstreits. Sofern
              ersichtlich ist, dass die Daten nach Ablauf der 6-Monatsfrist
              erforderlich sein werden (z.B. aufgrund eines drohenden oder
              anhängigen Rechtsstreits), findet eine Löschung erst statt, wenn
              der Zweck für die weitergehende Aufbewahrung entfällt.
            </p>
            <p>
              Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
              entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt
              haben oder wenn gesetzliche Aufbewahrungspflichten der Löschung
              entgegenstehen.
            </p>
            <h4>Aufnahme in den Bewerber-Pool</h4>
            <p>
              Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die
              Möglichkeit, Sie in unseren Bewerber-Pool aufzunehmen. Im Falle
              der Aufnahme werden alle Dokumente und Angaben aus der Bewerbung
              in den Bewerber-Pool übernommen, um Sie im Falle von passenden
              Vakanzen zu kontaktieren.
            </p>
            <p>
              Die Aufnahme in den Bewerber-Pool geschieht ausschließlich auf
              Grundlage Ihrer ausdrücklichen Einwilligung (Art. 6 Abs. 1 lit. a
              DSGVO). Die Abgabe der Einwilligung ist freiwillig und steht in
              keinem Bezug zum laufenden Bewerbungsverfahren.
              <strong>
                Der Betroffene kann seine Einwilligung jederzeit widerrufen.
              </strong>
              In diesem Falle werden die Daten aus dem Bewerber-Pool
              unwiderruflich gelöscht, sofern keine gesetzlichen
              Aufbewahrungsgründe vorliegen.
            </p>
            <p>
              Die Daten aus dem Bewerber-Pool werden spätestens zwei Jahre nach
              Erteilung der Einwilligung unwiderruflich gelöscht.
            </p>
            <p>Quelle: e-recht24.de</p>
          </div>
        </Container>
      </Layout>
    );
  }
}
