import React from 'react';
import { BaseLayout } from 'components';

import { ShortBackground } from 'images';
import { Container, H1, isMobile, SvgWrapper, device } from 'utils';
import styled from '@emotion/styled';
import { LegitimationIcon, WorkTimeIcon, PaymentIcon } from 'images';
import CheckIcon from 'images/checkIcon.svg';
import { Helmet } from 'react-helmet';
import ContactModal from 'scenes/Project/components/ContactModal';

const FeesPageWrapper = styled.div`
  .intro {
    text-align: center;
    margin-bottom: 100px;
    margin-top: 100px;
    @media ${device.allMobile} {
      margin-bottom: 25px;
    }
    .icon {
      position: relative;
      left: -55px;
      margin-bottom: 50px;
      @media ${device.allMobile} {
        left: 0;
      }
    }
    p {
      font-size: 22px;
      margin: auto;
      @media ${device.allMobile} {
        font-size: 18px;
      }
      span {
        font-weight: bold;
      }
    }
    &__content {
      display: flex;
      align-items: center;
      margin: 50px auto;
      @media ${device.allMobile} {
        display: block;
      }
      &--icon {
        width: 40%;
        @media ${device.allMobile} {
          width: 100%;
          padding: 0 20px;
        }
      }
      &--content {
        width: 60%;
        text-align: left;
        @media ${device.allMobile} {
          width: 100%;
          padding: 0 20px;
        }
        &.payment {
          h2 {
            font-size: 22px;
          }
          p {
            text-align: left;
          }

          ul {
            padding: 0;
            list-style-image: url(${CheckIcon});
            li {
              font-size: 22px;
              margin: 15px 0;
            }
          }
        }
      }
    }
  }
`;
export default function LegitimationPage() {
  return (
    <div>
      <ContactModal />
      <Helmet>
        <meta
          name="description"
          content="Sichere Legitimation bei commonsplace. Erfahren, wie Identität und Projekte verifiziert werden, um Vertrauen und Sicherheit zu gewährleisten."
        />
      </Helmet>
      <BaseLayout
        maxHeightProps="500px"
        maxHeightMobileProps="250px"
        bannerComponent={
          <div style={{ textAlign: 'center' }}>
            <H1>Legitimation</H1>
          </div>
        }
        container={false}
        img={ShortBackground}
      >
        <Container>
          <FeesPageWrapper>
            <div className="intro">
              <h1>
                Unser engagiertes Team arbeitet täglich rund um die Uhr, um zu
                gewährleisten, dass du nur seriöse und vertrauenswürdige
                Projekte unterstützt.
              </h1>
              <div className="intro__content">
                <div className="intro__content--icon">
                  <SvgWrapper width={isMobile() ? '100%' : '90%'} height="auto">
                    <LegitimationIcon />
                  </SvgWrapper>
                </div>
                <div className="intro__content--content">
                  <p>
                    Damit du als Projektinitiator auf commonsplace deine
                    Unterstützungen ausgezahlt bekommst, sind wir gesetzlich
                    verpflichtet deine Identität zu überprüfen. Jeder
                    Projektinitiator - ganz gleich ob Unternehmen, Verein oder
                    Privatperson - wird von unserem Zahlungsdienstleister Stripe
                    verifiziert. Stripe übernimmt die gesamte Sorgfaltsprüfung
                    und Anmeldung des Projektinitiators bei der
                    Finanzaufsichtsbehörde gemäß Anti-Geldwäsche- und
                    Zahlungsaufsichtsgesetzen. Dadurch prüfen wir, dass kein
                    Projektinitiator auf Sanktionslisten, Betrugs- oder
                    Anti-Geldwäschelisten steht. Außerdem stellen wir durch die
                    Legitimation sicher, dass kein anderer in deinem Namen ein
                    Projekt startet.
                  </p>
                </div>
              </div>
              <div className="intro__content">
                <div className="intro__content--content">
                  <p>
                    Um dein Projekt erfolgreich zu registieren, musst du dich
                    legitimieren. Für die Legitimation deiner Person oder
                    Organisaton arbeiten wir mit unserem Dienstleister Stripe
                    zusammen. Stripe erfüllt strengste Anforderung in Bezug auf
                    den Datenschutz. Eine öffentliche Erklärung von Stripe in
                    Bezug auf Datenschutz findest du hier:{' '}
                    <a href="https://stripe.com/privacy-center/legal">
                      https://stripe.com/privacy-center/legal
                    </a>
                    . Zunächst hinterlegst du deine Daten im Formular auf
                    commonsplace, die auch Stripe erhält, um einen Account mit
                    deinen Zahlungsinformationen anlegen kann. Deine
                    persönlichen Daten werden durch eine anerkannte
                    SSL-Verschlüsselung an unseren Zahlungsdienstleister
                    übermittelt. Anschließend leiten wir dich auf die
                    Bedienungsoberfläche von Stripe weiter, dort wird dir
                    erklärt, was du tun musst. Sobald du alle erforderlichen
                    Dokumente eingereicht hast, bearbeitet und analysiert Stripe
                    deine Daten innerhalb von 24 Stunden. Wir benachrichtigen
                    dich über E-Mail, wenn du legitimiert wurdest. Derweil
                    kannst du alle übrigen erforderlichen Informationen deines
                    Projektes im Formular auf commonsplace vervollständigen.
                  </p>
                </div>
                <div className="intro__content--icon">
                  <SvgWrapper width={isMobile() ? '100%' : '80%'} height="auto">
                    <WorkTimeIcon />
                  </SvgWrapper>
                </div>
              </div>
              <h1>
                Stripe prüft alle notwendigen Unterlagen zum Nachweis des
                Projektzwecks. Folgende Unterlagen werden für die Legitimation
                benötigt:
              </h1>
              <div className="intro__content">
                <div className="intro__content--content payment">
                  <h2>Privatpersonen: </h2>
                  <ul>
                    <li>Personalausweis, Reisepass oder Führerschein</li>
                  </ul>
                  <h2>Organisationen:</h2>
                  <ul>
                    <li>Persönliche Daten des gesetzlichen Vertreters</li>
                    <li>
                      Personalausweis, Reisepass oder Führerschein des
                      gesetzlichen Vertreters
                    </li>
                    <li>Vereinsregisterauszug</li>
                    <li>Vereinssatzung</li>
                    <li>Vereinsregisternummer</li>
                  </ul>
                </div>
                <div className="intro__content--icon">
                  <SvgWrapper width="90%" height="auto">
                    <PaymentIcon />
                  </SvgWrapper>
                </div>
              </div>
            </div>
          </FeesPageWrapper>
        </Container>
      </BaseLayout>
    </div>
  );
}
