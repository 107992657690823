import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Header } from 'components';
import {
  Container,
  Colors,
  Modal,
  PrimaryButton,
  isMobile,
  device,
  H1,
  isTablet,
} from 'utils';
import {
  LeftSideBar,
  RightSideBar,
  MobileRightSideBar,
  AcceptEdit,
  RejectEdit,
  HoldenEdit,
  UpdatesForm,
  // Success,
  TimeLineTab,
  DonationsTable,
  SuccessfulPop,
  FinishedPop,
} from './components';
import { RewardsTable } from './components/RewardsTable';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { ProjectsActions } from 'redux/actions';
import ContactUsPage from 'scenes/ContactUsPage/ContactUsPage';
import { Contact } from 'images';
import ContactModal from 'scenes/Project/components/ContactModal';

const MyProjectDetailsWrapper = styled.section`
  display: flex;
  justify-content: space-between;

  .my-project-details__content {
    max-width: 1440px;
    margin-top: 50px;
    @media (max-width: 1441px) {
      max-width: 950px;
      padding: 50px 20px;
    }
    h1 {
      font-weight: bold;
      font-size: 35px;
    }
  }
  .action {
    text-align: right;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    @media ${device.allMobile} {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      button {
        min-width: 95%;
      }
    }
    button + button {
      margin-left: 15px;
      @media ${device.allMobile} {
        margin-left: 0;
      }
    }
    .link {
      padding: 0;
      a {
        padding: 15px 20px;
        display: block;
        &:hover {
          color: inherit;
        }
      }
    }
  }
  .show-more {
    text-align: center;
    button {
      display: inline-block;
      text-decoration: none;
      background: none;
      border: none;
      border-bottom: 2px solid ${Colors.transparent};
      margin: 20px auto 0;
      color: ${Colors.secondaryColor};
      &:hover {
        border-bottom: 2px solid ${Colors.secondaryColor};
      }
    }
  }
`;

const MyProjectDetailsModal = styled.div`
  .modal__content {
    min-width: 550px;
  }
  .modal__body {
    padding: 20px;
  }
`;
const ShowMenuWrapper = styled.div`
  text-align: center;
  margin: 25px 0 0;
`;
const GotoDiskTop = styled.div`
  display: none;
  @media ${device.allMobile} {
    display: block;
    margin: 150px 20px;
    text-align: center;
    border: 1px solid ${Colors.grey};
    border-radius: 15px;
    padding: 20px 10px;
    color: ${Colors.white};
    background: ${Colors.secondaryColor};
    h1 {
      color: ${Colors.white};
      font-weight: bold;
    }
  }
`;
class YourProjectDetailsPage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getProjectUpdates,
      getProjectStatus,
    } = this.props;
    getProjectStatus(id, {});

    getProjectUpdates(id);
  }
  static propTypes = {
    updates: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    getProjectUpdates: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    getProjectStatus: PropTypes.func.isRequired,
    projectStatus: PropTypes.object.isRequired,
    removeEdit: PropTypes.func.isRequired,
  };
  state = {
    showModal: false,
    showMore: true,
    perPage: 5,
    openMenu: false,
    showContact: false,
  };
  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };
  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };
  handleOpenMenu = () => {
    this.setState({ openMenu: true });
    document.body.style.overflowY = 'hidden';
  };

  handleCloseMenu = () => {
    this.setState({ openMenu: false });
    document.body.style.overflowY = 'unset';
  };

  render() {
    const { showModal, openMenu, showContact } = this.state;
    const {
      match: {
        params: { id },
      },
      updates,
      projectStatus,
      removeEdit,
      user,
    } = this.props;

    const handleStatus = (status) => {
      switch (status) {
        case 'accepted':
        case 'edit_pending':
        case 'edit_accepted':
        case 'edit_declined':
        case 'accepted_and_draft':
          return 'Online';
        case 'declined':
        case 'pending':
        case 'totally_declined':
        case 'successful':
        case 'finished':
          return 'Offline';
        default:
          return 'Offline';
      }
    };
    return (
      <>
        <div>
          <Header relativeHeader={true} />
          {isTablet() && (
            <ShowMenuWrapper>
              <PrimaryButton
                onClick={() => this.handleOpenMenu()}
                minWidth={'90%'}
              >
                Menü anzeigen
              </PrimaryButton>
            </ShowMenuWrapper>
          )}
          {isMobile() ? (
            <GotoDiskTop>
              <H1>INFO</H1>
              <p>
                Achtung! Einzigartige Ideen benötigen Präzision und eine
                ordentliche Bearbeitung. Bitte bearbeite dein Projekt deshalb am
                Desktoprechner.
              </p>
              <Link to="/profile">
                <PrimaryButton
                  onClick={() => this.handleOpenMenu()}
                  minWidth={'90%'}
                  primary={true}
                >
                  Zurück zum Profil
                </PrimaryButton>
              </Link>
            </GotoDiskTop>
          ) : (
            <>
              <MyProjectDetailsWrapper>
                {!isMobile() && !isTablet() && (
                  <LeftSideBar status={projectStatus.status} />
                )}
                <Container className="my-project-details__content">
                  <>
                    {projectStatus.status === 'finished' && <FinishedPop />}
                    {projectStatus.status === 'successful' && <SuccessfulPop />}
                    {projectStatus.status === 'edit_accepted' && (
                      <AcceptEdit
                        time={projectStatus.submit_date}
                        projectId={id}
                        removeEdit={removeEdit}
                      />
                    )}
                    {projectStatus.status === 'edit_declined' && (
                      <RejectEdit
                        message={projectStatus.message}
                        time={projectStatus.submit_date}
                        projectId={id}
                        removeEdit={removeEdit}
                      />
                    )}
                    {projectStatus.status === 'edit_pending' && (
                      <HoldenEdit time={projectStatus.submit_date} />
                    )}

                    <div className="action">
                      {handleStatus(projectStatus.status) === 'Online' && (
                        <Link to={`/project/${projectStatus.url}`}>
                          <PrimaryButton minWidth={'180px'}>
                            Projektseite
                          </PrimaryButton>
                        </Link>
                      )}
                      {(projectStatus.status === 'successful' ||
                        projectStatus.status === 'finished') && (
                        <Link to={`/project/${projectStatus.url}`}>
                          <PrimaryButton minWidth={'180px'}>
                            Projektseite
                          </PrimaryButton>
                        </Link>
                      )}
                      <div>
                        {(handleStatus(projectStatus.status) === 'Online' ||
                          projectStatus.status === 'successful' ||
                          projectStatus.status === 'finished') && (
                          <PrimaryButton
                            onClick={() => this.handelShowModal()}
                            minWidth={'180px'}
                          >
                            Neues Update
                          </PrimaryButton>
                        )}

                        {projectStatus.status !== 'totally_declined' &&
                          projectStatus.status !== 'successful' &&
                          projectStatus.status !== 'finished' && (
                            <PrimaryButton className="link" minWidth={'180px'}>
                              <Link to={`/start-project/${id}`}>
                                Projekt bearbeiten
                              </Link>
                            </PrimaryButton>
                          )}
                      </div>
                    </div>
                    <div
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <PrimaryButton
                        className="link"
                        minWidth={'180px'}
                        onClick={() => this.setState({ showContact: true })}
                      >
                        Support anfragen
                      </PrimaryButton>
                      {showContact && (
                        <Modal
                          width="830px"
                          title={
                            <div style={{ padding: 20 }}>
                              Kontaktiere uns, wir helfen gerne!
                            </div>
                          }
                          showModal={showContact}
                          handelCloseModal={() =>
                            this.setState({ showContact: false })
                          }
                        >
                          <ContactUsPage
                            user={user}
                            projectId={id}
                            formOnly
                            closeModal={() =>
                              this.setState({ showContact: false })
                            }
                          />
                        </Modal>
                      )}
                    </div>
                    {(handleStatus(projectStatus.status) === 'Online' ||
                      projectStatus.status === 'successful' ||
                      projectStatus.status === 'finished') && (
                      <DonationsTable projectId={id} />
                    )}

                    {/* Rewards */}
                    {(handleStatus(projectStatus.status) === 'Online' ||
                      projectStatus.status === 'successful' ||
                      projectStatus.status === 'finished') && (
                      <RewardsTable projectId={id} />
                    )}

                    {updates && updates.length > 0 && (
                      <TimeLineTab updates={updates} projectId={id} />
                    )}
                  </>
                </Container>
                {!isMobile() && !isTablet() && <RightSideBar />}
                {(isMobile() || isTablet()) && openMenu && (
                  <MobileRightSideBar handleCloseMenu={this.handleCloseMenu} />
                )}
              </MyProjectDetailsWrapper>
            </>
          )}
        </div>
        <MyProjectDetailsModal>
          <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
            <UpdatesForm
              projectId={id}
              handelCloseModal={this.handelCloseModal}
            />
          </Modal>
        </MyProjectDetailsModal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { updates, loading, errors, projectStatus } = state.projects;
  return {
    updates,
    loading,
    errors,
    projectStatus,
    user: state.authentication.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProjectUpdates: (projectId) =>
    dispatch(ProjectsActions.getProjectUpdates(projectId)),
  getProjectStatus: (projectId) =>
    dispatch(ProjectsActions.getProjectStatus(projectId)),
  removeEdit: (projectId) => dispatch(ProjectsActions.removeEdit(projectId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YourProjectDetailsPage);
