import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  Colors,
  SvgWrapper,
  numberWithCommas,
  device,
  dateFormat,
  PrimaryButton,
} from 'utils';
import { InfoIcon } from 'images';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import config from 'config';

const AboutProjectWrapper = styled.div`
  border-bottom: 2px solid ${Colors.secondaryColor};
  // padding: 20px;
  h2 {
    font-size: 25px;
    font-weight: bold;
    color: ${Colors.GreenColor};
    margin: 0;
    margin-bottom: 20px;
  }
  h3 {
    margin: 0;
    color: ${Colors.steel};
    font-size: 22px;
    line-height: 1;
    margin-bottom: 5px;
    font-weight: 400;
    @media ${device.allMobile} {
      font-size: 18px;
    }
  }
  p {
    margin: 0;
    font-size: 16px;
    text-align: left !important;
    color: ${Colors.black};
    line-height: 1;
    margin-bottom: 5px;
    margin-top: 20px;
    @media ${device.allMobile} {
      margin-top: 10px;
      text-align: start !important;
    }
    & + p {
      color: ${Colors.steel};
    }
  }
  .period {
    display: flex;
    align-items: center;

    div {
      text-align: center;
      &:nth-of-type(2) {
        padding: 0 10px;
      }
    }
  }
  .note {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
    }
  }
  & > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media ${device.allMobile} {
      display: block;
    }
    & > div {
      width: 100%;
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      @media ${device.allMobile} {
        display: block;
        width: 100%;
      }
      h3,
      p {
        width: 100%;
        @media ${device.allMobile} {
          width: 100%;
        }
      }
    }
  }
`;

const APP_URL = config.REACT_APP_URL;

export default function AboutProject(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const {
    project,
    isViewPage,
    handelShowModal,
    project: {
      funding_end_date,
      funding_start_date,
      city,
      goal,
      final_goal,
      is_ticketable,
      is_patreon,
    },
  } = props;

  const handleSetMetaTags = () => {
    if (!project) {
      return;
    }
    const { title, description, images } = project;
    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    const ogDescriptionTag = document.querySelector(
      'meta[property="og:description"]'
    );
    // const ogImageTag = document.querySelector('meta[property="og:image"]');
    // const ogImageTagSecure = document.querySelector('meta[property="og:image:secure_url"]');
    const projectUrl = document.querySelector('meta[property="og:url"]');
    if (ogTitleTag && title) {
      ogTitleTag.setAttribute('content', title);
    }
    if (ogDescriptionTag && description) {
      ogDescriptionTag.setAttribute('content', description);
    }
    if (projectUrl) {
      const project_url = `${APP_URL}project/${
        project?.url ? project?.url : ''
      }`;
      projectUrl.setAttribute('content', project_url);
    }
    // if (ogImageTag && ogImageTagSecure && images && images.length > 0) {
    //   const projectImageURL = `${images[0]}`;
    //   if (ogImageTag) {
    //     ogImageTag.setAttribute('content', projectImageURL);
    //   }
    //   if (ogImageTagSecure) {
    //     ogImageTagSecure.setAttribute('content', projectImageURL);
    //   }
    // }
  };

  return (
    <AboutProjectWrapper>
      {!is_ticketable && !is_patreon && (
        <>
          <h2>Eckdaten</h2>

          <Row>
            <Col lg={6}>
              <div>
                <h3>Finanzierungszeitraum</h3>
                <p>
                  {isViewPage
                    ? project.projectDescription &&
                      project.projectDescription.realization_start_date &&
                      project.projectDescription.realization_start_date
                        .length >= 0 &&
                      project.projectDescription.realization_start_date[0]
                      ? project.projectDescription.realization_start_date[0].format(
                          dateFormat
                        )
                      : '—/—/——'
                    : moment.unix(funding_start_date).format(dateFormat)}{' '}
                  -{' '}
                  {isViewPage
                    ? project.projectDescription &&
                      project.projectDescription.realization_start_date &&
                      project.projectDescription.realization_start_date
                        .length >= 1 &&
                      project.projectDescription.realization_start_date[1]
                      ? project.projectDescription.realization_start_date[1].format(
                          dateFormat
                        )
                      : '—/—/——'
                    : moment.unix(funding_end_date).format(dateFormat)}
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <h3>Ort</h3>
                <p>
                  {isViewPage
                    ? (project.basicInfo && project.basicInfo.city) ||
                      'your City'
                    : city}
                </p>
              </div>
            </Col>
            {project?.goal !== project?.final_goal && (
              <Col lg={6}>
                <div>
                  {project?.goal !== project?.final_goal && (
                    <div>
                      {project.final_goal && <h3>Nächstes Etappenziel</h3>}
                      {!project.final_goal && <h3>Fundingziel</h3>}
                      <p>
                        {isViewPage
                          ? (project.fundingTarget &&
                              project.fundingTarget.goal &&
                              numberWithCommas(project.fundingTarget.goal)) ||
                            0
                          : goal && numberWithCommas(goal)}{' '}
                        €
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            )}
            <Col lg={6}>
              <div>
                {project.final_goal && (
                  <div>
                    <h3>Fundingziel</h3>
                    <p>
                      {isViewPage
                        ? (project.fundingTarget &&
                            project.fundingTarget.final_goal &&
                            numberWithCommas(
                              project.fundingTarget.final_goal
                            )) ||
                          0
                        : final_goal && numberWithCommas(final_goal)}{' '}
                      €
                    </p>
                  </div>
                )}
              </div>
            </Col>

            {/* <Col lg={8}>
        <Support
              // projectUrl={url}
              projectId={project.id}
              projectStatus={project.status}
              donationsAverage={project.donations_average}
            />
        </Col> */}
          </Row>
        </>
      )}
      <Row>
        {!isViewPage && (
          <div className="btn_main project_details_btn">
            {!project.is_ticketable &&
              project?.status !== 'successful' &&
              project?.status !== 'finished' && (
                <>
                  <Link
                    to={
                      isViewPage
                        ? ''
                        : `/project/${project.id}/donation?donation=20&name=${project.title}`
                    }
                    style={{ width: '100%' }}
                  >
                    <PrimaryButton minWidth="48%" className="donation">
                      Unterstützen
                    </PrimaryButton>
                  </Link>
                </>
              )}

            <PrimaryButton
              onClick={() => {
                handelShowModal();
                handleSetMetaTags();
              }}
              minWidth={
                project?.status === 'successful' ||
                project?.status === 'finished'
                  ? '100%'
                  : '48%'
              }
            >
              {project.is_ticketable ? (
                <>
                  Erzähle deinen Freunden {windowWidth < 600 && <br />} von
                  diesem Event
                </>
              ) : (
                'Teilen'
              )}
            </PrimaryButton>
          </div>
        )}
        {project.project_type === 'partial' &&
          !is_ticketable &&
          !project.is_patreon && (
            <p
              className="note"
              style={{ marginBottom: '18px', marginTop: '-20px' }}
            >
              <SvgWrapper>
                <InfoIcon />
              </SvgWrapper>
              Die gesammelten Unterstützungen werden auch ausgezahlt, wenn das
              Fundingziel nicht erreicht wird.
            </p>
          )}
      </Row>
    </AboutProjectWrapper>
  );
}

AboutProject.propTypes = {
  project: PropTypes.object,
  isViewPage: PropTypes.bool.isRequired,
  handelShowModal: PropTypes.func,
};
