import React, { Component } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  Colors,
  SvgWrapper,
  H1,
  Container,
  Section,
  isMobile,
  device,
  isTablet,
  isMinLaptop,
  isLabTop,
  is4K,
  H2,
} from 'utils';
import { BaseLayout } from 'components';
import {
  ShortBackground,
  AboutUsFirst,
  AboutUsSecondary,
  AboutUsThread,
  AboutUsFour,
  BeforeBackground,
  AfterBackground,
} from 'images';
import {
  WhoAreWe,
  // Words
} from './components';
import SawBlack from './../../images/Black-sallallahu-alaihi-wasallam.png';
import { Helmet } from 'react-helmet';
import ContactModal from 'scenes/Project/components/ContactModal';

const AboutUsWrapper = styled.div``;
// const AboutUsIntro = styled.section`
//   text-align: center;
//   margin: auto;

//   p {
//     max-width: 80%;
//     margin: auto;
//     font-size: 22px;
//     position: relative;
//     @media ${device.allMobile} {
//       font-size: 16px;
//     }
//     &:after {
//       content: '';
//       width: 500px;
//       height: 4px;
//       display: inline-block;
//       background: ${Colors.secondaryColor};
//       position: absolute;
//       left: 50%;
//       transform: translateX(-50%);
//       bottom: -50px;
//       @media ${device.allMobile} {
//         width: 100px;
//       }
//     }
//     &.name {
//       margin-top: 66px;
//       &:after {
//         content: none;
//       }
//     }
//   }
// `;

const CommonsplaceWrapper = styled.section`
  text-align: center;
  padding: 0 37px;
  @media ${device.allMobile} {
    padding: 0 10px;
  }
  h1 {
    font-weight: bold;
    position: relative;
    font-size: 35px;
    margin-bottom: 110px;
    @media ${device.allMobile} {
      font-size: 25px;
      margin-bottom: 50px;
    }
    &:after {
      content: '';
      width: 500px;
      height: 4px;
      display: inline-block;
      background: ${Colors.secondaryColor};
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -25px;
      @media ${device.allMobile} {
        width: 100px;
      }
    }
  }
  & > div {
    display: flex;
    align-items: center;

    @media ${device.allMobile} {
      display: block;
    }
    & > div {
      width: 60%;
      text-align: left;
      @media ${device.allMobile} {
        width: 100%;
        text-align: center;
      }
      p {
        max-width: 90%;
        font-size: 22px;
        @media ${device.laptop} {
          font-size: 18px;
        }
        @media ${device.allMobile} {
          max-width: 100%;
          font-size: 16px;
          margin-top: 20px;
        }
      }
      &:first-of-type {
        width: 40%;
        text-align: center;
        @media ${device.allMobile} {
          width: 100%;
        }
        span {
          width: 100%;
          margin: auto;
          display: inline-block;
          border-radius: 15px;
          color: ${Colors.secondaryColor};
          display: flex;
          flex-direction: column;
          justify-content: center;
          @media ${device.allMobile} {
            width: 100%;
          }
        }
      }
    }
    &:first-of-type,
    &:nth-of-type(3) {
      & div:first-of-type {
        order: 2;
      }
    }
  }
  .next-down-arrow {
    margin-bottom: 50px;
  }
`;
const MuhammedQuot = styled.section`
  text-align: center;

  &.intro {
    p,
    q {
      font-size: 25px;
      position: relative;
      @media ${device.laptop} {
        font-size: 25px;
      }
      @media ${device.tablet}, ${device.minLaptop} {
        font-size: 22px;
      }
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
  p {
    font-size: 25px;
    margin: auto;
    margin-bottom: 130px;
    @media ${device.laptop} {
      font-size: 25px;
    }
    @media ${device.tablet}, ${device.minLaptop} {
      font-size: 22px;
    }
    @media ${device.allMobile} {
      margin-bottom: 20px;
      font-size: 22px;
      max-width: 90%;
    }
  }
  q {
    font-family: 'Shadows Into Light', cursive;
    display: block;
    position: relative;
    max-width: 70%;
    margin: 150px auto;
    margin-bottom: 100px;
    font-size: 30px;
    @media ${device.laptop} {
      font-size: 25px;
    }
    @media ${device.tablet}, ${device.minLaptop} {
      font-size: 22px;
    }
    @media ${device.allMobile} {
      margin: 50px auto;
      font-size: 20px;
      max-width: 100%;
    }
    &::before {
      content: '“';
      font-size: 340px;
      line-height: 1;
      color: ${Colors.secondaryColor};
      position: absolute;
      top: -145px;
      left: -100px;
      @media ${device.allMobile} {
        font-size: 50px;
        top: -20px;
        left: -10px;
      }
    }
    &::after {
      content: '„';
      font-size: 340px;
      line-height: 1;
      position: absolute;
      color: ${Colors.secondaryColor};
      bottom: -94px;
      right: -71px;
      @media ${device.allMobile} {
        font-size: 50px;
        bottom: -20px;
        right: -10px;
      }
    }
    & + p {
      position: relative;
      &:after {
        content: '';
        width: 300px;
        height: 4px;
        display: inline-block;
        background: ${Colors.secondaryColor};
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -27px;
        @media ${device.allMobile} {
          width: 100px;
          top: -25px;
        }
      }
    }
  }
  span {
    font-size: 150px;
    color: ${Colors.secondaryColor};
    font-weight: bold;
    @media ${device.allMobile} {
      font-size: 70px;
    }
  }
  &.aya {
    q::before {
      left: 62px;
    }
    q::after {
      right: 59px;
    }
  }
`;
const WerAndWas = styled.section`
  text-align: center;
  p {
    font-size: 22px;
    @media ${device.allMobile} {
      font-size: 16px;
    }
  }
`;
const Intro = styled.div`
  text-align: center;
  h1 {
    font-size: 55px;
    @media ${device.allMobile} {
      font-size: 30px;
    }
  }
`;

const HowAreYou = styled.div`
  position: relative;
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }

  .after-background {
    position: relative;
    margin-top: -5px;
  }
`;
export default class AboutUsPage extends Component {
  componentDidMount() {
    const AboutUsFour = document.getElementById('freepik_stories-good-team');
    const AboutUsThread = document.getElementById(
      'freepik_stories-about-us-page'
    );
    const AboutUsSecondary = document.getElementById(
      'freepik_stories-team-work'
    );

    window.addEventListener('scroll', function () {
      if (isMobile()) {
        if (window.scrollY >= 1060 && window.scrollY <= 8348) {
          AboutUsFour.classList.add('animated');
        } else {
          AboutUsFour.classList.remove('animated');
        }
      } else if (isTablet()) {
        if (window.scrollY >= 1643 && window.scrollY <= 8348) {
          AboutUsFour.classList.add('animated');
        } else {
          AboutUsFour.classList.remove('animated');
        }
      } else if (isMinLaptop()) {
        if (window.scrollY >= 1537 && window.scrollY <= 8348) {
          AboutUsFour.classList.add('animated');
        } else {
          AboutUsFour.classList.remove('animated');
        }
      } else if (isLabTop()) {
        if (window.scrollY >= 1590 && window.scrollY <= 8348) {
          AboutUsFour.classList.add('animated');
        } else {
          AboutUsFour.classList.remove('animated');
        }
      } else if (is4K()) {
        if (window.scrollY >= 2256 && window.scrollY <= 8348) {
          AboutUsFour.classList.add('animated');
        } else {
          AboutUsFour.classList.remove('animated');
        }
      } else {
        if (window.scrollY >= 1961 && window.scrollY <= 6784) {
          AboutUsFour.classList.add('animated');
        } else {
          AboutUsFour.classList.remove('animated');
        }
      }
      if (isMobile()) {
        if (window.scrollY >= 1749 && window.scrollY <= 8348) {
          AboutUsThread.classList.add('animated');
        } else {
          AboutUsThread.classList.remove('animated');
        }
      } else if (isTablet()) {
        if (window.scrollY >= 2014 && window.scrollY <= 8348) {
          AboutUsThread.classList.add('animated');
        } else {
          AboutUsThread.classList.remove('animated');
        }
      } else if (isMinLaptop()) {
        if (window.scrollY >= 1749 && window.scrollY <= 8348) {
          AboutUsThread.classList.add('animated');
        } else {
          AboutUsThread.classList.remove('animated');
        }
      } else if (isLabTop()) {
        if (window.scrollY >= 2014 && window.scrollY <= 8348) {
          AboutUsThread.classList.add('animated');
        } else {
          AboutUsThread.classList.remove('animated');
        }
      } else if (is4K()) {
        if (window.scrollY >= 2627 && window.scrollY <= 8348) {
          AboutUsThread.classList.add('animated');
        } else {
          AboutUsThread.classList.remove('animated');
        }
      } else {
        if (window.scrollY >= 2279 && window.scrollY <= 6784) {
          AboutUsThread.classList.add('animated');
        } else {
          AboutUsThread.classList.remove('animated');
        }
      }
      if (isMobile()) {
        if (window.scrollY >= 6254 && window.scrollY <= 8348) {
          AboutUsSecondary.classList.add('animated');
        } else {
          AboutUsSecondary.classList.remove('animated');
        }
      } else if (isTablet()) {
        if (window.scrollY >= 5141 && window.scrollY <= 8348) {
          AboutUsSecondary.classList.add('animated');
        } else {
          AboutUsSecondary.classList.remove('animated');
        }
      } else if (isMinLaptop()) {
        if (window.scrollY >= 4558 && window.scrollY <= 8348) {
          AboutUsSecondary.classList.add('animated');
        } else {
          AboutUsSecondary.classList.remove('animated');
        }
      } else if (isLabTop()) {
        if (window.scrollY >= 4929 && window.scrollY <= 8348) {
          AboutUsSecondary.classList.add('animated');
        } else {
          AboutUsSecondary.classList.remove('animated');
        }
      } else if (is4K()) {
        if (window.scrollY >= 6072 && window.scrollY <= 8348) {
          AboutUsSecondary.classList.add('animated');
        } else {
          AboutUsSecondary.classList.remove('animated');
        }
      } else {
        if (window.scrollY >= 5459 && window.scrollY <= 6784) {
          AboutUsSecondary.classList.add('animated');
        } else {
          AboutUsSecondary.classList.remove('animated');
        }
      }
    });
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div>
        <ContactModal />
        <Helmet>
          {/* <title>About Us | Commonsplace</title>
          <meta charSet="utf-8" />
          <meta
            property="og:title"
            data-react-helmet="true"
            content="About Us | Commonsplace"
          /> */}
          {/* <meta
            data-react-helmet="true"
            name="description"
            content="Discover Commonsplace – Our mission, engagement, and team. Learn more about our platform and what we offer."
          /> */}
          <meta
            property="og:url"
            content="https://testing.commonsplace.de/about-us"
          />
          <link
            rel="canonical"
            href="https://testing.commonsplace.de/about-us"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="1200" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <AboutUsWrapper>
          <BaseLayout
            maxHeightProps="500px"
            maxHeightMobileProps="250px"
            bannerComponent={
              <Intro>
                <h1>Über uns</h1>
              </Intro>
            }
            container={false}
            img={ShortBackground}
          >
            <>
              <>
                <Section>
                  <Container>
                    <MuhammedQuot className="intro">
                      <p>
                        <q className="txt">
                          Was bin ich denn selbst, was habe ich geleistet?
                          Alles, was ich gesehen habe, gehört und beobachtet,
                          habe ich gesammelt und ausgenutzt. Meine Werke sind
                          von unzähligen verschiedenen Individuen genährt
                          worden, von Ignoranten und Weisen, Leuten von Geist
                          und Dummköpfen; die Kindheit, das reife Alter, das
                          Greisenalter, alle haben mir ihre Gedanken
                          entgegengebracht, ihre Fähigkeiten, Hoffnungen und
                          Lebensansichten, ich habe oft geerntet, was andere
                          gesäet haben. Mein Werk ist das eines Kollektivwesens,
                          das den Namen Goethe trägt.
                        </q>
                      </p>

                      <p className="name">J. W. Goethe</p>
                    </MuhammedQuot>
                  </Container>
                </Section>
                <HowAreYou>
                  <div className="background">
                    <SvgWrapper width="100%" height="100%">
                      <BeforeBackground />
                    </SvgWrapper>
                  </div>
                  <div className="container">
                    <Container>
                      <Section>
                        <WerAndWas>
                          <H2>Wer sind wir?</H2>
                          <p>
                            Diese Plattform haben fünf engagierte junge Menschen
                            initiiert, die davon überzeugt sind, dass Muslime
                            mit ihrer wertvollen und reichen Tradition einen
                            großen Mehrwert für die hiesige Gesamtgesellschaft
                            darstellen. Für uns ist schon lange klar: Die 5
                            Millionen Personen starke deutsch-muslimische
                            Zivilgesellschaft ist vielfältig und mittlerweile in
                            unterschiedlichsten Bereichen aktiv. Jedoch: Trotz
                            vieler Ideen können einige muslimische
                            Organisationen, Institutionen und Einzelpersonen in
                            Deutschland aufgrund fehlender professioneller und
                            finanzieller Infrastruktur ihr Potential nicht
                            vollkommen ausschöpfen. Daher streben wir durch den
                            Geist der Gemeinschaft, der gegenseitigen Fürsorge
                            und einer bundesweit gut vernetzten muslimischen
                            crowdfunding-Plattform eine selbstständige und
                            nachhaltige Entwicklung für die Muslime in
                            Deutschland an.
                          </p>
                        </WerAndWas>
                      </Section>
                    </Container>
                  </div>
                  <div className="after-background">
                    <SvgWrapper width="100%" height="100%">
                      <AfterBackground />
                    </SvgWrapper>
                  </div>
                </HowAreYou>
                <Section>
                  <Container>
                    <CommonsplaceWrapper>
                      <div>
                        <div>
                          <SvgWrapper
                            width={
                              isMobile() ? '100%' : isTablet() ? '100%' : '70%'
                            }
                            height="auto"
                          >
                            <AboutUsFour />
                          </SvgWrapper>
                        </div>
                        <div>
                          <p style={{ paddingTop: '25px' }}>
                            Mit der ersten deutsch-muslimischen
                            crowdfunding-Plattform geben wir kreativen und
                            engagierten Muslimen einen digitalen und
                            kooperativen Raum, um großartige Ideen in der
                            Community miteinander zu teilen und zu finanzieren.
                            So haben Impulse die Möglichkeit, sich zu entfalten,
                            zu entwickeln und zu einem Projektziel geformt zu
                            werden. Mit commonsplace nutzen wir eine gemeinsame,
                            überregionale Infrastruktur: Die deutsch-muslimische
                            Community wird zusammengeführt, um interessante
                            Projekte im Kollektiv zu finanzieren, die
                            bestenfalls gemeinschaftliche und soziale Vorteile
                            schaffen. Dabei ist jede Idee willkommen: Kultur,
                            Bildung, Entrepreneurship usw..
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <SvgWrapper width="100%" height="auto">
                            <AboutUsThread />
                          </SvgWrapper>
                        </div>
                        <div>
                          <p style={{ paddingTop: '20px' }}>
                            Muslimische Organisationen und Personen sind vom
                            Markt und öffentlichen Fördermittel weniger
                            abhängig. Dadurch sind sie in ihrer Entscheidung
                            freier. commonsplace basiert auf der Idee der
                            Selbstorganisation innerhalb der Community.
                            Gemeinsame Absichten und Werte sind das Herzblut
                            unserer Plattform. Dadurch wachsen Zusammenhalt,
                            Lebendigkeit und soziale Strukturen. Das Bewusstsein
                            einer sorgenden, selbstbestimmten und
                            selbstorganisierten Gemeinschaft, die einander
                            unterstützt und Herausforderungen gemeinschaftlich
                            löst, soll entstehen. Sie bewirtschaftet gemeinsame
                            Vermögenswerte und regelt ihre Angelegenheiten
                            selbst.
                          </p>
                        </div>
                      </div>
                    </CommonsplaceWrapper>
                  </Container>
                </Section>
              </>

              <Section>
                <WhoAreWe />
              </Section>
              <Container>
                <>
                  <Section>
                    <CommonsplaceWrapper>
                      <H2>Woran wir glauben</H2>
                      <MuhammedQuot>
                        <p>
                          Der Prophet des Islam Muhammed{' '}
                          <img
                            className="image-class2"
                            src={SawBlack}
                            alt="صلى الله عليه وسلم"
                          ></img>{' '}
                          hat die Wichtigkeit der Gemeinschaft hervorgehoben,
                          indem er sagte:
                        </p>
                        <q className="txt">
                          Das Gleichnis der Gläubigen, was ihre Liebe,
                          Barmherzigkeit und Zuneigung untereinander angeht, ist
                          wie das eines einzigen Körpers: wenn eines seiner
                          Glieder schmerzt, reagieren die anderen mit
                          Schlaflosigkeit und Fieber
                        </q>
                        <p></p>
                      </MuhammedQuot>
                      <div>
                        <div>
                          <SvgWrapper
                            width={
                              isMobile() ? '100%' : isTablet() ? '100%' : '90%'
                            }
                            height="auto"
                          >
                            <AboutUsFirst />
                          </SvgWrapper>
                        </div>
                        <div>
                          <p>
                            Wir sind alle voneinander abhängig: Jeder Einzelne
                            von uns braucht den Anderen, um zu (über)leben. Dies
                            drückt sich sehr gut in einem alten afrikanischen
                            Spruch aus, der besagt “Es braucht ein Dorf, um ein
                            Kind großzuziehen.“ Gleichwohl entsteht das große
                            Bild durch all seine einzelnen Puzzleteile –
                            Gemeinschaft. Die Gemeinschaft kann nur durch das
                            Wirken und die Zusammenarbeit eines jeden
                            Individuums entstehen und erhalten bleiben. Das ist
                            die Essenz unseres Tuns.
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <SvgWrapper
                            width={
                              isMobile() ? '100%' : isTablet() ? '100%' : '80%'
                            }
                            height="auto"
                          >
                            <AboutUsSecondary />
                          </SvgWrapper>
                        </div>
                        <div>
                          <p style={{ marginTop: '30px' }}>
                            Die Zusammenschließung der Gemeinschaft, die an
                            einem Strang zieht, die sich bei Projekten
                            unterstützt, sich selbstbestimmt und gemeinsam
                            verwaltet, nennt man Commons. Jeder Einzelne besitzt
                            ein enormes, gottgegebenes Potenzial um die
                            Gemeinschaft zu bereichern. Wir erfahren Sinn &amp;
                            Fürsorge, wenn wir uns gegenseitig als
                            Persönlichkeiten und einen als Gemeinschaft
                            erkennen, fern von Rivalitäten.
                          </p>
                        </div>
                      </div>
                    </CommonsplaceWrapper>
                  </Section>
                  {/* <MuhammedQuot>
              <p>
                Der Prophet Muhammed (Friede und Segen auf ihm) hat die
                Wichtigkeit der Gemeinschaft hervorgehoben, indem er sagte:
              </p>
              <q>
                Die Parabel der Gläubigen was ihre Liebe, Barmherzigkeit und
                Zuneigung untereinander angeht, ist wie die eines Körpers: wenn
                ein Glied schmerzt, reagieren die anderen mit Schlaflosigkeit
                und Fieber
              </q>
              <p>Sahih Muslim</p>
              <span>&amp;</span>
            </MuhammedQuot> */}

                  {/* <MuhammedQuot>
              <q>
                Alltägliches soziales Miteinander, bewusste Selbstorganisation
                durch die Gemeinschaft und sorgendes &amp; selbstbestimmtes
                Wirtschaften
              </q>
            </MuhammedQuot> */}
                </>
              </Container>
              {/* <Words /> */}
            </>
          </BaseLayout>
        </AboutUsWrapper>
      </div>
    );
  }
}
