import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from '@emotion/styled';
import { Card } from 'components';
import Colors from 'utils/Theme';
import { Spin } from 'antd';
import isMobile from 'utils/isMobile';
import { Link } from 'react-router-dom';

const ArrowButton = styled.button`
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: ${Colors.white};
  --tw-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.1);
  --tw-shadow-color: rgba(
    0,
    0,
    0,
    0.2
  ); /* Replace with your desired shadow color */
  --tw-shadow-colored: 0px 1px 2px var(--tw-shadow-color),
    0px 4px 10px var(--tw-shadow-color);

  box-shadow: var(--tw-shadow);
`;

const Heading = styled.h2`
  text-align: left;
  font-size: 30px;
  color: #95bd51;
  margin: 0;
`;

const ShowMore = styled.button`
  font-weight: bold;
  background: rgb(149, 189, 81);
  border: 1px solid rgb(149, 189, 81);
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 20px;
  padding: 15px 20px;
  min-width: 200px;
  font-size: 22px;
`;

export const SlickSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1540,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 1040,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        centerPadding: 10,
        swipe: true,
      },
    },
  ],
};

const AppSlider = ({
  isSuccessful,
  projects,
  heading,
  showMoreHandle,
  isShowMore,
  isLoading,
  isMain,
}) => {
  const sliderRef = useRef();
  if ((!projects || !projects.length) && isLoading)
    return <Spin spinning={isLoading} />;
  if (!projects || !projects.length)
    return (
      <div>
        <h1 style={{ marginBottom: 20, marginTop: 40 }}>{heading}</h1>
        <div>No project found</div>
      </div>
    );
  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile() ? 'column' : 'row',
          gap: 8,
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 40,
          marginBottom: 10,
          paddingRight: isMobile() ? 0 : 10,
        }}
      >
        <Heading>{heading}</Heading>

        <div
          style={{
            display: 'flex',
            gap: 8,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!isMobile() && (
            <>
              <ArrowButton onClick={() => sliderRef.current?.slickPrev()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  class="w-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </ArrowButton>
              <ArrowButton onClick={() => sliderRef.current?.slickNext()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                  data-slot="icon"
                  class="w-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </ArrowButton>
            </>
          )}
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <Slider
          {...SlickSettings}
          className="carousel-container big-projects-slider"
          ref={sliderRef}
          speed={500}
          arrows={false}
        >
          {projects.map((project, index) => (
            <div
              style={{ height: '100%' }}
              key={`${JSON.stringify(project)}-${index}`}
            >
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  padding: 10,
                }}
              >
                <Card isSuccessful={isSuccessful} project={project} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
        {isShowMore && (
          <>
            {isMain ? (
              <Link to="/projects">
                <ShowMore>Mehr anzeigen</ShowMore>
              </Link>
            ) : (
              <ShowMore onClick={showMoreHandle}>Mehr anzeigen</ShowMore>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default AppSlider;
