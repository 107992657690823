import { adminServices } from 'services';
import { AlertActions } from 'redux/actions';
import adminConstants from './AdminTypes';

const login = ({ email, password }) => {
  return (dispatch) => {
    dispatch(loginRequest({ email }));
    adminServices
      .adminLogin({ email, password })
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(res.user.data));
        localStorage.setItem('user-type', 'admin');
        dispatch(
          AlertActions.success(
            `Welcome ${res.user.data.first_name} ${res.user.data.last_name}`,
            'Welcome'
          )
        );
        return dispatch(loginSuccess(res.user.data));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(loginFailure(error.errors));
      });
  };
};

const loginRequest = (user) => ({ type: adminConstants.LOGIN_REQUEST, user });
const loginSuccess = (user) => ({ type: adminConstants.LOGIN_SUCCESS, user });
const loginFailure = (error) => ({
  type: adminConstants.LOGIN_FAILURE,
  error,
});

const setHomeProjects = (projectsIdsArray) => {
  return (dispatch) => {
    dispatch(setHomeProjectsRequest());
    adminServices
      .setHomeProjects(projectsIdsArray)
      .then((res) => {
        AlertActions.success('Projects updated successfully!', 'Success');
        return dispatch(setHomeProjectsSuccess(res.projects));
      })
      .catch((error) => {
        AlertActions.error('Projects updation failed!', 'Fail');
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(setHomeProjectsFailure(error.errors));
      });
  };
};

const setHomeProjectsRequest = () => ({
  type: adminConstants.SET_PROJECTS_HOME_REQUEST,
});
const setHomeProjectsSuccess = (projects) => ({
  type: adminConstants.SET_PROJECTS_HOME_SUCCESS,
  projects,
});
const setHomeProjectsFailure = (error) => ({
  type: adminConstants.SET_PROJECTS_HOME_FAILURE,
  error,
});

const getAllProjects = (perPage, page, status, filter) => {
  return (dispatch) => {
    dispatch(getAllProjectsRequest());
    adminServices
      .getAllProjects(perPage, page, status, filter)
      .then((res) => {
        return dispatch(getAllProjectsSuccess(res));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(getAllProjectsFailure(error.errors));
      });
  };
};

const getAllProjectsRequest = () => ({
  type: adminConstants.GET_ALL_PROJECTS_REQUEST,
});
const getAllProjectsSuccess = (projects) => ({
  type: adminConstants.GET_ALL_PROJECTS_SUCCESS,
  projects,
});
const getAllProjectsFailure = (error) => ({
  type: adminConstants.GET_ALL_PROJECTS_FAILURE,
  error,
});

const getAllSelectedProjects = () => {
  return (dispatch) => {
    dispatch(getAllSelectedProjectsRequest());
    adminServices
      .getAllSelectedProjects()
      .then((res) => {
        return dispatch(getAllSelectedProjectsSuccess(res));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(getAllSelectedProjectsFailure(error.errors));
      });
  };
};

const getAllSelectedProjectsRequest = () => ({
  type: adminConstants.GET_ALL_SELECTED_PROJECTS_REQUEST,
});
const getAllSelectedProjectsSuccess = (projects) => ({
  type: adminConstants.GET_ALL_SELECTED_PROJECTS_SUCCESS,
  projects,
});
const getAllSelectedProjectsFailure = (error) => ({
  type: adminConstants.GET_ALL_SELECTED_PROJECTS_FAILURE,
  error,
});

const getAllInvoices = (perPage, page, status) => {
  return (dispatch) => {
    dispatch(getAllInvoicesRequest());
    adminServices
      .getAllProjects(perPage, page, status)
      .then((res) => {
        return dispatch(getAllInvoicesSuccess(res));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(getAllInvoicesFailure(error.errors));
      });
  };
};

const getAllInvoicesRequest = () => ({
  type: adminConstants.GET_ALL_INVOICES_REQUEST,
});
const getAllInvoicesSuccess = (invoices) => ({
  type: adminConstants.GET_ALL_INVOICES_SUCCESS,
  invoices,
});
const getAllInvoicesFailure = (error) => ({
  type: adminConstants.GET_ALL_INVOICES_FAILURE,
  error,
});

const showProjectById = (id) => {
  return (dispatch) => {
    dispatch(showProjectByIdRequest());
    adminServices
      .showProjectById(id)
      .then((res) => {
        return dispatch(showProjectByIdSuccess(res.project));
      })
      .catch((error) => {
        console.log('ERR: ', error);
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(showProjectByIdFailure(error.errors));
      });
  };
};

const updateProjectById = (id, values) => {
  return (dispatch) => {
    dispatch(updateProjectByIdRequest());
    adminServices
      .updateProjectbyId(id, values)
      .then((res) => {
        return dispatch(updateProjectByIdSuccess(res.project));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(updateProjectByIdFailure(error.errors));
      });
  };
};

const updateProjectByIdRequest = () => ({
  type: adminConstants.UPDATE_PROJECT_REQUEST,
});

const updateProjectByIdSuccess = (project) => ({
  type: adminConstants.UPDATE_PROJECT_SUCCESS,
  project,
});

const updateProjectByIdFailure = (error) => ({
  type: adminConstants.UPDATE_PROJECT_FAILURE,
  error,
});

const startProjectById = (id, values) => {
  return (dispatch) => {
    dispatch(startProjectByIdRequest());
    adminServices
      .startProjectById(id, values)
      .then((res) => {
        return dispatch(startProjectByIdSuccess(res.project));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(startProjectByIdFailure(error.errors));
      });
  };
};

const startProjectByIdRequest = () => ({
  type: adminConstants.START_PROJECT_REQUEST,
});

const startProjectByIdSuccess = (project) => ({
  type: adminConstants.START_PROJECT_SUCCESS,
  project,
});

const startProjectByIdFailure = (error) => ({
  type: adminConstants.START_PROJECT_FAILURE,
  error,
});

const showProjectByIdRequest = () => ({
  type: adminConstants.SHOW_PROJECT_REQUEST,
});
const showProjectByIdSuccess = (project) => ({
  type: adminConstants.SHOW_PROJECT_SUCCESS,
  project,
});
const showProjectByIdFailure = (error) => ({
  type: adminConstants.SHOW_PROJECT_FAILURE,
  error,
});

const acceptProject = (id) => {
  return (dispatch) => {
    dispatch(acceptProjectRequest());
    adminServices
      .acceptProject(id)
      .then((res) => {
        dispatch(AlertActions.success(`Project Accepted`, 'Danke!'));
        return dispatch(acceptProjectSuccess(res.project));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(acceptProjectFailure(error.errors));
      });
  };
};

const acceptProjectRequest = () => ({
  type: adminConstants.ACCEPT_PROJECT_REQUEST,
});
const acceptProjectSuccess = (project) => ({
  type: adminConstants.ACCEPT_PROJECT_SUCCESS,
  project,
});
const acceptProjectFailure = (error) => ({
  type: adminConstants.ACCEPT_PROJECT_FAILURE,
  error,
});

const declineProject = (id, massage) => {
  return (dispatch) => {
    dispatch(declineProjectRequest());
    adminServices
      .declineProject(id, massage)
      .then((res) => {
        dispatch(AlertActions.success(`Project Declined`, 'Danke!'));
        return dispatch(declineProjectSuccess(res.project));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(declineProjectFailure(error.errors));
      });
  };
};

const declineProjectRequest = () => ({
  type: adminConstants.DECLINE_PROJECT_REQUEST,
});
const declineProjectSuccess = (project) => ({
  type: adminConstants.DECLINE_PROJECT_SUCCESS,
  project,
});
const declineProjectFailure = (error) => ({
  type: adminConstants.DECLINE_PROJECT_FAILURE,
  error,
});

const logout = () => {
  return (dispatch) => {
    adminServices
      .logout()
      .then((res) => {
        return dispatch(logoutSuccess(res.user));
      })
      .catch((error) => {
        return error;
      });
  };
};

const getAllBookGoodies = () => {
  return (dispatch) => {
    dispatch(getAllBookGoodiesRequest());
    adminServices
      .getAllBookedGoodies()
      .then((res) => {
        return dispatch(getAllBookGoodiesSuccess(res));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(getAllBookGoodiesFailure(error.errors));
      });
  };
};

const getAllBookGoodiesRequest = () => ({
  type: adminConstants.GET_ALL_BOOK_GOODIES_REQUEST,
});
const getAllBookGoodiesSuccess = (goodies) => ({
  type: adminConstants.GET_ALL_BOOK_GOODIES_SUCCESS,
  goodies,
});
const getAllBookGoodiesFailure = (error) => ({
  type: adminConstants.GET_ALL_BOOK_GOODIES_FAILURE,
  error,
});

const logoutSuccess = (user) => ({ type: adminConstants.LOGOUT, user });

// ADMIN RECURRING PAYMENTS
const getAdminRecurringPayments = () => {
  return (dispatch) => {
    dispatch(getAdminRecurringPaymentsRequest());
    adminServices
      .getAdminRecurringPayments()
      .then((res) => {
        dispatch(getAdminRecurringPaymentsSuccess(res.donations));
      })
      .catch((error) => {
        error.errors.full_messages
          ? error.errors.full_messages.map((err) =>
              dispatch(AlertActions.error(err))
            )
          : error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(getAdminRecurringPaymentsFailure(error.errors));
      });
  };
};
const getAdminRecurringPaymentsRequest = () => ({
  type: adminConstants.GET_ADMIN_RECURRING_DONATIONS_REQUEST,
});
const getAdminRecurringPaymentsSuccess = (donations) => ({
  type: adminConstants.ADMIN_RECURRING_DONATIONS_SUCCESS,
  donations,
});
const getAdminRecurringPaymentsFailure = (error) => ({
  type: adminConstants.ADMIN_RECURRING_DONATIONS_FAILURE,
  error,
});

// ADMIN CANCEL RECURRING PAYMENTS
const AdmincancelRecurringPayment = (id) => {
  return (dispatch) => {
    dispatch(adminCancelRecurringPaymentRequest());
    adminServices
      .adminRecurringPaymentsCanceled(id)
      .then((res) => {
        dispatch(AlertActions.success('Ihre Spende wurde storniert!'));
        dispatch(adminCancelRecurringPaymentSuccess(res));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        return dispatch(adminCancelRecurringPaymentFailure(error.errors));
      });
  };
};

const adminCancelRecurringPaymentRequest = () => ({
  type: adminConstants.ADMIN_RECURRING_DONATIONS_CANCEL_REQUEST,
});
const adminCancelRecurringPaymentSuccess = (id) => ({
  type: adminConstants.ADMIN_RECURRING_DONATIONS_CANCEL_SUCCESS,
  id,
});
const adminCancelRecurringPaymentFailure = (error) => ({
  type: adminConstants.ADMIN_RECURRING_DONATIONS_CANCEL_FAILURE,
  error,
});

const hideProject = (body, massage) => {
  return (dispatch) => {
    dispatch(declineProjectRequest());
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ body }),
      redirect: 'follow',
    };
    return fetch('admin/hide_project', requestOptions)
      .then(({ project }) => {
        dispatch(
          AlertActions.success(
            `Project ${body.visible ? 'Unhide' : 'Hide'}`,
            'Danke!'
          )
        );
        return dispatch(declineProjectSuccess(project));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(declineProjectFailure(error.errors));
      });
  };
};

const closeJob = (body, massage) => {
  return (dispatch) => {
    dispatch(declineProjectRequest());
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body ),
      redirect: 'follow',
    };
    return fetch('close_auto_funding', requestOptions)
      .then(({ project }) => {
        dispatch(
          AlertActions.success(
            'Danke!'
          )
        );
        return dispatch(declineProjectSuccess(project));
      })
      .catch((error) => {
        error.errors.map((err) => dispatch(AlertActions.error(err)));
        dispatch(AlertActions.clear());
        return dispatch(declineProjectFailure(error.errors));
      });
  };
};

const AdminActions = {
  login,
  logout,
  setHomeProjects,
  getAllProjects,
  getAllInvoices,
  showProjectById,
  acceptProject,
  declineProject,
  updateProjectById,
  startProjectById,
  getAllBookGoodies,
  getAdminRecurringPayments,
  AdmincancelRecurringPayment,
  getAllSelectedProjects,
  hideProject,
  closeJob
};

export default AdminActions;
