import React from 'react';
import { ProgressBar } from 'components';
import { TimeIcon, TargetIcon, SupporterIcon } from 'images';
import {
  numberWithCommas,
  Colors,
  SvgWrapper,
  device,
  isTablet,
  isLabTop,
  isMobile,
} from 'utils';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DonationButton from 'utils/DonationButton';

const CardWrapper = styled.article`
  background: ${Colors.white};
  border-radius: 10px;
  border: 2px solid ${Colors.secondaryColor};
  cursor: pointer;
  max-width: 350px;
  margin: auto;
  height: 100%;
  position: relative;
  @media ${device.laptop} {
    max-width: 280px;
  }
  &:hover {
    border: 2px solid ${Colors.secondaryColor};
  }
  & > a {
    margin: 0;
    color: ${Colors.black};
    &:hover {
      border-bottom: none;
    }
  }
  .card {
    &__img-wrapper {
      position: relative;
      max-height: 200px;
      border-radius: 10px 10px 0 0;
      @media ${device.laptop} {
        max-height: 150px;
      }
      & > span,
      img {
        width: 100%;
        height: 200px;
        position: relative;
        display: inline-block;
        background: ${Colors.grey};
        border-radius: 10px 10px 0 0;
        object-fit: cover;
        @media ${device.laptop} {
          max-height: 150px;
        }
        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
    &__category {
      color: ${Colors.white};
      text-transform: uppercase;
      font-size: 12px;
      background-color: rgba(42, 42, 42, 0.8);
      padding: 5px;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      opacity: 0.8;
    }
    &__form {
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      display: flex;
      align-items: center;
      color: ${Colors.black};
      text-transform: uppercase;
      font-size: 12px;
      background: ${Colors.white};
      padding: 5px;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
      opacity: 0.5;
      @media ${device.laptop} {
        font-size: 10px;
      }
    }
    &__content {
      padding: 10px 20px;
      footer {
        display: flex;
        justify-content: space-between;
        p {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 1;
          margin-top: 5px;
          @media ${device.laptop} {
            font-size: 12px;
          }
          span {
            margin-right: 5px;
          }
        }
      }
    }
    &__name {
      margin: 0;
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
      min-height: 56px;
      @media ${device.laptop} {
        font-size: 16px;
      }
    }
    &__description {
      font-size: 16px;
      margin: 0;
      width: 100%;
      min-height: 100px;
      overflow-y: hidden;
      overflow-x: hidden;
      margin-bottom: 90px;
      @media ${device.laptop} {
        font-size: 14px;
        min-height: 75px;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    p {
      font-weight: bold;
    }
  }

  .limit {
    word-wrap: break-word;
  }
`;

export default function CardsWrapper(props) {
  const {
    project: {
      category,
      name,
      description,
      target,
      fundings,
      days,
      url,
      from,
      photo,
      is_patreon,
      is_ticketable,
      donations_count,
      mobile_photo,
      id,
    },
  } = props;
  const percentage = Math.round((fundings / target) * 100);
  return (
    <div className={'card'}>
      <CardWrapper>
        <Link
          style={{ color: '#1e322b' }}
          to={url ? `/project/${url}` : '/project/iiiev'}
        >
          <div className="card__img-wrapper">
            {photo ? (
              <picture>
                {isMobile() && mobile_photo ? (
                  <img src={mobile_photo} alt={name} />
                ) : (
                  <img src={photo} alt={name} />
                )}
              </picture>
            ) : (
              <span>
                <span>Projektbild</span>
              </span>
            )}
            {category && <p className="card__category">{category}</p>}

            <p className="card__form">
              <picture>
                <img src={SupporterIcon} alt="supporter-icon" />
              </picture>
              {from} Supporter
            </p>
          </div>
        </Link>

        <div className="card__content">
          <Link
            style={{ color: '#1e322b' }}
            to={url ? `/project/${url}` : '/project/iiiev'}
          >
            <h2 className="card__name limit" title={name}>
              {name}
            </h2>
          </Link>
          <Link
            style={{ color: '#1e322b' }}
            to={url ? `/project/${url}` : '/project/iiiev'}
          >
            <p title={description} className="card__description">
              {isMobile()
                ? description.length > 89
                  ? description.substring(0, 89)
                  : description.substring(0, 92)
                : isTablet()
                ? description.length > 89
                  ? description.substring(0, 89)
                  : description.substring(0, 92)
                : isLabTop()
                ? description.length > 105
                  ? description.substring(0, 100)
                  : description.substring(0, 107)
                : description.length > 118
                ? description.substring(0, 118)
                : description.substring(0, 120)}
              {((isMobile() && description.length > 92) ||
                (isTablet() && description.length > 92) ||
                (isLabTop() && description.length > 107) ||
                description.length > 120) &&
                '...'}
            </p>
          </Link>

          <div className="footer">
            <ProgressBar percentage={percentage} />
            <Link
              style={{ color: '#1e322b' }}
              to={url ? `/project/${url}` : '/project/iiiev'}
            >
              <footer>
                {is_patreon ? (
                  <div className="icon_main_div_supporters">
                    <img
                      src={SupporterIcon}
                      style={{
                        width: '15px',
                        minWidth: '15px',
                        height: '15px',
                        marginTop: '3px',
                      }}
                      alt="supporter-icon"
                    />
                    <p>{Number(donations_count)} Supporter</p>
                  </div>
                ) : is_ticketable ? (
                  <div className="icon_main_div_supporters">
                    <img
                      src={SupporterIcon}
                      style={{
                        width: '15px',
                        minWidth: '15px',
                        height: '15px',
                        marginTop: '3px',
                      }}
                      alt="supporter-icon"
                    />
                    <p>{Number(donations_count)} Tickets verkauft</p>
                  </div>
                ) : (
                  <p className="card__target">
                    <SvgWrapper width="14px" height="14px">
                      <TargetIcon />
                    </SvgWrapper>
                    {numberWithCommas(parseFloat(fundings).toFixed(2))} € (
                    {Math.round((fundings / target) * 100)}
                    %)
                  </p>
                )}
                {is_patreon === false && is_ticketable === false && (
                  <p className="card__time">
                    <SvgWrapper width="14px" height="14px">
                      <TimeIcon />
                    </SvgWrapper>
                    {days} Tage
                  </p>
                )}
              </footer>
            </Link>

            <DonationButton id={id} url={url} name={name} />
          </div>
        </div>
      </CardWrapper>
    </div>
  );
}

CardsWrapper.propTypes = {
  project: PropTypes.object.isRequired,
};
