import { donationsConstants } from 'redux/actions/types';
const initialState = {
  loading: false,
  errors: { errors: [] },
  donations: {},
  recurringDonations: {},
  patreonDonations: {},
};

function donations(state = initialState, action) {
  switch (action.type) {
    case donationsConstants.GET_PROJECT_DONATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case donationsConstants.GET_PROJECT_DONATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        donations: action.donations,
      };
    case donationsConstants.GET_PROJECT_DONATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case donationsConstants.GET_MY_DONATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case donationsConstants.GET_MY_DONATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        donations: action.donations,
      };
    case donationsConstants.GET_MY_DONATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };

    case donationsConstants.REFUND_DONATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case donationsConstants.REFUND_DONATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        donations: {
          ...state.donations,
          donations: state.donations.donations.filter(
            (donation) => donation.id !== action.id
          ),
        },
      };
    case donationsConstants.REFUND_DONATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    ///// RECURRING DONATIONS
    case donationsConstants.GET_RECURRING_DONATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case donationsConstants.RECURRING_DONATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        recurringDonations: action.donations,
      };
    case donationsConstants.RECURRING_DONATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };

    ///// RECURRING DONATIONS CANCEL
    case donationsConstants.GET_RECURRING_DONATIONS_CANCEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case donationsConstants.RECURRING_DONATIONS_CANCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        recurringDonations: action.donations,
      };
    case donationsConstants.RECURRING_DONATIONS_CANCEL_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };

    ///// PATREON DONATIONS CANCEL
    case donationsConstants.GET_PATREON_DONATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case donationsConstants.GET_PATREON_DONATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        patreonDonations: action.donations,
      };
    case donationsConstants.GET_PATREON_DONATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };

    default:
      return state;
  }
}

export default donations;
